import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import InventoryMainTable from '../../components/InventoryMainTable';
import HardwaresTable from '../../components/HardwaresTable';
import LoansTable from '../../components/LoansTable';
import Divider from '../../components/Divider';
import TotalHardwareHeldTable from '../../components/TotalHardwareHeldTable';

const Inventory = () => {
  const state = useSelector(({ user }) => ({ user }));

  useEffect(() => {
    if (
      state.user.name !== '' &&
      state.user.groups.indexOf('inventory') === -1
    ) {
      navigate('/');
    }
  }, [state.user]);

  return (
    <>
      <Helmet>
        <title>SGCC Inventory Dashboard</title>
      </Helmet>
      <InventoryMainTable />
      <Divider />
      <TotalHardwareHeldTable />
      <Divider />
      <HardwaresTable />
      <Divider />
      <LoansTable />
    </>
  );
};

export default Inventory;
