import { Auth } from 'aws-amplify';

let timeout;
let interval;
let counter = 0;

/**
 * class for websocket on the client side.
 * Self-reconnects once the connection is broken.
 * It will stop trying to reconnect after failing three times.
 */
export default class WS {
  constructor() {
    this.ws = null;
    this.autoReconnect = false;
  }

  /**
   * Sends a message to server.
   * @param {string} type - event type
   * @param {object} value - the message itself.
   */
  _send({ type, payload }) {
    const mssg = { action: 'message' };

    if (typeof payload !== 'undefined') {
      mssg.data = { type, payload };
    }

    this.ws.send(JSON.stringify(mssg));
  }

  /**
   * Listens to incoming messages from the server.
   */
  _message() {
    const dispatchEvent = (evt, detail) => {
      let customEvent;

      if (typeof detail !== 'undefined') {
        customEvent = new CustomEvent(evt, { detail });
      } else {
        customEvent = new CustomEvent(evt);
      }

      window.dispatchEvent(customEvent);
    };

    if (this.ws === null) {
      return;
    }

    this.ws.onmessage = (e) => {
      const mssg = JSON.parse(e.data);

      if (mssg) {
        switch (mssg.type) {
          case 'SEND_ACTION':
            dispatchEvent('sendAction', mssg.payload);
            break;
          case 'IS_ONLINE':
            dispatchEvent('isOnline', mssg.payload);
            break;
          case 'IS_VIEWING':
            dispatchEvent('isViewing', mssg.payload);
            break;
          case 'IS_TYPING':
            dispatchEvent('isTyping', mssg.payload);
            break;
          case 'STOPPED_TYPING':
            dispatchEvent('stoppedTyping', mssg.payload);
            break;
          case 'STATUS_UPDATE':
            dispatchEvent('statusUpdate', mssg.payload);
            break;
          case 'NEW_MESSAGE':
          case 'INCOMING_MESSAGE':
            dispatchEvent('incomingMessage', mssg.payload);
            break;
          case 'PONG':
            break;
          default:
            break;
        }
      }
    };
  }

  /**
   * Listens to the websocket closing and attempt to reconnect.
   * 1. Stops reconnecting if all three attempts failed.
   * 2. Don't reconnect at all if autoConnect is false.
   */
  _close() {
    if (this.ws === null) {
      return;
    }

    this.ws.onclose = (e) => {
      // Every X minutes/seconds it will try to reconnect if found the connection to be closing.
      const time = 1000 * 5;

      clearTimeout(timeout);

      if (this.autoReconnect) {
        if (counter < 3) {
          console.error('Restarting', e);
          timeout = setTimeout(() => {
            this.start();
          }, time * counter);
          counter += 1;
        } else {
          console.error('Unable to connect to server.');
        }
      }
    };
  }

  /**
   * Start or clear heartbeat to keep connection alive.
   * @param {boolean} state - Set or clear heartbeat
   */
  _ping(state) {
    if (this.ws === null) {
      return;
    }

    if (state) {
      interval = setInterval(() => {
        this._send({
          type: 'PING',
          payload: 'PING',
        });
      }, 1000 * 10);
    } else if (interval) {
      clearInterval(interval);
    }
  }

  /**
   * Catches error from websocket. Prevents uncaught error problems.
   */
  _error() {
    if (this.ws === null) {
      return;
    }

    this.ws.onerror = (e) => {
      console.error('CLIENT WS ERROR: ', e);
    };
  }

  /**
   * Start listening for websockets event.
   */
  start() {
    Auth.currentSession().then((cred) => {
      const token = `${cred.accessToken.jwtToken}`;

      this.ws = new WebSocket(
        `wss://8bcwvynkqh.execute-api.ap-southeast-1.amazonaws.com/dev?token=${token}`
      );

      if (process && process.env && process.env.NODE_ENV === 'development') {
        console.log('START');
      }

      this._error();
      this._close();
      this._message();
      this._ping(true);
    });
  }

  /**
   * Prevents auto reconnecting and closes connection to the server immediately.
   */
  exit() {
    if (this.ws && this.ws.readyState === 1) {
      this.autoReconnect = false;
      this._ping(false);
      this.ws.close();
    }
  }

  /**
   * Sends a new message to all. Used to send a reply to the Whatsapp user.
   */
  sendToAll(payload) {
    if (this.ws === null) {
      return;
    }

    this._send({
      type: 'NEW_MESSAGE',
      payload,
    });
  }

  /**
   * Sends a "is typing" status to all.
   * @param {string} phone
   */
  isTyping(phone) {
    if (this.ws === null) {
      return;
    }

    Auth.currentAuthenticatedUser().then(({ username }) => {
      this._send({
        type: 'IS_TYPING',
        payload: { name: username, phone },
      });
    });
  }

  /**
   * Informs all that the user is online.
   */
  isOnline() {
    if (this.ws === null) {
      return;
    }

    Auth.currentAuthenticatedUser().then(({ username }) => {
      this._send({
        type: 'IS_ONLINE',
        payload: { name: username, timestamp: Date.now() },
      });
    });
  }

  /**
   * General message to inform current user's action to all
   * online dashboard users
   */
  sendAction(action) {
    if (this.ws === null && !action) {
      return;
    }

    Auth.currentAuthenticatedUser().then(({ username }) => {
      this._send({
        type: 'SEND_ACTION',
        payload: { name: username, action },
      });
    });
  }

  /**
   * Cancels the "is typing" status to all.
   */
  stoppedTyping(phone) {
    if (this.ws === null) {
      return;
    }

    Auth.currentAuthenticatedUser().then(({ username }) => {
      this._send({
        type: 'STOPPED_TYPING',
        payload: { name: username, phone },
      });
    });
  }

  /**
   * Informs all on who is viewing which chat.
   * @param {string} phone
   */
  isViewing(phone) {
    if (this.ws === null) {
      return;
    }

    Auth.currentAuthenticatedUser().then(({ username }) => {
      this._send({
        type: 'IS_VIEWING',
        payload: { name: username, phone },
      });
    });
  }
}
