import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NewTab from './NewTab';

const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Vid = styled.video`
  width: 100%;
  height: calc(300px - 33px);
  background-color: #cecece;
`;

const Video = ({
  url, type,
}) => (
  <Container>
    <Vid controls preload="none">
      <source src={url} type={type} />
        Your browser does not support the video tag.
    </Vid>
    <NewTab url={url} type={type} />
  </Container>
);

Video.defaultProps = {
  url: '',
  type: 'video/mp4',
};

Video.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default Video;
