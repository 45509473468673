import React from 'react';
import { matchSorter } from 'match-sorter';
import styled from 'styled-components';

import $ from '../../../../styles/global';

const FilterInput = styled.input`
  padding: 10px;
  border: 1px solid ${$.color.gray1};
  border-radius: 5px;
  min-width: 150px;
`;

const filterTypes = () => {
  const fuzzyTextFilter = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, {
      keys: [
        (row) => {
          const value = row.values[id];

          if (typeof value === 'object' && value && value.props) {
            return value.props.children;
          }

          return row.values[id];
        },
      ],
    });
  };

  return React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilter,
    }),
    []
  );
};

const defaultColumn = () => {
  const DefaultColumnFilter = ({
    column: { filterValue, setFilter, Header },
  }) => {
    return (
      <FilterInput
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${Header.toLowerCase()}...`}
      />
    );
  };

  return React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
};

export { defaultColumn, filterTypes };
