import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import useActions from '../../utils/useActions';
import { clearHistoriesState, updateHistoriesState } from '../../actions';
import hardwareColumns from './hardwareColumns';
import loanColumns from './loanColumns';
import { getHardwareHistoriesGQL, getLoanHistoriesGQL } from '../../apollo';
import $ from '../../styles/global';
import ReactTable from '../ReactTable';
import SubRow from './SubRow';

const Title = styled.h1`
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin2}px;
`;

const HistoryTable = ({ type, id }) => {
  const columns = React.useMemo(
    () => (type === 'hardware' ? hardwareColumns : loanColumns),
    []
  );
  const [updateLogs, clearLogs] = useActions([
    updateHistoriesState,
    clearHistoriesState,
  ]);
  const state = useSelector(({ histories }) => ({ histories }));
  const [getHardwareHistories] = useLazyQuery(getHardwareHistoriesGQL, {
    onCompleted: ({ getHardwareHistories: result }) => {
      updateLogs(result);
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [getLoanHistories] = useLazyQuery(getLoanHistoriesGQL, {
    onCompleted: ({ getLoanHistories: result }) => {
      updateLogs(result);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    switch (type) {
      case 'loan':
        getLoanHistories({ variables: { id } });
        break;
      case 'hardware':
        getHardwareHistories({ variables: { id } });
        break;
      default:
        break;
    }

    return () => {
      clearLogs();
    };
  }, []);

  return (
    <section>
      <div>
        <Title>History</Title>
        <ReactTable
          data={state.histories}
          columns={columns}
          defaultPageSize={20}
          SubRow={SubRow}
        />
      </div>
    </section>
  );
};

HistoryTable.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default HistoryTable;
