import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { updateHardwaresState } from '../../actions';
import useActions from '../../utils/useActions';
import { getHardwaresGQL } from '../../apollo';
import ReactTable from '../ReactTable';
import columns from './columns';
import SubRow from './SubRow';
import RefreshButton from '../RefreshButton';
import { Title, TotalEntries, TitleContainer } from '../TableStyles';

const TitleItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HardwaresTable = () => {
  const state = useSelector(({ hardwares }) => ({ hardwares }));
  const hardwareTableColumns = React.useMemo(() => columns, []);
  const [updateLogs] = useActions([updateHardwaresState]);
  const [getHardwares] = useLazyQuery(getHardwaresGQL, {
    onCompleted: ({ getHardwares: result }) => {
      updateLogs(result);
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [refreshHardwares] = useLazyQuery(getHardwaresGQL, {
    onCompleted: ({ getHardwares: result }) => {
      updateLogs(result);
      toast.success('Hardware table refreshed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Hardware table not refreshed. Please reload page.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  useEffect(() => {
    getHardwares();
  }, []);

  return (
    <section>
      <div>
        <TitleContainer>
          <TitleItems>
            <Title>Hardwares</Title>
            <TotalEntries>{state.hardwares.length}</TotalEntries>
          </TitleItems>
          <ButtonContainer>
            <RefreshButton refreshList={refreshHardwares} />
          </ButtonContainer>
        </TitleContainer>
        <ReactTable
          data={state.hardwares}
          columns={hardwareTableColumns}
          SubRow={SubRow}
        />
      </div>
    </section>
  );
};

export default HardwaresTable;
