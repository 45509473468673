import { gql } from 'apollo-boost';

const deleteLoanGQL = gql`
  mutation loan(
    $id: String
    $name: String
    $comments: String
    $timestamp: Date
    $totalHardwareHeld: Boolean
  ) {
    deleteLoan(
      loan: {
        id: $id
        name: $name
        comments: $comments
        timestamp: $timestamp
        totalHardwareHeld: $totalHardwareHeld
      }
    )
  }
`;

export default deleteLoanGQL;
