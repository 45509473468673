import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NewTab from './NewTab';

const Container = styled.div`
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Aud = styled.audio`
  width: 100%;
  height: 100%;
`;

const Audio = ({
  url, type,
}) => (
  <Container>
    <Aud controls>
      <source src={url} type={type} />
      Your browser does not support the audio tag.
    </Aud>
    <NewTab url={url} type={type} />
  </Container>
);

Audio.defaultProps = {
  url: '',
  type: 'video/mp4',
};

Audio.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default Audio;
