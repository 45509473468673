import styled from 'styled-components';
import $ from '../../../styles/global';
import Button from '../../Button';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${$.layout().padding2}px ${$.layout().padding3}px;
  border: 1px solid ${$.color.gray1};
  border-radius: 10px;
  box-shadow: ${$.dropShadow.normal};
  background-color: ${$.color.white};
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
  font-size: 16px;
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.white};
    margin-right: ${$.layout().margin1}px;
  }
`;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${$.color.blue5};
  padding-bottom: ${$.layout().padding1}px;
  margin-bottom: ${$.layout().margin3}px;
  border-bottom: 1px solid ${$.color.gray1};
`;

const Label = styled.label`
  color: ${$.color.blue4};
  font-size: 15px;
  margin-bottom: ${$.layout().margin1}px;
`;

const TextInput = styled.input`
  border-radius: 5px;
  padding: ${$.layout().padding1}px;
  border: 1px solid ${$.color.gray1};
  min-width: 222px;
  width: calc(100% - ${$.layout().padding1 * 2}px - 2px);
  font-size: 15px;
  color: ${$.color.blue4};
`;

const TextAreaInput = styled.textarea`
  border-radius: 5px;
  padding: ${$.layout().padding1}px;
  border: 1px solid ${$.color.gray1};
  height: 100px;
  resize: none;
  overflow: auto;
  font-size: 15px;
  color: ${$.color.blue4};
`;

const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${$.layout().margin2}px;
`;

const DoubleFormInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: 49% !important;
  }
`;

const Dropdown = styled.select`
  background-color: ${$.color.white};
  color: ${$.color.blue4};
  font-size: 15px;
  border-radius: 5px;
  min-width: 222px;
  padding: ${$.layout().padding1}px;
  height: calc(${$.layout().padding1 * 2}px + 19px);
  border: 1px solid ${$.color.gray1};
  width: 100%;
`;

const QuestionCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > label {
    color: ${$.color.blue4};
    font-size: 15px;
    line-height: 15px;
    margin-right: 15px;
  }

  & > input[type='checkbox'] {
    width: 16px;
    height: 16px;
  }
`;

export {
  Label,
  TextInput,
  TextAreaInput,
  QuestionCheckbox,
  Dropdown,
  FormTitle,
  FormInput,
  Form,
  SubmitButton,
  DoubleFormInput,
};
