import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Mousetrap from '../../../../utils/mousetrap';
import $ from '../../../../styles/global';
import { clearNoti } from '../../../../actions';
import useActions from '../../../../utils/useActions';
import SendWhatsappIcon from '../../../../assets/icons/send-whatsapp.svg';
import { useMyContext } from './Context';
import { scrollToBottom } from '../../helper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(
    100% - ${$.layout().padding1 * 2}px - ${$.layout().margin1 * 2}px
  );
`;

const TextInput = styled.textarea`
  border-radius: 10px;
  width: calc(100% - ${$.layout().padding1 * 2}px - 2px);
  padding: ${$.layout().padding1}px;
  border: 1px solid ${$.color.whatsappBorder};
  resize: none;
  font-size: 15px;
  line-height: 1.2em;
  margin-bottom: 5px;
`;

const StylingInfo = styled.div`
  font-size: 14px;
  color: ${$.color.blue3};

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  pre {
    margin: 0;
    display: inline-block;
  }
`;

const SendButton = styled.div`
  ${({ disabled }) => `
  border-radius: 999px;
  align-self: flex-start;
  position: relative;
  top: 7px;
  ${disabled ? 'pointer-events: none;' : ''}
  background-color: ${disabled ? $.color.gray1 : $.color.green1};
  padding: 6px 7px;
  transition: all 0.3s ease;
  border: 2px solid transparent;

  svg {
    transition: all 0.3s ease;
    fill: ${$.color.white};
    width: 20px;
    height: 19px;
    position: relative;
    left: 1px;
    top: 1px;
  }

  &:hover {
    background-color: ${$.color.white};
    border: 2px solid ${$.color.green1};
    cursor: pointer;

    svg {
      fill: ${$.color.green1};
    }
  }
`}
`;

const ChatArea = () => {
  const [clearMessage] = useActions([clearNoti]);
  const state = useSelector(({ whatsapp, user }) => ({ whatsapp, user }));
  const { textInputRef, setTxt, txt, sendReply } = useMyContext();
  const timeoutRef = useRef({ state: false, func: '' });

  /**
   * Force the text input to focus when user switches to the chat.
   * Listens to ENTER keypress and ignore ENTER + Shift keypress combo.
   * Updates chatbox and send message to all listeners.
   */
  useEffect(() => {
    Mousetrap(textInputRef.current).bind('enter', (e) => {
      if (e.target.value) {
        sendReply({ str: e.target.value });
      }

      return false;
    });

    return () => {
      Mousetrap(textInputRef.current).unbind('enter');
    };
  }, []);

  /**
   * Beams out isTyping status to all.
   */
  useEffect(() => {
    if (timeoutRef.current && timeoutRef.current.func) {
      clearTimeout(timeoutRef.current.func);
    }

    if (txt) {
      if (timeoutRef.current.state === false) {
        state.user.ws.isTyping(state.whatsapp.currentPhone);
        timeoutRef.current.state = true;
      }
    }

    timeoutRef.current.func = setTimeout(() => {
      if (timeoutRef.current.state === true) {
        state.user.ws.stoppedTyping(state.whatsapp.currentPhone);
        timeoutRef.current.state = false;
      }
    }, 3500);
  }, [txt]);

  return (
    <>
      <Container>
        <TextInput
          onFocus={() => {
            clearMessage(state.whatsapp.currentPhone);
          }}
          onClick={() => {
            clearMessage(state.whatsapp.currentPhone);
            scrollToBottom();
          }}
          className="chatarea"
          ref={textInputRef}
          onChange={(e) => {
            setTxt(e.target.value);
          }}
          placeholder="Shift + ENTER for a new line. Press ENTER/Send button to send."
        />
        <StylingInfo>
          *<strong>bold</strong>
          *&nbsp;&nbsp;_
          <em>italic</em>
          _&nbsp;&nbsp;~
          <strike>strikethrough</strike>
          ~&nbsp;&nbsp;```
          <pre>preformatted</pre>
          ```
        </StylingInfo>
      </Container>
      <SendButton
        disabled={txt === ''}
        onClick={() => {
          sendReply({ str: txt });
        }}
        title="Click to send"
      >
        <SendWhatsappIcon />
      </SendButton>
    </>
  );
};

export default ChatArea;
