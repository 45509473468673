import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NewTab from './NewTab';

const Container = styled.div`
  width: 320px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  height: calc(100% - 33px);
`;

const Pic = ({
  url, type,
}) => (
  <Container>
    <Img src={url} alt="text message" />
    <NewTab url={url} type={type} />
  </Container>
);

Pic.defaultProps = {
  url: '',
  type: 'image/png',
};

Pic.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default Pic;
