import { gql } from 'apollo-boost';

const getHardwaresGQL = gql`
  query hardware {
    getHardwares {
      id
      name
      serialNumber
      os
      appleID
      type
      comments
      timestamp
      location
    }
  }
`;

export default getHardwaresGQL;
