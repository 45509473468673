import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.table`
  border: 1px solid ${$.color.gray2};
  border-collapse: collapse;
  color: ${$.color.blue4};
  width: calc(100vw - 100px);
  height: 100%;

  a {
    text-decoration: none;
    color: ${$.color.blue5};
    font-weight: bold;
    &:visited {
      color: ${$.color.blue5};
    }
  }

  & tbody {
    & tr {
      transition: all 0.3s ease;
      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.03);
      }
      &:last-child {
        border: 1px solid ${$.color.gray2};
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      & > td {
        border: 1px solid rgba(0, 0, 0, 0.02);
        padding: ${$.layout().padding1}px ${$.layout().padding2}px;
        &:first-child {
          border-left: 1px solid ${$.color.gray2};
        }
        &:last-child {
          border-right: 1px solid ${$.color.gray2};
        }
      }
    }
  }
`;

const THCell = styled.th`
  background-color: '#dae1ed';
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  border-top: 1px solid ${$.color.gray2};
  border-bottom: 1px solid ${$.color.gray2};
  border-left: 1px solid ${$.color.gray2};
  border-right: 1px solid ${$.color.gray2};
  font-size: 14px;

  & > div {
    margin-top: 5px;
  }

  &.bottom {
    border-bottom: 3px solid ${$.color.gray2};
  }

  &.top {
    border-top: 3px solid ${$.color.gray2};
  }
`;

const TRHighlight = styled.tr`
  ${({ rowHighlight }) => `
  ${rowHighlight ? `background-color: ${rowHighlight} !important;` : ''}
`}
`;

const Table = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  visibleColumns,
  SubRow,
}) => (
  <Container {...getTableProps()}>
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            let sorted = '';

            if (column.isSorted && !column.disableSorting) {
              sorted = column.isSortedDesc ? 'bottom' : 'top';
            }

            return (
              <THCell className={sorted} {...column.getHeaderProps()}>
                <span
                  {...(column.disableSorting
                    ? {}
                    : column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                </span>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </THCell>
            );
          })}
        </tr>
      ))}
    </thead>
    <tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        const rowProps = row.getRowProps();

        return (
          <React.Fragment key={rowProps.key}>
            <TRHighlight {...rowProps} rowHighlight={row.original.rowHighlight}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </TRHighlight>
            {row.isExpanded && SubRow ? (
              <SubRow row={row} columns={visibleColumns.length} />
            ) : null}
          </React.Fragment>
        );
      })}
    </tbody>
  </Container>
);

Table.defaultProps = {
  SubRow: <></>,
};

Table.propTypes = {
  SubRow: PropTypes.element,
  getTableProps: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.arrayOf(PropTypes.object).isRequired,
  prepareRow: PropTypes.func.isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
