import React from 'react';
import styled from 'styled-components';

import $ from '../styles/global';

const Container = styled.hr`
  margin: 30px 0;
  width: 100vw;
  height: 1px;
  background-color: ${$.color.gray1};
  border: none;
`;

const Divider = () => <Container />;

export default Divider;
