import typeOptions from './typeOptions';
import locationOptions from './locationOptions';

export default [
  {
    inputs: [
      [
        {
          label: 'Name',
          name: 'name',
        },
        {
          name: 'serialNumber',
          label: 'Serial Number',
        },
      ],
      [
        {
          name: 'os',
          label: 'Operating System',
        },
        {
          name: 'appleID',
          label: 'Apple ID',
        },
      ],
      [
        {
          name: 'type',
          label: 'Type',
          type: 'select',
          options: typeOptions.map(({ value }) => ({
            text: value,
            value,
            key: value,
          })),
        },
        {
          name: 'location',
          label: 'Location',
          type: 'select',
          options: locationOptions.map(({ value }) => ({
            text: value,
            value,
            key: value,
          })),
        },
      ],
      [
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments',
        },
      ],
    ],
  },
];
