/* eslint-disable react/jsx-filename-extension, react/prop-types */

import React from 'react';
import { Link } from 'gatsby';
import ExpandRow from '../ReactTable/ExpandRow';
import { convertTimestampToStr } from '../../utils/globals';

export default [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => <ExpandRow row={row} />,
    SubCell: () => null,
  },
  {
    Header: 'Name',
    id: 'name',
    accessor: (ele) => (
      <Link to={`/inventory/history?hardware=${ele.id}`}>{ele.name}</Link>
    ),
    filter: 'fuzzyText',
  },
  {
    Header: 'Serial Number',
    id: 'serial_number',
    accessor: (ele) => ele.serialNumber,
    filter: 'fuzzyText',
  },
  {
    Header: 'Hardware Type',
    id: 'type',
    accessor: (ele) => ele.type,
    filter: 'fuzzyText',
  },
  {
    Header: 'Operating System',
    id: 'os',
    accessor: (ele) => ele.os,
    filter: 'fuzzyText',
  },
  {
    Header: 'Apple ID',
    id: 'apple_id',
    accessor: (ele) => ele.appleID,
    filter: 'fuzzyText',
  },
  {
    Header: 'Location',
    id: 'location',
    accessor: (ele) => ele.location,
    filter: 'fuzzyText',
  },
  {
    Header: 'Comments',
    id: 'comments',
    accessor: (ele) => ele.comments,
    filter: 'fuzzyText',
  },
  {
    Header: 'Last Updated',
    id: 'timestamp',
    accessor: (ele) => convertTimestampToStr(ele.timestamp).date,
    filter: 'fuzzyText',
    sortType: 'timestampSort',
  },
];
