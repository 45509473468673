import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import ReactTable from '../ReactTable';
import { getCustomersGQL, createCustomerGQL } from '../../apollo';
import columns from './columns';
import useActions from '../../utils/useActions';
import {
  updateCustomersState,
  newCustomerState,
  setFormModal,
} from '../../actions';
import { Title, TotalEntries, TitleContainer } from '../TableStyles';
import $ from '../../styles/global';
import Button from '../Button';
import RefreshButton from '../RefreshButton';
import AddIcon from '../../assets/icons/add.svg';
import SubRow from './SubRow';
import formStructure from './customerForm';
import LoadingMessage from '../LoadingMessage';

const TitleItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > *:first-child {
    margin-right: ${$.layout().margin2}px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: ${$.layout().margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const NewCustomerButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${$.color.green1};

  &:hover {
    background-color: ${$.color.green2};
  }

  & > svg {
    fill: ${$.color.white};
    width: 20px;
    height: 20px;
    margin-right: ${$.layout().margin1}px;
  }
`;

const CustomersTable = () => {
  const state = useSelector(({ customers, user }) => ({ customers, user }));
  const customerTableColumns = React.useMemo(() => columns, []);
  const [updateLogs, newLog, setFormModalState] = useActions([
    updateCustomersState,
    newCustomerState,
    setFormModal,
  ]);
  const [getCustomerLogs] = useLazyQuery(getCustomersGQL, {
    onCompleted: ({ getStudents: result }) => {
      if (result && result.length > 0) {
        updateLogs(result);
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [createCustomerLog] = useMutation(createCustomerGQL, {
    onCompleted: ({ createStudent: result }) => {
      if (result && Object.keys(result).length > 0) {
        newLog(result);
        toast.success('Customer successfully added to the database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to add customer into database. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to add customer into database. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  useEffect(() => {
    getCustomerLogs();
  }, []);

  return (
    <section>
      {state.customers && state.customers.length > 0 ? (
        <div>
          <TitleContainer>
            <TitleItems>
              <Title>Customers</Title>
              <TotalEntries>{state.customers.length}</TotalEntries>
            </TitleItems>
            <ButtonContainer>
              <NewCustomerButton
                onClick={() => {
                  setFormModalState({
                    title: 'Create New Customer Entry',
                    components: formStructure,
                    onConfirm: (formData) => {
                      createCustomerLog({ variables: { ...formData } });
                    },
                  });
                }}
              >
                <AddIcon />
                New Customer
              </NewCustomerButton>
              <RefreshButton refreshList={getCustomerLogs} />
            </ButtonContainer>
          </TitleContainer>
          <ReactTable
            data={state.customers}
            SubRow={SubRow}
            columns={customerTableColumns}
          />
        </div>
      ) : (
        <LoadingMessage itemName="customers" />
      )}
    </section>
  );
};

export default CustomersTable;
