import { Auth, API } from 'aws-amplify';

export default (body) => {
  const payload = Auth.currentSession()
    .then((session) => ({
      headers: { Authorization: session.idToken.jwtToken },
      response: true,
      body,
    }))
    .then((myInit) => API.post('APIGatewayGetAllMessages', '/', myInit))
    .then((res) => res.data);

  return {
    type: 'GET_ALL_MESSAGES',
    payload,
  };
};
