import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import $ from '../../../styles/global';
import hardwareColumns from './hardwareColumns';
import loanColumns from './loanColumns';
import { createLoanedGQL, createReturnedGQL } from '../../../apollo';
import AddIcon from '../../../assets/icons/add.svg';
import {
  FormTitle,
  Label,
  FormInput,
  TextAreaInput,
  Form,
  Dropdown,
  SubmitButton,
} from '../../FormModal/Content/formStyles';
import SearchTable from './SearchTable';

const DateInput = styled.input.attrs({ type: 'date' })`
  border-radius: 5px;
  padding: ${$.layout().padding1}px;
  border: 1px solid ${$.color.gray1};
  resize: none;
  overflow: auto;
  font-size: 15px;
  color: ${$.color.blue4};
`;

const today = () => {
  const d = new Date();
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return `${year}-${month <= 9 ? '0' + month : month}-${
    day <= 9 ? '0' + day : day
  }`;
};

const NewHistoryForm = () => {
  const [formData, setFormData] = useState({
    type: 'loaned',
  });
  const [clearTable, setClearTable] = useState(false);
  const memoizedHardwareColumns = React.useMemo(() => hardwareColumns, []);
  const memoizedLoanColumns = React.useMemo(() => loanColumns, []);
  const state = useSelector(({ hardwares, loans }) => {
    return { hardwares, loans };
  });

  const [createOneLoanHistory] = useMutation(createLoanedGQL, {
    onCompleted: ({ createLoaned: result }) => {
      if (result && Object.keys(result).length > 0) {
        toast.success('Loan history successfully added to database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to add loan history into database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to add loan history into database.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
  const [createOneReturnedHistory] = useMutation(createReturnedGQL, {
    onCompleted: ({ createReturned: result }) => {
      if (result && Object.keys(result).length > 0) {
        toast.success('Returned history successfully added to database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to add returned history into database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to add returned history into database.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Form>
      <FormTitle>New History</FormTitle>
      <FormInput>
        <Label>Type</Label>
        <Dropdown
          name="type"
          value={formData.type || ''}
          onChange={handleInputChange}
        >
          <option value="loaned">Add a loaned history</option>
          <option value="returned">Add a returned history</option>
        </Dropdown>
      </FormInput>
      <FormInput>
        <Label>Hardware</Label>
        <SearchTable
          name="hardwareID"
          clearTable={clearTable}
          setClearTable={setClearTable}
          columns={memoizedHardwareColumns}
          data={state.hardwares}
          setFormData={setFormData}
        />
      </FormInput>
      <FormInput>
        <Label>Loan</Label>
        <SearchTable
          name="loanID"
          clearTable={clearTable}
          setClearTable={setClearTable}
          columns={memoizedLoanColumns}
          data={state.loans}
          setFormData={setFormData}
        />
      </FormInput>
      <FormInput>
        <Label>Date</Label>
        <DateInput
          name="timestamp"
          value={formData.timestamp || today()}
          onChange={handleInputChange}
        />
      </FormInput>
      <FormInput>
        <Label>Comments</Label>
        <TextAreaInput
          name="comments"
          value={formData.comments || ''}
          onChange={handleInputChange}
          placeholder="Comments"
        ></TextAreaInput>
      </FormInput>
      <SubmitButton
        onClick={() => {
          const currData = { ...formData };

          currData.timestamp =
            typeof currData.timestamp === 'string'
              ? new Date(currData.timestamp).getTime()
              : Date.now();

          if (currData.hardwareID && currData.loanID) {
            setClearTable(true);
            setFormData({ type: 'loaned' });

            if (currData.type === 'loaned') {
              createOneLoanHistory({ variables: { ...currData } });
            } else {
              createOneReturnedHistory({ variables: { ...currData } });
            }
          }
        }}
      >
        <AddIcon />
        Add
      </SubmitButton>
    </Form>
  );
};

export default NewHistoryForm;
