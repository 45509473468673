import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import $ from '../styles/global';
import CloseIcon from '../assets/icons/close.svg';

const Container = styled(ToastContainer)`
  font-size: 17px;

  & > * {
    border-radius: 10px;
    padding: ${$.layout().padding2}px;
  }

  .Toastify__toast--default,
  .Toastify__toast--warning {
    color: ${$.color.blue4};
    & > svg {
      fill: ${$.color.blue4};
    }
  }

  .Toastify__toast--error,
  .Toastify__toast--success,
  .Toastify__toast--info {
    color: ${$.color.white};
  }
`;

const StyledCloseIcon = styled(({ className }) => (
  <CloseIcon className={className} />
))`
  width: 20px;
  height: 20px;
  position: relative;
  top: -10px;
  left: 10px;
  fill: ${$.color.white};
`;

const StyledToast = () => (
  <Container autoClose={3000} closeButton={<StyledCloseIcon />} />
);

export default StyledToast;
