import styled from 'styled-components';
import $ from '../styles/global';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${$.layout().margin2}px;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: ${$.color.blue4};
  margin-right: ${$.layout().margin2}px;
`;

const TotalEntries = styled.div`
  background-color: ${$.color.orange3};
  color: ${$.color.white};
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 20px;
  top: -3px;
  position: relative;
`;

export { Title, TotalEntries, TitleContainer };
