import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import Info from '../../components/InventoryHistory/Info';
import HistoryTable from '../../components/InventoryHistory/HistoryTable';
import Divider from '../../components/Divider';

const History = () => {
  const state = useSelector(({ user }) => ({ user }));
  const [historyItem, setHistoryItem] = useState({});

  useEffect(() => {
    if (
      state.user.name !== '' &&
      state.user.groups.indexOf('inventory') === -1
    ) {
      navigate('/');
      return;
    }

    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const hardwareParam = params.get('hardware');
      const loanParam = params.get('loan');

      if (hardwareParam) {
        setHistoryItem({ type: 'hardware', id: hardwareParam });
        return;
      }

      if (loanParam) {
        setHistoryItem({ type: 'loan', id: loanParam });
        return;
      }

      navigate('/');
    }
  }, [state.user]);

  return (
    <>
      <Helmet>
        <title>SGCC Inventory Dashboard</title>
      </Helmet>
      {historyItem.type && (
        <>
          <Info type={historyItem.type} id={historyItem.id} />
          <Divider />
          <HistoryTable type={historyItem.type} id={historyItem.id} />
        </>
      )}
    </>
  );
};

export default History;
