import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../styles/global';

const Container = styled.div`
  padding: ${$.layout().padding1}px;
  border: 1px solid ${$.color.gray2};
  color: ${$.color.blue4};
  font-size: 14px;
  width: calc(100vw - 100px - ${$.layout().padding1 * 2}px - 2px);
  height: 100%;

  & > *:not(:last-child) {
    margin-right: ${$.layout().margin1}px;
  }
`;

const NavButton = styled.button`
  ${({ disabled }) => `
  width: 140px;
  background-color: ${disabled ? $.color.gray1 : $.color.blue4};
  font-size: 15px;
  letter-spacing: 1px;
  color: ${$.color.white};
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  pointer-events: ${disabled ? 'none' : 'initial'};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${$.color.blue5};
  }
`}
`;

const PageInput = styled.input`
  border: 1px solid ${$.color.gray1};
  padding: 5px;
  width: 50px;
  border-radius: 5px;
`;

const RowSelection = styled.select`
  border: 1px solid ${$.color.gray1};
  padding: 5px;
  width: 120px;
  border-radius: 5px;
`;

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  previousPage,
  nextPage,
  pageIndex,
  pageOptions,
  setPageSize,
  pageSize,
}) => {
  return (
    <Container>
      <NavButton onClick={() => previousPage()} disabled={!canPreviousPage}>
        Previous
      </NavButton>
      <NavButton onClick={() => nextPage()} disabled={!canNextPage}>
        Next
      </NavButton>
      <span>{`Page ${pageIndex + 1} of ${pageOptions.length}`}</span>
      <span>
        {`Go to page: `}
        <PageInput
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            gotoPage(e.target.value ? Number(e.target.value) - 1 : 0);
          }}
          max={pageCount}
          min={1}
        />
      </span>
      <RowSelection
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 40, 60, 80, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </RowSelection>
    </Container>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Pagination;
