/* eslint-disable react/jsx-filename-extension, react/prop-types */

import React from 'react';
import { Link } from 'gatsby';
import { SelectColumnFilter } from '../ReactTable/Filter';

import ExpandRow from '../ReactTable/ExpandRow';
import { convertTimestampToStr } from '../../utils/globals';

export default [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => <ExpandRow row={row} />,
    SubCell: () => null,
  },
  {
    Header: 'Name',
    id: 'name',
    accessor: (ele) => (
      <Link to={`/inventory/history?loan=${ele.id}`}>{ele.name}</Link>
    ),
    filter: 'fuzzyText',
  },
  {
    Header: 'Comments',
    id: 'comments',
    accessor: (ele) => ele.comments,
    filter: 'fuzzyText',
  },
  {
    Header: 'Show Total Hardware',
    id: 'totalHardwareHeld',
    accessor: (ele) => {
      if (typeof ele.totalHardwareHeld === 'undefined') {
        return 'No';
      }

      return ele.totalHardwareHeld ? 'Yes' : 'No';
    },
    filter: 'includes',
    Filter: SelectColumnFilter,
  },
  {
    Header: 'Last Updated',
    id: 'timestamp',
    accessor: (ele) => convertTimestampToStr(ele.timestamp).date,
    filter: 'fuzzyText',
    sortType: 'timestampSort',
  },
];
