import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';

import { getCallsGQL } from '../../apollo';
import useActions from '../../utils/useActions';
import { updateCallsState } from '../../actions';
import columns from './columns';
import { Title, TotalEntries, TitleContainer } from '../TableStyles';
import RefreshButton from '../RefreshButton';
import ReactTable from '../ReactTable';

const TitleItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CallLogsTable = () => {
  const [updateLogs] = useActions([updateCallsState]);
  const [names, setNames] = useState({});
  const state = useSelector(({ calls, leads, customers }) => ({
    calls,
    leads,
    customers,
  }));
  const callLogsTableColumns = React.useMemo(() => columns, []);
  const [getCallLogsInterval] = useLazyQuery(getCallsGQL, {
    pollInterval: 1000 * 60 * 1,
    onCompleted: ({ getCalls: result }) => {
      if (result && result.length > 0) {
        updateLogs(result);
      }
    },
    onError: (e) => {
      console.log('Error: ', e);
    },
  });
  const [refreshCallLogs] = useLazyQuery(getCallsGQL, {
    onCompleted: ({ getCalls: result }) => {
      if (result && result.length > 0) {
        updateLogs(result);
      }
    },
    onError: (e) => {
      console.log('Error: ', e);
    },
  });

  useEffect(() => {
    const hasLeads =
      Array.isArray(state.leads) &&
      Object.keys(names).length !== state.leads.length;
    const hasCustomers = state.customers.length > 1;
    const obj = {};

    if (hasLeads && hasCustomers) {
      state.leads.forEach(
        ({ first_name: fname = '', last_name: lname = '', phone }) => {
          if (phone && typeof obj[phone.trim()] === 'undefined') {
            obj[phone.trim()] = `${fname} ${lname || ''}`;
          }
        }
      );

      state.customers.forEach(
        ({ parent_first_name: fname, parent_last_name: lname, phone }) => {
          if (phone && typeof obj[phone.trim()] === 'undefined') {
            obj[phone.trim()] = `${fname} ${lname || ''}`;
          }
        }
      );

      setNames(obj);
    }
  }, [state.leads, state.customers]);

  useEffect(() => {
    getCallLogsInterval();
  }, []);

  return (
    <section>
      <div>
        <TitleContainer>
          <TitleItems>
            <Title>Call Logs</Title>
            <TotalEntries>{state.calls.length}</TotalEntries>
          </TitleItems>
          <RefreshButton refreshList={refreshCallLogs} />
        </TitleContainer>
        <ReactTable
          data={state.calls.map(({ from, ...rest }) => ({
            ...rest,
            name: names[from.trim()] || '?',
            from,
          }))}
          columns={callLogsTableColumns}
        />
      </div>
    </section>
  );
};

export default CallLogsTable;
