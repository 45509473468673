import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';
import Button from './Button';
import RefreshIcon from '../assets/icons/refresh.svg';

const StyledRefreshIcon = styled(RefreshIcon)`
  width: 20px;
  height: 20px;
  fill: ${$.color.white};
  margin-right: ${$.layout().margin1}px;

  &.rotate {
    animation: rotate 2s infinite linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const Container = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${$.color.blue5};
  color: ${$.color.white};
  width: 100px;

  &:hover {
    background-color: ${$.color.blue4};
  }
`;

const RefreshButton = ({ refreshList }) => {
  const [refreshStatus, setRefreshStatus] = useState(false);

  return (
    <Container
      onClick={() => {
        setRefreshStatus(true);
        refreshList();

        setTimeout(() => {
          setRefreshStatus(false);
        }, 2000);
      }}
    >
      <StyledRefreshIcon className={refreshStatus === true ? 'rotate' : ''} />
      {refreshStatus === false ? 'Refresh' : 'Loading'}
    </Container>
  );
};

RefreshButton.defaultProps = {
  refreshList: () => {},
};

RefreshButton.propTypes = {
  refreshList: PropTypes.func,
};

export default RefreshButton;
