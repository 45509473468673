import { gql } from 'apollo-boost';

const createLeadGQL = gql`
  mutation leads(
    $comments: String
    $first_name: String
    $last_name: String
    $course: String
    $email: String
    $phone: String
    $location: String
  ) {
    createLead(lead: {
      comments: $comments,
      first_name: $first_name,
      last_name: $last_name,
      course: $course,
      email: $email,
      phone: $phone,
      location: $location
    }) {
      id
      comments
      first_name
      last_name
      course
      email
      phone
      location
      timestamp
    }
  }
`;

export default createLeadGQL;
