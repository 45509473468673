import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import SubRow from './SubRow';
import useActions from '../../utils/useActions';
import { getLeadsGQL } from '../../apollo';
import columns from './columns';
import { updateLeadsState } from '../../actions';
import { Title, TotalEntries, TitleContainer } from '../TableStyles';
import RefreshButton from '../RefreshButton';
import LoadingMessage from '../LoadingMessage';
import ReactTable from '../ReactTable';

const TitleItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeadsTable = () => {
  const [updateLogs] = useActions([updateLeadsState]);
  const state = useSelector(({ leads }) => ({ leads }));
  const leadTableColumns = React.useMemo(() => columns, []);
  const [getLeads] = useLazyQuery(getLeadsGQL, {
    onCompleted: ({ getLeads: result }) => {
      updateLogs(result);
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [refreshLeads] = useLazyQuery(getLeadsGQL, {
    onCompleted: ({ getLeads: result }) => {
      updateLogs(result);
      toast.success('Leads database table refreshed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Leads database table not refreshed. Please reload page.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  /**
   * Get the list the moment the component is mounted.
   * The list will automatically refresh if any changes are done to it through
   * redux action.
   */
  useEffect(() => {
    getLeads();
  }, []);

  return (
    <section>
      {state.leads && state.leads.length > 0 ? (
        <div>
          <TitleContainer>
            <TitleItems>
              <Title>Leads</Title>
              <TotalEntries>{state.leads.length}</TotalEntries>
            </TitleItems>
            <ButtonContainer>
              <RefreshButton refreshList={refreshLeads} />
            </ButtonContainer>
          </TitleContainer>
          <ReactTable
            data={state.leads}
            columns={leadTableColumns}
            SubRow={SubRow}
          />
        </div>
      ) : (
        <LoadingMessage itemName="leads" />
      )}
    </section>
  );
};

export default LeadsTable;
