import { gql } from 'apollo-boost';

const getLeadsGQL = gql`
  query leads {
    getLeads {
      id
      comments
      first_name
      last_name
      course
      email
      phone
      location
      timestamp
    }
  }
`;

export default getLeadsGQL;
