/* eslint-disable react/jsx-filename-extension, react/prop-types */

import React from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import ExpandRow from '../ReactTable/ExpandRow';
import { convertTimestampToStr } from '../../utils/globals';

const Highlight = styled.span`
  ${({ color }) => `
  color: ${color};
`}
`;

const Button = styled.span`
  color: ${$.color.blue5};
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

export default [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => <ExpandRow row={row} />,
    SubCell: () => null,
  },
  {
    Header: 'Timestamp',
    id: 'timestamp',
    accessor: (ele) =>
      `${convertTimestampToStr(ele.timestamp).date} ${
        ele.rowHighlight ? '(LATEST)' : ''
      }`,
    filter: 'fuzzyText',
    disableSorting: true,
  },
  {
    Header: 'Loaner',
    id: 'loaner',
    accessor: (ele) => {
      // We had to tell the page to refresh by using .assign() because
      // Gatsby's Link doesn't hard refresh
      // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/

      if (ele.deleted) {
        return ele.name;
      }

      return (
        <Button
          onClick={() => {
            window.location.assign(`/inventory/history?loan=${ele.loanID}`);
          }}
        >
          {ele.name}
        </Button>
      );
    },
    filter: 'fuzzyText',
    disableSorting: true,
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (ele) => (
      <Highlight color={ele.returned ? $.color.green2 : $.color.red2}>
        {ele.returned ? 'Returned' : 'Borrowed'}
      </Highlight>
    ),
    filter: 'fuzzyText',
    disableSorting: true,
  },
  {
    Header: 'Comments',
    id: 'comments',
    accessor: (ele) => ele.comments,
    filter: 'fuzzyText',
    disableSorting: true,
  },
];
