/**
 * Students and Customers are interchangeable terms in this project.
 * UI table is called customers but the database table is called students.
 * Why? No idea.
 */
import { gql } from 'apollo-boost';

const updateCustomerGQL = gql`
  mutation student(
    $id: String
    $address: String
    $campus: String
    $channel: String
    $course: String
    $course_start_date: String
    $course_time: String
    $dob: String
    $parent_email: String
    $parent_first_name: String
    $parent_last_name: String
    $phone: String
    $postal_code: String
    $school: String
    $school_grade: String
    $special_needs: String
    $special_needs_details: String
    $student_first_name: String
    $student_gender: String
    $student_last_name: String
    $timestamp: String
  ) {
    updateStudent(student: {
      id: $id,
      address: $address,
      campus: $campus,
      channel: $channel,
      course: $course,
      course_start_date: $course_start_date,
      course_time: $course_time,
      dob: $dob,
      parent_email: $parent_email,
      parent_first_name: $parent_first_name,
      parent_last_name: $parent_last_name,
      phone: $phone,
      postal_code: $postal_code,
      school: $school,
      school_grade: $school_grade,
      special_needs: $special_needs,
      special_needs_details: $special_needs_details,
      student_first_name: $student_first_name,
      student_last_name: $student_last_name,
      student_gender: $student_gender,
      timestamp: $timestamp
    }) {
      id
      address
      campus
      channel
      course
      course_start_date
      course_time
      dob
      parent_email
      parent_first_name
      parent_last_name
      phone
      postal_code
      school
      school_grade
      special_needs
      special_needs_details
      student_first_name
      student_gender
      student_last_name
      timestamp
    }
  }
`;

export default updateCustomerGQL;
