import React from 'react';
import {
  SelectColumnFilter,
  SpecialNeedsFilterColumn,
} from '../ReactTable/Filter';

import ExpandRow from '../ReactTable/ExpandRow';

export default [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => <ExpandRow row={row} />,
    SubCell: () => null,
  },
  ,
  {
    Header: 'Timestamp',
    columns: [
      {
        Header: 'Timestamp',
        id: 'timestamp',
        accessor: (ele) => ele.timestamp,
        filter: 'fuzzyText',
        sortType: 'strTimestampSort',
      },
    ],
  },
  {
    Header: 'Parent Info',
    columns: [
      {
        Header: 'First Name',
        id: 'parent_first_name',
        accessor: (ele) => ele.parent_first_name,
        filter: 'fuzzyText',
      },
      {
        Header: 'Last Name',
        id: 'parent_last_name',
        accessor: (ele) => ele.parent_last_name,
        filter: 'fuzzyText',
      },
      {
        Header: 'Email',
        id: 'parent_email',
        accessor: (ele) => ele.parent_email,
        filter: 'fuzzyText',
      },
      {
        Header: 'Phone No.',
        id: 'phone',
        accessor: (ele) => ele.phone,
        filter: 'fuzzyText',
      },
      {
        Header: 'Phone No. (Secondary)',
        id: 'phone_secondary',
        accessor: (ele) => ele.phone_secondary,
        filter: 'fuzzyText',
      },
    ],
  },
  {
    Header: 'Kid Info',
    columns: [
      {
        Header: 'First Name',
        id: 'student_first_name',
        accessor: (ele) => ele.student_first_name,
        filter: 'fuzzyText',
      },
      {
        Header: 'Last Name',
        id: 'student_last_name',
        accessor: (ele) => ele.student_last_name,
        filter: 'fuzzyText',
      },
      {
        Header: 'Gender',
        id: 'student_gender',
        accessor: 'student_gender',
        disableSorting: true,
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Date of Birth',
        id: 'dob',
        accessor: (ele) => ele.dob,
        filter: 'fuzzyText',
        sortType: 'strTimestampSort',
      },
      {
        Header: 'School',
        id: 'school',
        accessor: (ele) => ele.school,
        filter: 'fuzzyText',
      },
      {
        Header: 'Grade',
        id: 'school_grade',
        accessor: (ele) => ele.school_grade,
        filter: 'fuzzyText',
      },
      {
        Header: 'Special Needs',
        id: 'special_needs_details',
        disableSorting: true,
        accessor: 'special_needs_details',
        filter: (rows, _, value) => {
          return rows.filter(({ original: { special_needs_details } }) => {
            switch (value) {
              case 'Yes':
                return special_needs_details;
              case 'No':
                return !special_needs_details;
              case 'All':
                return true;
              default:
                return true;
            }
          });
        },
        Filter: SpecialNeedsFilterColumn,
      },
    ],
  },
  {
    Header: 'Other Info',
    columns: [
      {
        Header: 'Address',
        id: 'address',
        accessor: (ele) => ele.address,
        filter: 'fuzzyText',
      },
      {
        Header: 'Postcode',
        id: 'postal_code',
        accessor: (ele) => ele.postal_code,
        filter: 'fuzzyText',
      },
      {
        Header: 'Conversion Source',
        id: 'channel',
        accessor: (ele) => ele.channel,
        filter: 'fuzzyText',
      },
      {
        Header: 'Course Attended',
        id: 'course',
        accessor: (ele) => ele.course,
        filter: 'fuzzyText',
      },
      {
        Header: 'Course Start Date',
        id: 'course_start_date',
        accessor: (ele) => ele.course_start_date,
        filter: 'fuzzyText',
      },
      {
        Header: 'Timeslot',
        id: 'course_time',
        accessor: (ele) => ele.course_time,
        filter: 'fuzzyText',
      },
      {
        Header: 'Campus',
        id: 'campus',
        accessor: 'campus',
        filter: 'includes',
        disableSorting: true,
        Filter: SelectColumnFilter,
      },
      {
        Header: 'ID',
        id: 'id',
        accessor: (ele) => ele.id,
        filter: 'fuzzyText',
      },
    ],
  },
];
