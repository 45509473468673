import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import Logo from './Logo';
import Menu from './Menu';

const Container = styled.div`
  width: calc(100vw - 10%);
  height: 49px;
  padding: 10px 5%;
  box-shadow: ${$.dropShadow.normal};
  border-bottom: 1px solid ${$.color.gray1};
  display: flex;
  opacity: 1;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${$.color.background};
  transition: opacity 0.3s ${$.easingFn.standard},
    top 0.3s ${$.easingFn.standard};
  z-index: 2;
  top: 0;

  &.hide {
    opacity: 0;
    top: -70px;
  }

  & > * {
    height: 50px;
  }
`;

const handleHideNavbar = (prev) => ({
  prevTop: window.scrollY,
  hide: window.scrollY > prev.prevTop,
});

const Nav = () => {
  const [hideNavbar, setHideNavbar] = useState({ prevTop: 0, hide: false });

  useEffect(() => {
    const scrollCallback = () => {
      setHideNavbar(handleHideNavbar);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', scrollCallback);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', scrollCallback);
      }
    };
  }, []);

  return (
    <Container className={hideNavbar.hide === false ? '' : 'hide'}>
      <Logo />
      <Menu />
    </Container>
  );
};

export default Nav;

export { handleHideNavbar };
