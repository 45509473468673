const initialState = [
  {
    address: '131 Tai Keng Gardens',
    campus: 'Marine Parade (Parkway Centre #03-04)',
    channel: 'Print Media',
    coding_experience: 'None',
    course: 'Principles of Code 1',
    course_start_date: 'Holiday Camp: 18 Dec (Mon)',
    course_time: '1:30pm - 3:30pm',
    dob: '08-Apr-2004 0:0:0',
    id: 'acdb2ff5-e324-4f4c-be7e-ab6604212bac',
    parent_email: 'nouvelletan@yahoo.com.sg',
    parent_first_name: 'Sheila ',
    parent_last_name: 'Tan',
    phone: '+6596807563',
    postal_code: '535412',
    school: 'Montfort Secondary School',
    school_grade: 'Secondary 1 / Grade 7',
    special_needs: 'No',
    student_first_name: 'Qing Yao',
    student_gender: 'Male',
    student_last_name: 'Chan',
    timestamp: '07-Nov-2017 21:59:58',
  },
];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CUSTOMERS_STATE':
      return action.payload;
    case 'NEW_CUSTOMER_STATE':
      if (action.payload.id) {
        return [action.payload, ...state];
      }

      return state;
    case 'DELETE_CUSTOMER_STATE':
      if (action.payload) {
        return state.filter((ele) => ele.id !== action.payload);
      }

      return state;
    case 'UPDATE_CUSTOMER_STATE':
      if (action.payload.id) {
        return state.map((ele) => (ele.id === action.payload.id ? action.payload : ele));
      }

      return state;
    default:
      return state;
  }
};
