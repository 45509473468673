import React from 'react';
import styled from 'styled-components';

import { Form, FormTitle } from '../../FormModal/Content/formStyles';
import $ from '../../../styles/global';
import Members from './Members';

const StyledForm = styled(Form)`
  max-height: 150px;
  height: 100%;
`;

const Container = styled.div`
  border-radius: 10px;
  border: 1px solid ${$.color.gray1};
  height: 100%;
  overflow: hidden;
`;

const OnlineMembers = () => (
  <StyledForm>
    <FormTitle>Online Members</FormTitle>
    <Container>
      <Members />
    </Container>
  </StyledForm>
);

export default OnlineMembers;
