import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import { setHandled } from '../../../actions';
import useActions from '../../../utils/useActions';
import ChatContainer from './ChatContainer';
import TextBox from './TextBox';

const Container = styled.div`
  width: 70%;
`;

const Title = styled.div`
  height: calc(60px + 1px - ${13 * 2}px);
  background-color: ${$.color.whatsappBg};
  border-bottom: 1px solid ${$.color.whatsappBorder};
  width: calc(100% - ${$.layout().padding2 * 2}px);
  display: flex;
  flex-direction: column;
  padding: 13px ${$.layout().padding2}px;

  & > div {
    font-size: 12px;
    color: ${$.color.whatsappText};
  }
`;

const NoMessageRow = styled.div`
  color: ${$.color.whatsappText};
  font-size: 30px;
  text-align: center;
  padding-top: ${$.layout().padding4}px;
`;

const CustomerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ToggleHandled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin-right: ${$.layout().margin1}px;
  }

  & select {
    border: 1px solid ${$.color.gray1};
    font-size: 14px;
    min-width: 100px;
    border-radius: 5px;
  }
`;

const CustomerName = styled.div`
  font-size: 16px;
  color: ${$.color.black};
  margin-right: 10px;
`;

const showWhoIsTyping = (names) => {
  switch (names.length) {
    case 1:
      return `${names[0]} is typing...`;
    case 2:
      return `${names[0]} and ${names[1]} are typing...`;
    default:
      return `${names[0]} and ${names.length - 1} others are typing...`;
  }
};

const ChatBox = ({ names }) => {
  const state = useSelector(({ whatsapp, user }) => ({
    whatsapp,
    user,
  }));
  const { currentPhone, phoneList } = state.whatsapp;
  const fullName =
    names[currentPhone] && names[currentPhone].name
      ? names[currentPhone].name
      : currentPhone;
  const [addHandledBy] = useActions([setHandled]);
  const [typing, setTyping] = useState({});
  const handledState = phoneList.phone.filter(
    ({ phone }) => phone === currentPhone
  )[0];
  const { handledAt, handledBy } = handledState || {};
  const [caseHandled, setCaseHandled] = useState('ongoing');

  /**
   * Pings to all that you are typing/stopped typing.
   */
  useEffect(() => {
    const cb = (e) => {
      const { phone, name } = e.detail;

      switch (e.type) {
        case 'isTyping':
          setTyping((prev) => {
            const newPrev = { ...prev };

            newPrev[phone] = newPrev[phone] || [];
            newPrev[phone].push(name);

            return newPrev;
          });
          break;
        case 'stoppedTyping':
          setTyping((prev) => {
            const newPrev = { ...prev };

            if (newPrev[phone]) {
              const index = newPrev[phone].indexOf(name);

              if (index > -1) {
                newPrev[phone].splice(index, 1);
              }
            }

            return newPrev;
          });
          break;
        default:
          break;
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('isTyping', cb);
      window.addEventListener('stoppedTyping', cb);
    }

    return () => {
      window.removeEventListener('isTyping', cb);
      window.addEventListener('stoppedTyping', cb);
    };
  }, []);

  useEffect(() => {
    setCaseHandled(handledAt ? 'closed' : 'ongoing');
  }, [currentPhone, handledAt]);

  return (
    <Container>
      <Title>
        {currentPhone && (
          <>
            <CustomerTitle>
              <CustomerName>{fullName}</CustomerName>
              <ToggleHandled
                title={
                  handledAt ? `Case closed by ${handledBy}, ${handledAt}` : ''
                }
              >
                <div>{handledAt ? `${handledBy}, ${handledAt}` : ''}</div>
                <select
                  value={caseHandled}
                  onChange={(e) => {
                    setCaseHandled(e.target.value);

                    switch (e.target.value) {
                      case 'closed':
                        addHandledBy({
                          handledBy: state.user.name,
                          phone: currentPhone,
                        });
                        break;
                      case 'ongoing':
                        addHandledBy({ handledBy: '', phone: currentPhone });
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <option value="closed">Closed</option>
                  <option value="ongoing">Ongoing</option>
                </select>
              </ToggleHandled>
            </CustomerTitle>
            <div>
              {typing[currentPhone] && typing[currentPhone].length > 0
                ? showWhoIsTyping(typing[currentPhone])
                : ''}
            </div>
          </>
        )}
      </Title>
      {currentPhone ? (
        <>
          <ChatContainer />
          <TextBox />
        </>
      ) : (
        <NoMessageRow>No chat selected.</NoMessageRow>
      )}
    </Container>
  );
};

ChatBox.defaultProps = {
  names: {},
};

ChatBox.propTypes = {
  names: PropTypes.objectOf(PropTypes.object),
};

export default ChatBox;
