import { gql } from 'apollo-boost';

const createLoanGQL = gql`
  mutation loan($name: String, $comments: String, $totalHardwareHeld: Boolean) {
    createLoan(
      loan: {
        name: $name
        comments: $comments
        totalHardwareHeld: $totalHardwareHeld
      }
    ) {
      id
      name
      comments
      timestamp
      totalHardwareHeld
    }
  }
`;

export default createLoanGQL;
