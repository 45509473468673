import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';

import useActions from '../../../utils/useActions';
import { updateCallState } from '../../../actions';
import { updateCallGQL } from '../../../apollo';
import {
  Label,
  TextInput,
  FormInput,
  Form,
  SubmitButton,
} from '../../FormModal/Content/formStyles';
import EditIcon from '../../../assets/icons/edit.svg';

const MissedCallForm = () => {
  const [phone, setPhone] = useState(null);
  const [updateLog] = useActions([updateCallState]);
  const state = useSelector(({ calls, user }) => ({ calls, user }));
  const [updateOneCallLog] = useMutation(updateCallGQL, {
    onCompleted: ({ updateCall: result }) => {
      if (result && Object.keys(result).length > 0) {
        updateLog(result);
        toast.success('Successfully updated Call Log database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Failed to update Call Log database', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Failed to update Call Log database', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  return (
    <Form>
      <FormInput>
        <Label>Phone Number</Label>
        <TextInput
          placeholder="Phone Number"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          name="phone"
          value={phone || ''}
        />
      </FormInput>
      <SubmitButton
        onClick={() => {
          let foundMatch = false;
          const matchingMissedCallEntriesCalled = state.calls.filter(
            (ele) =>
              phone === ele.from &&
              ele.status === 'missed' &&
              ele.handled !== true
          );

          if (!phone) {
            return;
          }

          matchingMissedCallEntriesCalled.forEach((ele) => {
            const updatedEntry = {
              ...ele,
              handled: true,
              handledBy: state.user.name,
              handledTime: Date.now(),
            };

            if (updatedEntry.outbound === null) {
              delete updatedEntry.outbound;
            }

            updateOneCallLog({ variables: { ...updatedEntry } });
            setPhone(null);
            foundMatch = true;
          });

          if (foundMatch === false) {
            toast.info('No matching entry found.', {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            state.user.ws.sendAction(
              `updated missed call entry for the number ${phone}.`
            );
          }
        }}
      >
        <EditIcon />
        Update
      </SubmitButton>
    </Form>
  );
};

export default MissedCallForm;
