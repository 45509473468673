import { gql } from 'apollo-boost';

const getLoanGQL = gql`
  query loan($id: String!) {
    getLoan(id: $id) {
      id
      name
      comments
      timestamp
      totalHardwareHeld
    }
  }
`;

export default getLoanGQL;
