import updateCallsState from './phone-dashboard/Calls/updateCallsState';
import updateCallState from './phone-dashboard/Calls/updateCallState';

import updateLeadsState from './phone-dashboard/Leads/updateLeadsState';
import updateLeadState from './phone-dashboard/Leads/updateLeadState';
import deleteLeadState from './phone-dashboard/Leads/deleteLeadState';
import newLeadState from './phone-dashboard/Leads/newLeadState';

import updateCustomersState from './phone-dashboard/Customers/updateCustomersState';
import deleteCustomerState from './phone-dashboard/Customers/deleteCustomerState';
import newCustomerState from './phone-dashboard/Customers/newCustomerState';
import updateCustomerState from './phone-dashboard/Customers/updateCustomerState';

import getPhoneList from './phone-dashboard/Whatsapp/getPhoneList';
import getAllMessages from './phone-dashboard/Whatsapp/getAllMessages';
import newIncomingMessage from './phone-dashboard/Whatsapp/newIncomingMessage';
import clearNoti from './phone-dashboard/Whatsapp/clearNoti';
import statusUpdate from './phone-dashboard/Whatsapp/statusUpdate';
import setCurrentPhone from './phone-dashboard/Whatsapp/setCurrentPhone';
import setHandled from './phone-dashboard/Whatsapp/setHandled';

import updateHardwaresState from './inventory-dashboard/Hardware/updateHardwaresState';
import newHardwareState from './inventory-dashboard/Hardware/newHardwareState';
import updateHardwareState from './inventory-dashboard/Hardware/updateHardwareState';
import deleteHardwareState from './inventory-dashboard/Hardware/deleteHardwareState';

import newLoanState from './inventory-dashboard/Loan/newLoanState';
import updateLoanState from './inventory-dashboard/Loan/updateLoanState';
import deleteLoanState from './inventory-dashboard/Loan/deleteLoanState';
import updateLoansState from './inventory-dashboard/Loan/updateLoansState';

import deleteHistoryState from './inventory-dashboard/History/deleteHistoryState';
import updateHistoriesState from './inventory-dashboard/History/updateHistoriesState';
import clearHistoriesState from './inventory-dashboard/History/clearHistoriesState';

import setFormModal from './Modal/setFormModal';
import clearModal from './Modal/clearModal';
import setDeleteModal from './Modal/setDeleteModal';

import setUser from './setUser';

export {
  updateCallsState,
  updateCallState,
  updateLeadsState,
  updateLeadState,
  deleteLeadState,
  newLeadState,
  updateCustomersState,
  deleteCustomerState,
  newCustomerState,
  updateCustomerState,
  setFormModal,
  clearModal,
  setDeleteModal,
  setUser,
  getPhoneList,
  getAllMessages,
  clearNoti,
  setHandled,
  newIncomingMessage,
  statusUpdate,
  setCurrentPhone,
  updateHardwaresState,
  newHardwareState,
  updateHardwareState,
  deleteHardwareState,
  newLoanState,
  updateLoanState,
  updateLoansState,
  deleteLoanState,
  deleteHistoryState,
  updateHistoriesState,
  clearHistoriesState,
};
