import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';

import { getHardwareGQL, getLoanGQL } from '../../apollo';
import $ from '../../styles/global';
import { addKeys, convertTimestampToStr } from '../../utils/globals';

const Name = styled.h1`
  color: ${$.color.blue4};
  margin-bottom: ${$.layout().margin2}px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.gray2};

  & tr {
    background-color: ${$.color.white2};
    &:not(:last-child) {
      border-bottom: 1px solid ${$.color.gray2};
    }
    & > td {
      padding: ${$.layout().padding1}px;
      min-width: 100px;
      &:first-child {
        border-right: 1px solid ${$.color.gray2};
      }
    }
  }
`;

const Info = ({ type, id }) => {
  const [item, setItem] = useState([]);
  const parseItem = (result) => {
    const newItem = [];

    Object.keys(result)
      .filter((key) => key.indexOf('__') === -1)
      .forEach((key) => {
        const mappedKey = () => {
          switch (key) {
            case 'totalHardwareHeld':
              return 'Show total hardware held';
            case 'id':
              return 'ID';
            case 'serialNumber':
              return 'Serial Number';
            case 'os':
              return 'Operating System';
            case 'appleID':
              return 'Apple ID';
            case 'type':
              return 'Hardware Type';
            case 'timestamp':
              return 'Last Updated';
            default:
              return key[0].toUpperCase() + key.substring(1, key.length);
          }
        };
        let value;

        switch (typeof result[key]) {
          case 'boolean':
            value = result[key] ? 'Yes' : 'No';
            break;
          case 'number':
            if (key === 'timestamp') {
              value = convertTimestampToStr(result[key]).date;
            }
            break;
          default:
            value = result[key] || '-';
            break;
        }

        newItem.push({
          name: mappedKey(),
          value,
        });
      });

    return addKeys(newItem);
  };
  const [getLoan] = useLazyQuery(getLoanGQL, {
    onCompleted: ({ getLoan: result }) => {
      setItem(() => parseItem(result));
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const [getHardware] = useLazyQuery(getHardwareGQL, {
    onCompleted: ({ getHardware: result }) => {
      setItem(() => parseItem(result));
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    switch (type) {
      case 'loan':
        getLoan({ variables: { id } });
        break;
      case 'hardware':
        getHardware({ variables: { id } });
        break;
      default:
        break;
    }
  }, []);

  return (
    <section>
      <div>
        <Name>
          {`${type[0].toUpperCase() + type.substr(1, type.length)}: `}
          {item.length > 0
            ? item.filter(({ name }) => name === 'Name')[0].value
            : 'Unknown ID'}
        </Name>
        {item.length > 0 && (
          <Table>
            <tbody>
              {item.map(({ key, name, value }) => {
                if (name === 'ID') {
                  return <tr key={key} />;
                }

                return (
                  <tr key={key}>
                    <td>{name}</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </section>
  );
};

Info.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Info;
