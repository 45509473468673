import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Title } from '../TableStyles';
import { newIncomingMessage, statusUpdate, setHandled } from '../../actions';
import useActions from '../../utils/useActions';
import $ from '../../styles/global';
import PhoneList from './PhoneList';
import ChatBox from './ChatBox';
import { scrollToBottom } from './helper';

const WhatsappContainer = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  box-shadow: ${$.dropShadow.normal};
  margin-top: ${$.layout().margin3}px;
  height: 750px;
  overflow: hidden;
  font-family: 'Lucida Grande', Verdana, sans-serif;
`;

const WhatsappTable = () => {
  const [names, setNames] = useState({});
  const [incomingMessage, newStatus, addHandledBy] = useActions([
    newIncomingMessage,
    statusUpdate,
    setHandled,
  ]);
  const state = useSelector(({ whatsapp, customers, leads }) => ({
    whatsapp,
    customers,
    leads,
  }));

  /**
   * Listen to new Twilio message and status callbacks.
   * We need to update our redux store with these new messages/statuses.
   */
  useEffect(() => {
    const incomingCB = (e) => {
      const chatArea = document.getElementsByClassName('chatarea');

      incomingMessage(e.detail);
      addHandledBy({ phone: e.detail.phone, handledBy: '' });

      if (chatArea && chatArea[0] && chatArea[0] === document.activeElement) {
        scrollToBottom();
      }
    };
    const statusCB = (e) => {
      newStatus(e.detail);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('incomingMessage', incomingCB);
      window.addEventListener('statusUpdate', statusCB);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('incomingMessage', incomingCB);
        window.removeEventListener('statusUpdate', statusCB);
      }
    };
  }, []);

  /**
   * Look for the customer's name to replace phone numbers in
   * the Whatsapp chat list.
   */
  useEffect(() => {
    const obj = {};
    const {
      customers,
      leads,
      whatsapp: { phoneList },
    } = state;
    const hasNames =
      customers.length >= 1 && leads.length >= 1 && phoneList.phone.length > 0;

    if (hasNames) {
      phoneList.phone.forEach(
        ({ phone: whatsappPhone, handledBy, handledAt }) => {
          obj[whatsappPhone] = {
            name: '',
            handledAt,
            handledBy,
          };

          customers.forEach(
            ({
              phone,
              parent_first_name: firstName,
              parent_last_name: lastName,
            }) => {
              if (phone && phone.indexOf(whatsappPhone) > -1) {
                obj[whatsappPhone].name = `${firstName} ${lastName}`;
              }
            }
          );

          leads.forEach(
            ({ phone, first_name: firstName, last_name: lastName }) => {
              if (phone && phone.indexOf(whatsappPhone) > -1) {
                obj[whatsappPhone].name = `${firstName} ${lastName}`;
              }
            }
          );
        }
      );

      setNames(obj);
    }
  }, [state.customers, state.leads]);

  return (
    <section>
      <div>
        <Title>Whatsapp</Title>
        <WhatsappContainer>
          <PhoneList names={names} />
          <ChatBox names={names} />
        </WhatsappContainer>
      </div>
    </section>
  );
};

export default WhatsappTable;
