import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { createLeadGQL } from '../../../apollo';
import useActions from '../../../utils/useActions';
import { newLeadState } from '../../../actions';
import {
  Label,
  TextAreaInput,
  TextInput,
  FormTitle,
  FormInput,
  Form,
  SubmitButton,
  DoubleFormInput,
} from '../../FormModal/Content/formStyles';
import AddIcon from '../../../assets/icons/add.svg';

const NewLeadForm = () => {
  const [formData, setFormData] = useState({});
  const [newLog] = useActions([newLeadState]);
  const [createOneLead] = useMutation(createLeadGQL, {
    onCompleted: ({ createLead: result }) => {
      if (result && Object.keys(result).length > 0) {
        newLog(result);
        toast.success('Lead successfully added to leads database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to add lead into leads database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to add lead into leads database.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Form>
      <FormTitle>New Lead</FormTitle>
      <DoubleFormInput>
        <FormInput>
          <Label>First name</Label>
          <TextInput
            name="first_name"
            value={formData.first_name || ''}
            onChange={handleInputChange}
            placeholder="First name"
          />
        </FormInput>
        <FormInput>
          <Label>Last name</Label>
          <TextInput
            name="last_name"
            value={formData.last_name || ''}
            onChange={handleInputChange}
            placeholder="Last name"
          />
        </FormInput>
      </DoubleFormInput>
      <DoubleFormInput>
        <FormInput>
          <Label>Phone</Label>
          <TextInput
            name="phone"
            value={formData.phone || ''}
            onChange={handleInputChange}
            placeholder="Phone"
          />
        </FormInput>
        <FormInput>
          <Label>Email</Label>
          <TextInput
            name="email"
            value={formData.email || ''}
            onChange={handleInputChange}
            placeholder="Email"
          />
        </FormInput>
      </DoubleFormInput>
      <FormInput>
        <Label>Course</Label>
        <TextInput
          name="course"
          value={formData.course || ''}
          onChange={handleInputChange}
          placeholder="Course"
        />
      </FormInput>
      <FormInput>
        <Label>Location</Label>
        <TextInput
          name="location"
          value={formData.location || ''}
          onChange={handleInputChange}
          placeholder="Location"
        />
      </FormInput>
      <FormInput>
        <Label>Comments</Label>
        <TextAreaInput
          name="comments"
          value={formData.comments || ''}
          onChange={handleInputChange}
          placeholder="Comments"
        />
      </FormInput>
      <SubmitButton
        onClick={() => {
          const currData = { ...formData };

          if (Object.keys(currData).length > 0) {
            createOneLead({ variables: { ...currData } });
            setFormData({});
          }
        }}
      >
        <AddIcon />
        Create
      </SubmitButton>
    </Form>
  );
};

export default NewLeadForm;
