import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../../../styles/global';
import useActions from '../../../../utils/useActions';
import { importOneImage } from '../../../../utils/globals';
import { getAllMessages } from '../../../../actions';
import MessageRow from './MessageRow';
import LoaderIcon from '../../../../assets/icons/loader.svg';

const Container = styled.div`
  width: 100%;
  position: relative;
  /** Remove height of the chat container title and the chat area below. */
  height: calc(100% - 60px - 100px - 2px);
`;

const InnerContainer = styled.div`
  padding: ${$.layout().padding2}px;
  width: calc(100% - ${$.layout().padding2 * 2}px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: auto;
  height: calc(100% - ${$.layout().padding2 * 2}px);

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-right: ${$.layout().margin5}px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${$.color.white};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${$.color.blue4};
  }

  & > * {
    margin-bottom: ${$.layout().margin4}px;
  }
`;

const Background = styled.div`
  ${({ url }) => `
  z-index: -1;
  background-color:rgb(229, 221, 213);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
    background-image: url(${url});
    background-repeat-x: repeat;
    background-repeat-y: repeat;
    opacity: 0.07;
  }
`}
`;

const DateRow = styled.div`
  text-align: center;
  align-self: center;
  border: none;
  padding: 10px;
  background-color: ${$.color.white};
  text-transform: uppercase;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: ${$.color.blue5};
  margin-bottom: ${$.layout().margin3}px;
`;

const LoadMoreButton = styled.div`
  ${({ loading }) => `
  margin: 0 auto ${$.layout().margin2}px auto;
  border: none;
  background-color: ${$.color.white};
  color: ${$.color.black};
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  border-radius: 15px;
  font-size: 13px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: ${$.dropShadow.normal};
  ${loading ? 'pointer-events: none;' : ''}

  svg {
    width: 18px;
    height: 18px;
    fill: ${$.color.black};
  }

  &:hover {
    cursor: pointer;
    box-shadow: ${$.dropShadow.repressed};
  }
`}
`;

/**
 * Determine if we need to show the date of the messages as an individual row,
 * and create said timestamp text. If the two messages are from 2 different
 * days, show the individual row. E.g. 8 DEC or 10 NOV 2019
 * @param {number} currTimestamp - Current message's timestamp
 * @param {number} prevTimestamp - Previous message's timestamp
 */
const setDateRow = (currTimestamp, prevTimestamp) => {
  const year = new Date().getFullYear();
  const currDate = new Date(currTimestamp).getDate();
  const prevDate = new Date(prevTimestamp).getDate();
  const currDateString = new Date(currTimestamp).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    timeZone: 'Asia/Hong_Kong',
  });
  const currYear = new Date(currTimestamp).getFullYear();

  if (typeof prevTimestamp === 'undefined' || currDate !== prevDate) {
    return `${currDateString}${currYear !== year ? `, ${currYear}` : ''}`;
  }

  return '';
};

const bgImage = importOneImage('whatsapp-bg-light.png');

const ChatContainer = () => {
  const state = useSelector(({ whatsapp, user }) => ({ whatsapp, user }));
  const { currentPhone, messages, statuses } = state.whatsapp;
  const [getChat] = useActions([getAllMessages]);
  const [loadMore, setLoadMore] = useState({ total: 0, loading: false });
  const hasPagination =
    messages[currentPhone] &&
    messages[currentPhone].Items.length > 0 &&
    messages[currentPhone].LastEvaluatedKey;

  useEffect(() => {
    const items = messages[currentPhone] && messages[currentPhone].Items;

    if (
      items &&
      items.length > 0 &&
      loadMore.total !== 0 &&
      loadMore.total !== items.length
    ) {
      setLoadMore({ total: items.length, loading: false });
    }
  }, [state]);

  return (
    <Container>
      <InnerContainer className="messages-area">
        {hasPagination && (
          <LoadMoreButton
            disabled={loadMore.loading}
            onClick={() => {
              /**
               * 1. On click, show the loading gif.
               * 2. Pull more messages.
               * 3. Once the messages are updated, the loading gif is hidden.
               */
              if (
                messages[currentPhone] &&
                messages[currentPhone].LastEvaluatedKey
              ) {
                setLoadMore({
                  total: messages[currentPhone].Items.length,
                  loading: true,
                });
                getChat({
                  phone: currentPhone,
                  key: messages[currentPhone].LastEvaluatedKey,
                  name: state.user.name,
                });
              }
            }}
          >
            {loadMore.loading ? <LoaderIcon /> : 'Load more messages'}
          </LoadMoreButton>
        )}
        <>
          {(messages[currentPhone]?.Items || []).map(
            (
              {
                from,
                message,
                timestamp,
                handledBy,
                attachment,
                status,
                messageSid,
              },
              index
            ) => {
              const obj = messages[currentPhone];
              const currTimestamp = Number(timestamp);
              let showDate = '';
              /**
               * Pulls status from status update messages from WS.
               */
              const newStatus = (() => {
                if (statuses[timestamp]) {
                  return statuses[timestamp].status;
                }

                if (statuses[messageSid]) {
                  return statuses[messageSid].status;
                }

                return status;
              })();

              /**
               * Show the individual day row if this is the first message we get */
              if (index === 0 && typeof obj.LastEvaluatedKey === 'undefined') {
                showDate = setDateRow(currTimestamp);
              }

              /**
               * For every new incoming messages, we check if we need to create
               * a new individual day row.
               */
              if (index > 0) {
                showDate = setDateRow(
                  currTimestamp,
                  Number(obj.Items[index - 1].timestamp)
                );
              }

              return (
                <React.Fragment key={`message_${timestamp}`}>
                  {showDate ? <DateRow>{showDate}</DateRow> : ''}
                  <MessageRow
                    from={from}
                    name={state.user.name}
                    message={message}
                    status={newStatus}
                    timestamp={timestamp}
                    handledBy={handledBy}
                    attachment={attachment}
                  />
                </React.Fragment>
              );
            }
          )}
        </>
      </InnerContainer>
      <Background url={bgImage}>
        <div />
      </Background>
    </Container>
  );
};

export default ChatContainer;
