/**
 * Students and Customers are interchangeable terms in this project.
 * UI table is called customers but the database table is called students.
 * Why? No idea.
 */

import { gql } from 'apollo-boost';

const deleteCustomerGQL = gql`
  mutation student($id: String!) {
    deleteStudent(id: $id)
  }
`;

export default deleteCustomerGQL;
