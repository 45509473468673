import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTable, useRowSelect, useFilters, usePagination } from 'react-table';

import $ from '../../../../styles/global';
import { filterTypes, defaultColumn } from './Filter';

const Container = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  color: ${$.color.blue4};
  width: 100%;
  border: 1px solid ${$.color.gray2};

  a {
    color: ${$.color.blue5};
    text-decoration: none;
    font-weight: bold;
    &:visited {
      color: ${$.color.blue5};
    }
  }

  & tbody {
    & tr {
      transition: all 0.3s ease;
      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.03);
      }
      &:last-child {
        border-bottom: 1px solid ${$.color.gray2};
        & > td {
          border-bottom: 1px solid ${$.color.gray2};
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      & > td {
        border: 1px solid rgba(0, 0, 0, 0.02);
        padding: ${$.layout().padding1}px ${$.layout().padding2}px;
        &:first-child {
          border-left: 1px solid ${$.color.gray2};
        }
        &:not(:first-child) {
          min-width: 200px;
        }
        &:last-child {
          border-right: 1px solid ${$.color.gray2};
        }
      }
    }
  }
`;

const THCell = styled.th`
  background-color: '#dae1ed';
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  border-top: 1px solid ${$.color.gray2};
  border-bottom: 1px solid ${$.color.gray2};
  border-left: 1px solid ${$.color.gray2};
  border-right: 1px solid ${$.color.gray2};
  font-size: 14px;

  & > div {
    margin-top: 5px;
  }
`;

const RadioButton = styled.input.attrs({ type: 'radio' })`
  width: 10px !important;
  height: 10px !important;
`;

const IndeterminateRadio = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <RadioButton ref={resolvedRef} {...rest} />;
  }
);

const SearchTable = ({
  data,
  columns,
  setFormData,
  name,
  clearTable,
  setClearTable,
}) => {
  const useRadioColumn = ({ visibleColumns }) => {
    visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: 'selection',
        Header: '',
        Cell: ({ row }) => (
          <IndeterminateRadio
            {...row.getToggleRowSelectedProps()}
            onClick={() => {
              toggleAllRowsSelected(false);
              toggleRowSelected(row.id, true);
            }}
          />
        ),
      },
      ...columns,
    ]);
  };
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    toggleAllRowsSelected,
    toggleRowSelected,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      filterTypes: filterTypes(),
      defaultColumn: defaultColumn(),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters,
    usePagination,
    useRowSelect,
    useRadioColumn
  );

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      setFormData((prev) => ({
        ...prev,
        [name]: selectedFlatRows[0].original.id,
      }));
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (clearTable) {
      toggleAllRowsSelected(false);
      setClearTable(false);
    }
  }, [clearTable]);

  return (
    <Container id={name + '_table'}>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <THCell {...column.getHeaderProps()}>
                  <span>{column.render('Header')}</span>
                  {column.canFilter && column.filter
                    ? column.render('Filter')
                    : null}
                </THCell>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

SearchTable.defaultProps = {
  data: [],
  columns: [],
  name: '',
  clearTable: false,
  setClearTable: () => {},
  setFormData: () => {},
};

SearchTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  clearTable: PropTypes.bool,
  setFormData: PropTypes.func,
  setClearTable: PropTypes.func,
  name: PropTypes.string,
};

export default SearchTable;
