import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';

import useActions from '../../utils/useActions';
import { newLoanState } from '../../actions';
import { createLoanGQL } from '../../apollo';
import AddIcon from '../../assets/icons/add.svg';
import {
  FormTitle,
  Label,
  FormInput,
  TextAreaInput,
  TextInput,
  Form,
  SubmitButton,
  QuestionCheckbox,
} from '../FormModal/Content/formStyles';

const NewLoanForm = () => {
  const [formData, setFormData] = useState({ totalHardwareHeld: false });
  const [newLog] = useActions([newLoanState]);
  const [createOneLoan] = useMutation(createLoanGQL, {
    onCompleted: ({ createLoan: result }) => {
      if (result && Object.keys(result).length > 0) {
        newLog(result);
        toast.success('Loan successfully added to database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to add loan into database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to add loan into database.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const handleInputChange = (e) => {
    const {
      target: { name, value, checked, type },
    } = e;

    switch (type) {
      case 'checkbox':
        setFormData((prev) => ({ ...prev, [name]: checked }));
        break;
      default:
        setFormData((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  return (
    <Form>
      <FormTitle>New Loan</FormTitle>
      <FormInput>
        <Label>Name</Label>
        <TextInput
          name="name"
          value={formData.name || ''}
          onChange={handleInputChange}
          placeholder="Name"
        />
      </FormInput>
      <FormInput>
        <QuestionCheckbox>
          <label>Show total hardware held?</label>
          <input
            name="totalHardwareHeld"
            type="checkbox"
            checked={formData.totalHardwareHeld || false}
            onChange={handleInputChange}
          />
        </QuestionCheckbox>
      </FormInput>
      <FormInput>
        <Label>Comments</Label>
        <TextAreaInput
          name="comments"
          value={formData.comments || ''}
          onChange={handleInputChange}
          placeholder="Comments"
        />
      </FormInput>
      <SubmitButton
        onClick={() => {
          const currData = { ...formData };

          if (currData.name) {
            createOneLoan({ variables: { ...currData } });
            setFormData({});
          }
        }}
      >
        <AddIcon />
        Create
      </SubmitButton>
    </Form>
  );
};

export default NewLoanForm;
