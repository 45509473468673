import { ADDRESS } from '../../utils/globals';

export default [
  {
    label: 'Parent Info',
    inputs: [
      [
        {
          label: 'First name',
          name: 'parent_first_name',
        },
        {
          label: 'Last name',
          name: 'parent_last_name',
        },
        {
          label: 'Email',
          name: 'parent_email',
        },
      ],
      [
        {
          label: 'Phone No.',
          name: 'phone',
        },
        {
          label: 'Phone No. (Secondary)',
          name: 'phone_secondary',
        },
      ],
    ],
  },
  {
    label: 'Kid Info',
    inputs: [
      [
        {
          name: 'student_first_name',
          label: 'First name',
        },
        {
          name: 'student_last_name',
          label: 'Last name',
        },
        {
          type: 'select',
          name: 'student_gender',
          label: 'Gender',
          options: [
            { text: 'Male', value: 'Male', key: 'm' },
            {
              text: 'Female',
              value: 'Female',
              key: 'f',
            },
          ],
        },
      ],
      [
        {
          type: 'text',
          name: 'dob',
          label: 'Date of Birth',
        },
        {
          name: 'school',
          label: 'School',
        },
        {
          type: 'select',
          name: 'school_grade',
          label: 'Grade',
          options: [
            {
              key: 'p1',
              text: 'Primary 1 / Grade 1',
              value: 'Primary 1 / Grade 1',
            },
            {
              key: 'p2',
              text: 'Primary 2 / Grade 2',
              value: 'Primary 2 / Grade 2',
            },
            {
              key: 'p3',
              text: 'Primary 3 / Grade 3',
              value: 'Primary 3 / Grade 3',
            },
            {
              key: 'p4',
              text: 'Primary 4 / Grade 4',
              value: 'Primary 4 / Grade 4',
            },
            {
              key: 'p5',
              text: 'Primary 5 / Grade 5',
              value: 'Primary 5 / Grade 5',
            },
            {
              key: 'p6',
              text: 'Primary 6 / Grade 6',
              value: 'Primary 6 / Grade 6',
            },
            {
              key: 's1',
              text: 'Secondary 1 / Grade 7',
              value: 'Secondary 1 / Grade 7',
            },
            {
              key: 's2',
              text: 'Secondary 2 / Grade 8',
              value: 'Secondary 2 / Grade 8',
            },
            {
              key: 's3',
              text: 'Secondary 3 / Grade 9',
              value: 'Secondary 3 / Grade 9',
            },
            {
              key: 's4',
              text: 'Secondary 4 / Grade 10',
              value: 'Secondary 4 / Grade 10',
            },
          ],
        },
      ],
      [
        {
          label: 'Special Needs',
          name: 'special_needs',
        },
      ],
    ],
  },
  {
    label: 'Other Info',
    inputs: [
      [
        {
          type: 'textarea',
          name: 'address',
          label: 'Address',
        },
      ],
      [
        {
          name: 'postal_code',
          label: 'Postcode',
        },
        {
          name: 'channel',
          label: 'Conversion Source',
        },
      ],
      [
        {
          type: 'select',
          name: 'course',
          label: 'Course Attended',
          options: [
            { key: 'basics1', text: 'Basics 1', value: 'Basics 1' },
            { key: 'basics2', text: 'Basics 2', value: 'Basics 2' },
            { key: 'basics3', text: 'Basics 3', value: 'Basics 3' },
            { key: 'basics4', text: 'Basics 4', value: 'Basics 4' },
            { key: 'basics5', text: 'Basics 5', value: 'Basics 5' },
            { key: 'basics6', text: 'Basics 6', value: 'Basics 6' },
            { key: 'basicsx', text: 'Basics X', value: 'Basics X' },
            { key: 'jrpython1', text: 'Jr Python 1', value: 'Jr Python 1' },
            { key: 'jrpython2', text: 'Jr Python 2', value: 'Jr Python 2' },
            { key: 'jrpython3', text: 'Jr Python 3', value: 'Jr Python 3' },
            { key: 'jrpython4', text: 'Jr Python 4', value: 'Jr Python 4' },
            {
              key: 'principles1',
              text: 'Principles 1',
              value: 'Principles 1',
            },
            {
              key: 'principles2',
              text: 'Principles 2',
              value: 'Principles 2',
            },
            {
              key: 'principles3',
              text: 'Principles 3',
              value: 'Principles 3',
            },
            {
              key: 'principles4',
              text: 'Principles 4',
              value: 'Principles 4',
            },
            {
              key: 'principles5',
              text: 'Principles 5',
              value: 'Principles 5',
            },
            {
              key: 'principles6',
              text: 'Principles 6',
              value: 'Principles 6',
            },
            {
              key: 'principles7',
              text: 'Principles 7',
              value: 'Principles 7',
            },
            {
              key: 'principles8',
              text: 'Principles 8',
              value: 'Principles 8',
            },
            {
              key: 'principlesx',
              text: 'Principles X',
              value: 'Principles X',
            },
            {
              key: 'java1',
              text: 'Java 1',
              value: 'Java 1',
            },
            {
              key: 'java2',
              text: 'Java 2',
              value: 'Java 2',
            },
            {
              key: 'ibtutoring',
              text: 'IB Tutoring',
              value: 'IB Tutoring',
            },
            {
              key: 'olevel',
              text: 'O Level',
              value: 'O Level',
            },
          ],
        },
        {
          type: 'text',
          name: 'course_start_date',
          label: 'Course Start Date',
        },
      ],
      [
        {
          type: 'text',
          name: 'course_time',
          label: 'Timeslot',
        },
        {
          type: 'select',
          name: 'campus',
          label: 'Campus',
          options: [
            {
              key: 'mp',
              text: ADDRESS.MP.LONG,
              value: ADDRESS.MP.LONG,
            },
            {
              key: 'bt',
              text: ADDRESS.BT.LONG,
              value: ADDRESS.BT.LONG,
            },
            {
              key: 'bs',
              text: ADDRESS.BS.LONG,
              value: ADDRESS.BS.LONG,
            },
            {
              key: 'tp',
              text: ADDRESS.TP.LONG,
              value: ADDRESS.TP.LONG,
            },
            {
              key: 'online',
              text: 'Online',
              value: 'Online',
            },
          ],
        },
      ],
    ],
  },
];
