import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';

const Container = styled.div`
  border: 1px solid ${$.color.gray1};
  border-radius: 8px;
  & > * {
    border-bottom: 1px solid ${$.color.gray1};
    &:last-child {
      border-bottom: none;
    }
  }
`;

const Label = styled.div`
  width: 20%;
  align-self: stretch;
  font-size: 14px;
  font-weight: bold;
  color: ${$.color.blue5};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    padding: ${$.layout().padding2}px;
    &:first-child {
      border-right: 1px solid ${$.color.gray1};
    }
  }
`;

const Info = styled.textarea.attrs({ readOnly: true })`
  border: none;
  resize: none;
  width: 80%;
  line-height: 1.3em;
  font-size: 15px;
  border-radius: 8px;
  color: ${$.color.blue4};
  background-color: ${$.color.background};
`;

const ConfirmationContainer = ({ components, inputState }) => {
  /* eslint-disable-next-line */
  const setConfirmationComponent = ({ label, name, type }) => {
    let value = inputState[name];

    switch (typeof value) {
      case 'boolean':
        value = value ? 'Yes' : 'No';
        break;
      default:
        value = inputState[name] || '';
        break;
    }

    return (
      <Row key={`confirmation-info-${name}`}>
        <Label>{label}</Label>
        <Info value={value} rows={type === 'textarea' ? 5 : 1} />
      </Row>
    );
  };

  return (
    <Container>
      {components.map(({ inputs = [] }, index) => {
        const group = inputs.map((input, subIndex) => {
          if (input.length > 1) {
            return (
              <React.Fragment key={`confirmation-group-${subIndex}`}>
                {input.map((inputItems) =>
                  setConfirmationComponent({
                    ...inputItems,
                  })
                )}
              </React.Fragment>
            );
          }
          return setConfirmationComponent({ ...input[0] });
        });

        return (
          <React.Fragment key={`confirmation-list-${index}`}>
            {group}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

ConfirmationContainer.propTypes = {
  inputState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
};

export default ConfirmationContainer;
