/**
 * Students and Customers are interchangeable terms in this project.
 * UI table is called customers but the database table is called students.
 * Why? No idea.
 */

import { gql } from 'apollo-boost';

const getCustomersGQL = gql`
   query student {
     getStudents {
       id
       address
       campus
       channel
       course
       course_start_date
       course_time
       dob
       parent_email
       parent_first_name
       parent_last_name
       phone
       postal_code
       school
       school_grade
       special_needs
       special_needs_details
       student_first_name
       student_last_name
       student_gender
       timestamp
     }
   }
 `;

export default getCustomersGQL;
