import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../../styles/global';
import NewTabIcon from '../../../../../../assets/icons/open-new-tab.svg';

const Container = styled.a`
  transition: all 0.3s ease;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  width: calc(100% - ${$.layout().padding1 * 2}px);
  height: calc(33px - ${$.layout().padding1 * 2}px);
  padding: ${$.layout().padding1}px;
  background-color: ${$.color.blue5};
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${$.color.white};
  ${$.ellipsis}
  & > svg {
    fill: ${$.color.white};
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${$.color.blue4};
  }
`;

const NewTab = ({ url, type }) => (
  <Container
    href={url}
    title="Click to open in new tab"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span>{type}</span>
    <NewTabIcon />
  </Container>
);

NewTab.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default NewTab;
