import { gql } from 'apollo-boost';

const getTotalHardwareHeldGQL = gql`
  query loan {
    getTotalHardwareHeld {
      id
      name
      count
    }
  }
`;

export default getTotalHardwareHeldGQL;
