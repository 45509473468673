import React from 'react';
import { convertTimestampToStr } from '../../utils/globals';
import { SelectColumnFilter } from '../ReactTable/Filter';

export default [
  {
    Header: 'Type',
    columns: [
      {
        Header: 'Type',
        id: 'outbound',
        accessor: (ele) =>
          typeof ele.outbound === 'undefined' || !ele.outbound
            ? 'Inbound'
            : 'Outbound',
        filter: 'includes',
        disableSorting: true,
        Filter: SelectColumnFilter,
        Cell: ({ row, value }) => {
          const {
            values: { status, outbound, handled },
          } = row;

          if (outbound === 'Outbound') {
            return <span style={{ color: 'green' }}>{value}</span>;
          }

          if (status === 'missed' && handled !== 'Yes') {
            return <span style={{ color: 'red' }}>{value}</span>;
          }

          return String(value);
        },
      },
    ],
  },
  {
    Header: 'Timestamp',
    columns: [
      {
        Header: 'Timestamp',
        id: 'time',
        accessor: (ele) => convertTimestampToStr(ele.time).long,
        filter: 'fuzzyText',
        sortType: 'timestampSort',
      },
    ],
  },
  {
    Header: 'Name',
    columns: [
      {
        Header: 'Name',
        id: 'name',
        accessor: (ele) => ele.name,
        filter: 'fuzzyText',
      },
    ],
  },
  {
    Header: 'From/To',
    columns: [
      {
        Header: 'Phone Number',
        id: 'from',
        accessor: (ele) => ele.from,
        filter: 'fuzzyText',
      },
    ],
  },
  {
    Header: 'Info',
    columns: [
      {
        Header: 'Status',
        id: 'status',
        accessor: (ele) => ele.status,
        filter: 'fuzzyText',
        Cell: ({ row }) => {
          const {
            values: { status, handled },
          } = row;

          if (status === 'missed' && handled !== 'Yes') {
            return <span style={{ color: 'red' }}>{status}</span>;
          }

          return String(status);
        },
      },
      {
        Header: 'Handled Status',
        id: 'handled',
        accessor: (ele) => {
          switch (ele.handled) {
            case true:
              return 'Yes';
            case false:
              return 'No';
            default:
              return ' ';
          }
        },
        disableSorting: true,
        filter: 'includes',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Handled By',
        id: 'handledBy',
        accessor: (ele) => ele.handledBy,
        filter: 'fuzzyText',
      },
      {
        Header: 'Handled Time',
        id: 'handledTime',
        accessor: (ele) =>
          ele.handledTime ? convertTimestampToStr(ele.handledTime).long : null,
        filter: 'fuzzyText',
        sortType: 'timestampSort',
      },
    ],
  },
];
