import { combineReducers } from 'redux';

import calls from './phone-dashboard/callReducer';
import leads from './phone-dashboard/leadsReducer';
import customers from './phone-dashboard/customerReducer';
import whatsapp from './phone-dashboard/whatsappReducer';

import hardwares from './inventory-dashboard/hardwareReducer';
import loans from './inventory-dashboard/loanReducer';
import histories from './inventory-dashboard/historyReducer';

import modalState from './modalReducer';
import user from './userReducer';

export default combineReducers({
  calls,
  leads,
  customers,
  modalState,
  whatsapp,
  hardwares,
  loans,
  histories,
  user,
});
