import React from 'react';
import styled from 'styled-components';

import NewHardwareForm from './NewHardwareForm';
import NewLoanForm from './NewLoanForm';
import NewHistoryForm from './NewHistoryForm';
import { Title } from '../TableStyles';
import $ from '../../styles/global';

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${$.layout().margin2}px;

  input,
  textarea {
    background-color: ${$.color.white2};
    width: calc(100% - ${$.layout().padding1 * 2}px - 2px);
  }

  select {
    background-color: ${$.color.white2};
    width: 100%;
  }

  & > * {
    width: 50%;
    &:first-child {
      margin-right: ${$.layout().margin5}px;
    }
  }
`;

const Forms = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const InventoryMainTable = () => {
  return (
    <>
      <section>
        <div>
          <Title>Inventory Dashboard</Title>
          <Columns>
            <div>
              <NewHistoryForm />
            </div>
            <Forms>
              <NewHardwareForm />
              <NewLoanForm />
            </Forms>
          </Columns>
        </div>
      </section>
    </>
  );
};

export default InventoryMainTable;
