import React from 'react';

import CallLogsTable from '../components/CallLogsTable';
import LeadsTable from '../components/LeadsTable';
import CustomersTable from '../components/CustomersTable';
import TwilioTable from '../components/TwilioTable';
import Divider from '../components/Divider';
import WhatsappTable from '../components/WhatsappTable';

const IndexPage = () => (
  <>
    <TwilioTable />
    <Divider />
    <CallLogsTable />
    <Divider />
    <WhatsappTable />
    <Divider />
    <LeadsTable />
    <Divider />
    <CustomersTable />
  </>
);

export default IndexPage;
