import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import useActions from '../../utils/useActions';
import { convertTimestampToStr } from '../../utils/globals';
import { setDeleteModal, deleteHistoryState } from '../../actions';
import { deleteHistoryGQL } from '../../apollo';
import $ from '../../styles/global';
import Button from '../Button';
import CloseIcon from '../../assets/icons/close.svg';

const DeleteButton = styled(Button)`
  background-color: ${$.color.red1};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${$.color.red2};
  }
`;

const Container = styled.tr`
  & > td {
    border: none;

    & svg {
      width: 20px;
      height: 20px;
      fill: ${$.color.white};
      margin-right: ${$.layout().margin1}px;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: ${$.layout().margin1}px;
      }
    }
  }
`;

const SubRow = ({ row, columns }) => {
  const [setDeleteModalState, deleteLog] = useActions([
    setDeleteModal,
    deleteHistoryState,
  ]);
  const [deleteOneHistory] = useMutation(deleteHistoryGQL, {
    onCompleted: ({ deleteHistory: result }) => {
      if (result) {
        deleteLog(result);
        toast.error('History entry deleted', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to delete history. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to delete history. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  return (
    <Container>
      <td colSpan={columns}>
        <div>
          <DeleteButton
            onClick={() => {
              const name = row.original.name || '';
              const state = row.original.returned ? 'Returned' : 'Borrowed';
              const d = convertTimestampToStr(row.original.timestamp).date;

              setDeleteModalState({
                title: 'Delete History',
                message: `Remove ${name} (${state}) at ${d}?`,
                onConfirm: () => {
                  deleteOneHistory({
                    variables: {
                      hardwareID: row.original.hardwareID,
                      timestamp: row.original.timestamp,
                    },
                  });
                },
              });
            }}
          >
            <CloseIcon />
            Delete
          </DeleteButton>
        </div>
      </td>
    </Container>
  );
};

SubRow.defaultProps = {
  row: {},
  columns: 0,
};

SubRow.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.number,
};

export default SubRow;
