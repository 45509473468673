import { Auth } from 'aws-amplify';
import WS from '../utils/WS';

export default () => {
  const payload = Auth.currentAuthenticatedUser()
    .then(
      ({
        username,
        attributes: { email },
        signInUserSession: { accessToken },
      }) => {
        const { localStorage } = typeof window !== 'undefined' ? window : {};
        const rt = JSON.parse(localStorage.getItem('ringTone') || '{}');
        const ws = new WS();

        ws.start();

        return {
          name: username,
          email,
          groups: accessToken.payload['cognito:groups'],
          ringTone: rt[email] || 'default',
          ws,
        };
      }
    )
    .catch(() => ({
      name: '',
      email: '',
      groups: [],
      ws: '',
      ringTone: 'default',
    }));

  return {
    type: 'SET_USER',
    payload,
  };
};
