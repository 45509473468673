import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Button from '../../Button';
import PlayIcon from '../../../assets/icons/play.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${$.color.gray1};
  border-radius: 10px;
  padding: ${$.layout().padding3}px;
  background-color: ${$.color.white};
  height: calc(100% - ${$.layout().padding3 * 2}px);
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding: 10px 15px;
  background-color: ${$.color.green1};
  &:hover {
    background-color: ${$.color.green2};
  }
  svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.white};
    margin-right: ${$.layout().margin1}px;
  }
`;

const StartTwilio = ({ setClicked }) => (
  <Container>
    <StyledButton
      onClick={() => {
        setClicked(true);
      }}
    >
      <PlayIcon />
      Start listening for incoming calls
    </StyledButton>
  </Container>
);

StartTwilio.defaultProps = {
  setClicked: () => {},
};

StartTwilio.propTypes = {
  setClicked: PropTypes.func,
};

export default StartTwilio;
