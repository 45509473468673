import { gql } from 'apollo-boost';

const createHardwareGQL = gql`
  mutation hardware(
    $name: String
    $serialNumber: String
    $os: String
    $appleID: String
    $type: String
    $comments: String
    $location: String
  ) {
    createHardware(
      hardware: {
        name: $name
        serialNumber: $serialNumber
        os: $os
        appleID: $appleID
        type: $type
        comments: $comments
        location: $location
      }
    ) {
      id
      name
      serialNumber
      os
      appleID
      type
      comments
      timestamp
      location
    }
  }
`;

export default createHardwareGQL;
