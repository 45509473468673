import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NewTab from './NewTab';
import DocIcon from '../../../../../../assets/icons/doc.svg';
import $ from '../../../../../../styles/global';

const Container = styled.div`
  width: 100%;
  height: 83px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Document = styled.div`
  width: calc(100% - ${$.layout().padding2 * 2}px);
  height: calc(50px - ${$.layout().padding1 * 2}px);
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    ${$.ellipsis}
    text-align: left;
    font-size: 14px;
    font-style: italic;
    color: ${$.color.whatsappText};
    width: calc(100% - 30px - ${$.layout().margin1}px);
  }

  svg {
    margin-right: ${$.layout().margin1}px;
    fill: ${$.color.gray2};
    width: 24px;
    height: 24px;
  }
`;

const Doc = ({
  url, type,
}) => {
  const docName = url.split('/')[url.split('/').length - 1];

  return (
    <Container>
      <Document>
        <DocIcon />
        <div>{docName}</div>
      </Document>
      <NewTab url={url} type={type} />
    </Container>
  );
};

Doc.defaultProps = {
  url: '',
  type: 'video/mp4',
};

Doc.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default Doc;
