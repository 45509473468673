import { Auth, API } from 'aws-amplify';

export default (body) => {
  const payload = Auth.currentSession()
    .then((session) => ({
      headers: { Authorization: session.idToken.jwtToken },
      response: true,
      body,
    }))
    .then((myInit) => API.post('APIGatewaySetHandled', '/', myInit))
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);
      return {};
    });

  return {
    type: 'SET_HANDLED',
    payload,
  };
};
