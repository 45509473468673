export default (payload) => {
  const params = {
    messageSid: payload.messageSid,
    message: payload.message,
    phone: payload.phone,
    handledBy: payload.handledBy || '',
    timestamp: (Date.now()).toString(),
    from: payload.from,
  };

  if (payload.attachment) {
    params.attachment = payload.attachment;
  }

  return {
    type: 'NEW_INCOMING_MESSAGE',
    payload: params,
  };
};
