const ourNumber = '+6531293688';
const googleMaps = 'AIzaSyCTggSbx7QDQStXBuVrdispo1ZteXE9J00';

/**
 * Parses flat Slack messages to have HTML tags.
 * @param {string} mssg
 */
const parseSlackMessage = (mssg) => {
  let newMssg = mssg;

  const links = mssg.match(/(https?:\/\/[^ ]*)|(http?:\/\/[^ ]*)/g) || [];
  links.forEach((link) => {
    newMssg = newMssg.replace(link, `<a href="${link}" target="_blank'">${link}</a>`);
  });

  const bolden = newMssg.match(/\*.+?\*/g) || [];
  bolden.forEach((txt) => {
    const replaceStar = txt.replace('*', '<strong>').replace('*', '</strong>');

    newMssg = newMssg.replace(txt, replaceStar);
  });

  const italicize = newMssg.match(/_.+?_/g) || [];
  italicize.forEach((txt) => {
    const replaceUnderscore = txt.replace('_', '<em>').replace('_', '</em>');

    newMssg = newMssg.replace(txt, replaceUnderscore);
  });

  const strike = newMssg.match(/~.+?~/g) || [];
  strike.forEach((txt) => {
    const replaceStrike = txt.replace('~', '<strike>').replace('~', '</strike>');

    newMssg = newMssg.replace(txt, replaceStrike);
  });

  const preformatted = newMssg.match(/```.+?```/g) || [];
  preformatted.forEach((txt) => {
    const replacePre = txt.replace('```', '<pre>').replace('```', '</pre>');

    newMssg = newMssg.replace(txt, replacePre);
  });

  return newMssg;
};

const scrollToBottom = () => {
  const messagesArea = document.getElementsByClassName('messages-area');

  if (messagesArea && messagesArea[0]) {
    messagesArea[0].scrollTop = messagesArea[0].scrollHeight;
  }
};

export {
  parseSlackMessage, ourNumber, googleMaps, scrollToBottom,
};
