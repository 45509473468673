export default [
  {
    value: 'Drawing tablet',
  },
  {
    value: 'iPad',
  },
  {
    value: 'Tablet',
  },
  {
    value: 'Desktop',
  },
  {
    value: 'Laptop',
  },
  {
    value: 'Phone',
  },
  {
    value: 'Screen',
  },
  {
    value: 'Peripheral',
  },
];
