/*eslint-disable */

import React from 'react';
import ApolloClient from 'apollo-boost';
import { Auth } from 'aws-amplify';
import { ApolloProvider } from '@apollo/react-hooks';

import './src/styles/reset.css';
import './src/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import Root from './src/components/Root';
import Layout from './src/components/Layout';

const isTesting = false;
const graphqlServer = isTesting
  ? 'https://1yuue1sx8f.execute-api.ap-southeast-1.amazonaws.com/prod/graphql'
  : 'https://bmfah8vmqc.execute-api.ap-southeast-1.amazonaws.com/dev/graphql';

const apolloClient = new ApolloClient({
  uri: graphqlServer,
  request: async (operation) => {
    const token = await Auth.currentSession();
    const accessToken = token.accessToken.jwtToken;

    operation.setContext({
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });
  },
});

apolloClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const wrapRootElement = Root;

const wrapPageElement = ({ element: page }) => (
  <ApolloProvider client={apolloClient}>
    <Layout>{page}</Layout>
  </ApolloProvider>
);

export { wrapRootElement, wrapPageElement };
