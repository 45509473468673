import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Device as TwilioDevice } from 'twilio-client';
import { useSelector } from 'react-redux';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import { updateCallState } from '../../../../actions';
import useActions from '../../../../utils/useActions';
import { updateCallGQL, getTwilioTokenGQL } from '../../../../apollo';
import { Provider } from './Context';
import CallLogHistory from './CallLogHistory';
import ControlPanel from './ControlPanel';

/**
 * The testing HP number is US number (cannot be changed because trial account)
 * HP: +19038475755
 * Please call using the console to test.
 * Change this to true if you want to test the incoming and outgoing calls.
 */
const isTesting = false;

// pulls state out of react lifecycle
const storage = {};

/**
 * Ensures that the initTwilio is not called repeatedly, since TwilioDevice.on('offline') can
 * get triggered multiple times.
 *  */
let triggeredSetup = false;

const Twilio = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [logs, setLogs] = useState([]);
  const [buttons, setButtons] = useState({});
  const [twilioToken, setTwilioToken] = useState({});
  const state = useSelector(({ customers, leads, calls, user }) => ({
    customers,
    leads,
    user,
    calls,
  }));
  const [ringTone, setRingTone] = useState(state.user.ringTone);
  const [updateLog] = useActions([updateCallState]);
  const [updateOneCallLog] = useMutation(updateCallGQL, {
    onCompleted: ({ updateCall: result }) => {
      if (result && Object.keys(result).length > 0) {
        updateLog(result);
        toast.success('Successfully updated Call Log database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Failed to update Call Log database', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Failed to update Call Log database', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
  const [getTwilioToken] = useLazyQuery(getTwilioTokenGQL, {
    onCompleted: ({ getTwilioToken: results }) => {
      setTwilioToken({ token: results });
    },
    onError: (e) => {
      setTwilioToken({ error: e });
    },
  });

  storage.state = state;
  storage.buttons = buttons;

  /**
   * Updates call log panel with a new message.
   * @param {string} entry - Log message
   * @param {string} icon - Log icon
   */
  const addToCallLogs = (entry, icon, type = 'call') => {
    const getTime = () => {
      const time = new Date().toLocaleString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Asia/Hong_Kong',
      });
      const day = new Date().toLocaleDateString('en-GB', {
        month: 'short',
        day: 'numeric',
        timeZone: 'Asia/Hong_Kong',
      });

      return `${day}, ${time}`;
    };

    setLogs((prev) => {
      const newPrev = [
        {
          entry,
          icon,
          type,
          time: getTime(),
        },
        ...prev,
      ];

      return newPrev
        .splice(0, 100)
        .sort((currLog, prevLog) => currLog.time - prevLog.time);
    });
  };

  /**
   * Updates DB if in prod. If in test mode, show console.log.
   */
  const updateDB = (body) => {
    if (isTesting === true) {
      console.log('Testing mode: DB (assumed) to be updated!');
      return;
    }

    updateOneCallLog({ variables: { ...body } });
  };

  /**
   * Handles making a Twilio call when user clicks on Make Call button.
   */
  const makeCall = () => {
    if (typeof window === 'undefined' || !phoneNumber) {
      return;
    }

    /**
     * Attempts to call the user using Twilio.
     */
    TwilioDevice.connect({ To: phoneNumber });
    state.user.ws.sendAction(`is calling ${phoneNumber}.`);

    /**
     * Adds to call log that we are calling the number.
     */
    addToCallLogs(`Calling ${phoneNumber}`, 'call');

    const matchingMissedCallEntriesCalled = storage.state.calls.filter(
      (ele) =>
        phoneNumber === ele.from &&
        ele.status === 'missed' &&
        ele.handled !== true
    );

    /**
     * Updates missed call list if user is making a call back to the number we missed
     * earlier. Set it as handled by current user.
     */
    matchingMissedCallEntriesCalled.forEach((ele) => {
      const updatedEntry = {
        ...ele,
        handled: true,
        handledBy: storage.state.user.name,
        handledTime: Date.now(),
      };

      updateDB(updatedEntry);
    });
  };

  /**
   * Starts Twilio. All event listening related to Twilio is in here.
   */
  const initTwilio = ({ token, error }) => {
    let ignored = false;
    const blacklist = [
      '+919717392321',
      '+6583222468',
      '464',
      '+13474741886',
      '+919075091800',
      '+19294984890',
    ];

    /**
     * If an error occurred at the AWS side, add to call log and do not attach
     * Twilio event listeners.
     */
    if (error) {
      addToCallLogs(
        `Error: ${error}. Please reload this page! If this persists, please inform the tech team.`,
        'warning'
      );

      return false;
    }

    /**
     * Start Twilio Device with or without custom ringtone.
     */
    if (storage.state.user.ringTone === 'default') {
      TwilioDevice.setup(token, {
        allowIncomingWhileBusy: false,
        closeProtection: true,
      });
    } else {
      TwilioDevice.setup(token, {
        allowIncomingWhileBusy: false,
        closeProtection: true,
        sounds: {
          incoming: storage.state.user.ringTone,
        },
      });
    }

    /**
     * On ready, handle outgoing calls.
     */
    TwilioDevice.on('ready', () => {
      /**
       * Update call log to show twilio is ready.
       */
      addToCallLogs(
        `Twilio is ready to accept calls${isTesting === true ? ' (TEST)' : ''}`,
        'thumbs up'
      );

      /**
       * Setup outgoing call callbacks. We will attach event listeners to:
       * 1. Update the call logs and clear the number we called once
       * receiver has disconnected the call.
       * 2. Disconnect the call if we are the ones who hang up the call.
       */
      TwilioDevice.on('connect', (conn) => {
        const hangUpCallCallback = () => {
          conn.disconnect();
          if (conn.message.To) {
            state.user.ws.sendAction(`hung up the call to ${conn.message.To}.`);
          }
        };

        if (!conn.parameters.From) {
          setTimeout(() => {
            const matchesInCallLogDatabase = storage.state.calls.filter(
              (ele) =>
                conn.message.To === ele.from.trim() &&
                ele.status === 'missed' &&
                ele.handled !== true &&
                ele.sid !== conn.parameters.CallSid
            );
            const body = {
              sid: conn.parameters.CallSid,
              staff: storage.state.user.name,
              campus: 'BT',
              call: 'a',
              outbound: true,
              status: 'called',
              from: conn.message.To,
              time: new Date() - 3000,
              handled: true,
              handledBy: storage.state.user.name,
              handledTime: Date.now() - 3000,
            };

            updateDB(body);

            /**
             * Updates the other missed call later.
             */
            matchesInCallLogDatabase.forEach((ele) => {
              const updatedEntry = {
                ...ele,
                handled: true,
                handledBy: storage.state.user.name,
                handledTime: Date.now() - 3000,
              };

              updateDB(updatedEntry);
            });
          }, 3000);

          conn.on('disconnect', () => {
            addToCallLogs(`Ended ${conn.message.To}`, 'end call');

            setPhoneNumber('');
            storage.buttons.hangup.ref.current.removeEventListener(
              'click',
              hangUpCallCallback
            );
          });
        }

        /**
         * Disconnects the phone call if user clicks on Hang Up button.
         */
        storage.buttons.hangup.ref.current.addEventListener(
          'click',
          hangUpCallCallback,
          {
            once: true,
          }
        );
      });
    });

    /**
     * Handles incoming calls.
     */
    TwilioDevice.on('incoming', (conn) => {
      /**
       * Reject blacklisted numbers.
       */
      if (blacklist.indexOf(conn.parameters.From) >= 0) {
        conn.reject();
        return;
      }

      const matchesInCustomerDatabase = storage.state.customers.filter(
        (ele) => {
          // Some customers may not have their phone logged.
          if (ele.phone) {
            if (conn.parameters.From.indexOf(ele.phone.trim()) > -1) {
              return true;
            }

            if (ele.phone.trim() === conn.parameters.From) {
              return true;
            }
          }

          return false;
        }
      )[0];
      const matchesInLeadsDatabase = storage.state.leads.filter((ele) => {
        // Some leads may not have their phone logged.
        if (ele.phone) {
          if (conn.parameters.From.indexOf(ele.phone.trim()) > -1) {
            return true;
          }

          if (ele.phone.trim() === conn.parameters.From) {
            return true;
          }
        }

        return false;
      })[0];
      const matchesInCallLogDatabase = storage.state.calls.filter(
        (ele) =>
          conn.parameters.From === ele.from.trim() &&
          ele.status === 'missed' &&
          ele.handled !== true &&
          ele.sid !== conn.parameters.CallSid
      );

      const ignoreCallCallback = () => {
        conn.ignore();
        if (conn.parameters.From) {
          state.user.ws.sendAction(
            `ignored a call from ${conn.parameters.From}.`
          );
        }

        /**
         * Update call log if user ignored a call.
         */
        if (conn.status() === 'closed') {
          addToCallLogs(`Ignored ${conn.parameters.From}`, 'call');

          ignored = true;
        }
      };
      const acceptCallCallback = () => {
        conn.accept();
        if (conn.parameters.From) {
          state.user.ws.sendAction(
            `accepted a call from ${conn.parameters.From}.`
          );
        }

        setTimeout(() => {
          /**
           * Updates the currently accepted call first.
           */
          updateDB({
            sid: conn.parameters.CallSid,
            staff: storage.state.user.name,
            campus: 'BT',
            call: 'a',
            status: 'answered',
            from: conn.parameters.From,
            time: new Date() - 3000,
            handled: true,
            handledBy: storage.state.user.name,
            handledTime: Date.now() - 3000,
          });

          /**
           * Updates the other missed call later.
           */
          matchesInCallLogDatabase.forEach((ele) => {
            const updatedEntry = {
              ...ele,
              handled: true,
              handledBy: storage.state.user.name,
              handledTime: Date.now() - 3000,
            };

            updateDB(updatedEntry);
          });
        }, 3000);
      };
      const hangUpCallCallback = () => {
        conn.disconnect();
        if (conn.parameters.From) {
          state.user.ws.sendAction(
            `hung up the call from ${conn.parameters.From}.`
          );
        }
      };

      /**
       * Try to match customer db first, followed by leads db and lastly, show as
       * a new call if matches nothing. Update call log respectively.
       */
      if (matchesInCustomerDatabase) {
        addToCallLogs(
          `${matchesInCustomerDatabase.parent_first_name} ${
            matchesInCustomerDatabase.parent_last_name || ''
          } found in customer database`,
          'contact found'
        );
      } else if (matchesInLeadsDatabase) {
        addToCallLogs(
          `${matchesInLeadsDatabase.first_name} ${
            matchesInLeadsDatabase.last_name || ''
          } found in leads database`,
          'contact found'
        );
      } else {
        addToCallLogs(
          'No existing contact in database for this incoming call',
          'contact not found'
        );
      }

      /**
       * Add incoming call details to call log.
       */
      addToCallLogs(`Incoming ${conn.parameters.From}`, 'bell');

      /**
       * Update call log if user accepts or hung up incoming call.
       */
      conn.on('accept', () => {
        addToCallLogs(`Received ${conn.parameters.From}.`, 'call');
      });

      /**
       * Update call log if user ended the call.
       */
      conn.on('disconnect', () => {
        addToCallLogs(`Ended ${conn.parameters.From}`, 'end call');
      });

      /**
       * Saved into state for removeEventListener later.
       */
      setButtons((prev) => {
        const newPrev = { ...prev };

        newPrev.accept.callback = acceptCallCallback;
        newPrev.ignore.callback = ignoreCallCallback;
        newPrev.hangup.callback = hangUpCallCallback;

        return newPrev;
      });

      /**
       * Update DB if user accepted a call.
       * If call ignored, we need to update the state so we can act on it appropriately.
       * Ignored and hangup calls will not enter DB.
       */
      storage.buttons.accept.ref.current.addEventListener(
        'click',
        acceptCallCallback,
        {
          once: true,
        }
      );
      storage.buttons.ignore.ref.current.addEventListener(
        'click',
        ignoreCallCallback,
        {
          once: true,
        }
      );
      storage.buttons.hangup.ref.current.addEventListener(
        'click',
        hangUpCallCallback,
        {
          once: true,
        }
      );
    });

    /**
     * Handles calls that have ended.
     */
    TwilioDevice.on('cancel', (conn) => {
      /**
       * Removes event from nodes
       */
      storage.buttons.accept.ref.current.removeEventListener(
        'click',
        storage.buttons.accept.callback
      );
      storage.buttons.ignore.ref.current.removeEventListener(
        'click',
        storage.buttons.ignore.callback
      );
      storage.buttons.hangup.ref.current.removeEventListener(
        'click',
        storage.buttons.hangup.callback
      );

      /**
       * Do nothing if it is a blacklist call that we rejected.
       */
      if (blacklist.indexOf(conn.parameters.From) >= 0) {
        return;
      }

      setTimeout(() => {
        /**
         * Do not update DB if we ignored the call.
         */
        if (ignored === true) {
          ignored = false;
          return;
        }

        const body = {
          sid: conn.parameters.CallSid,
          staff: 'NA',
          campus: 'NA',
          call: 'a',
          status: 'missed',
          from: conn.parameters.From,
          time: new Date() - 500,
        };

        /**
         * Update call log and DB if we have missed a call.
         */
        addToCallLogs(`Missed ${conn.parameters.From}`, 'warning');

        updateDB(body);
      }, 500);
    });

    /**
     * Refresh Twilio here. .destroy() would automatically fire the .offline event.
     */
    TwilioDevice.on('offline', () => {
      const isOnline =
        typeof window !== 'undefined' ? window.navigator.onLine : false;

      if (isOnline === true && triggeredSetup === false) {
        triggeredSetup = true;
        addToCallLogs(
          'Twilio is offline, restarting Twilio... Refreshing the page also works to restart Twilio.',
          'warning'
        );

        getTwilioToken({
          variables: { type: isTesting === true ? 'test' : 'prod' },
        });
      }
    });

    /**
     * Add to call log if Twilio encountered an error.
     * Twilio will reconnect automatically if the Mac goes offline
     * so we don't need to handle that. Here, we trigger .destroy() to fire the 'offline' event.
     */
    TwilioDevice.on('error', () => {
      const isOnline =
        typeof window !== 'undefined'
          ? window.navigator.onLine === true
          : false;

      if (isOnline === true) {
        TwilioDevice.destroy();
      }
    });
  };

  /**
   * Get Twilio token first.
   */
  useEffect(() => {
    const memberCB = () => {};
    const actionCB = (e) => {
      addToCallLogs(`${e.detail.name} ${e.detail.action}`, 'info', 'action');
    };

    if (typeof window !== 'undefined') {
      getTwilioToken({
        variables: { type: isTesting === true ? 'test' : 'prod' },
      });

      window.addEventListener('isOnline', memberCB);
      window.addEventListener('sendAction', actionCB);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('isOnline', memberCB);
        window.removeEventListener('sendAction', actionCB);
      }
    };
  }, []);

  /**
   * Starts twilio.
   */
  useEffect(() => {
    if (twilioToken.token || twilioToken.error) {
      initTwilio(twilioToken);
    }
  }, [twilioToken]);

  /**
   * Listens to ringtone changes, and force Twilio to reboot with new ringtone set.
   */
  useEffect(() => {
    if (ringTone !== state.user.ringTone) {
      setRingTone(state.user.ringTone);
      TwilioDevice.destroy();
    }
  }, [state.user.ringTone]);

  return (
    <Provider
      value={{
        logs,
        setLogs,
        phoneNumber,
        setPhoneNumber,
        makeCall,
        setButtons,
      }}
    >
      <CallLogHistory />
      <ControlPanel />
    </Provider>
  );
};

export default Twilio;
