import React from 'react';
import styled from 'styled-components';

import $ from '../../styles/global';
import TwilioDialInterface from './TwilioDialInterface';
import { Title } from '../TableStyles';
import TwilioFormsContainer from './TwilioFormsContainer';

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${$.layout().margin2}px;
  height: 940px;
  margin-bottom: 30px;

  input,
  textarea {
    background-color: ${$.color.white2};
    width: calc(100% - ${$.layout().padding1 * 2}px - 2px) !important;
  }

  & > * {
    width: 50%;
    height: 100%;
    &:first-child {
      margin-right: ${$.layout().margin5}px;
    }
  }
`;

const TwilioTable = () => (
  <section>
    <div>
      <Title>Phone Dashboard</Title>
      <Columns>
        <TwilioDialInterface />
        <TwilioFormsContainer />
      </Columns>
    </div>
  </section>
);

export default TwilioTable;
