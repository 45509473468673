import { gql } from 'apollo-boost';

const getHardwareGQL = gql`
  query hardware($id: String!) {
    getHardware(id: $id) {
      id
      name
      serialNumber
      os
      appleID
      type
      comments
      timestamp
      location
    }
  }
`;

export default getHardwareGQL;
