import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';
import {
  Label,
  TextAreaInput,
  TextInput,
  Dropdown,
  QuestionCheckbox,
} from './formStyles';

const FormLabel = styled.label`
  font-size: 18px;
  margin-bottom: ${$.layout().margin3}px;
  margin-top: ${({ first }) => (first === true ? '0' : '60px')};
  font-weight: bold;
  color: ${$.color.blue4};
  padding-bottom: ${$.layout().padding1}px;
  border-bottom: 1px solid ${$.color.gray1};
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout().margin4}px;
  width: 100%;

  & > * {
    flex-grow: 1;
    margin-bottom: 0 !important;
    &:not(:last-child) {
      margin-right: ${$.layout().margin2}px;
    }
  }
`;

const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${$.layout().margin4}px;
`;

const setInputComponent = ({
  /* eslint-disable react/prop-types */
  type = 'text',
  name,
  label,
  inputState,
  setInputState,
  options = [],
}) => {
  const handleInputChange = (e) => {
    const {
      target: { value, checked },
    } = e;

    switch (type) {
      case 'checkbox':
        setInputState((prev) => ({ ...prev, [name]: checked }));
        break;
      default:
        setInputState((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };
  let inputType;

  switch (type) {
    case 'textarea':
      inputType = (
        <TextAreaInput
          value={inputState[name] || ''}
          onChange={handleInputChange}
        />
      );
      break;
    case 'checkbox':
      inputType = (
        <QuestionCheckbox>
          <label>{label}</label>
          <input
            type="checkbox"
            checked={inputState[name]}
            onChange={handleInputChange}
          />
        </QuestionCheckbox>
      );
      break;
    case 'text':
      inputType = (
        <TextInput
          value={inputState[name] || ''}
          onChange={handleInputChange}
        />
      );
      break;
    case 'select':
      if (typeof inputState[name] === 'undefined') {
        setInputState((prev) => ({ ...prev, [name]: options[0].value }));
      }

      inputType = (
        <Dropdown value={inputState[name] || ''} onChange={handleInputChange}>
          {options.map(({ key, value, text }) => (
            <option value={value} key={key}>
              {text}
            </option>
          ))}
        </Dropdown>
      );
      break;
    default:
      break;
  }

  return (
    <FormInput key={`form-input-${name}`}>
      {type !== 'checkbox' && <Label>{label}</Label>}
      {inputType}
    </FormInput>
  );
};

const InputsContainer = ({ components, inputState, setInputState }) => (
  <>
    {components.map(({ inputs = [], label = '' }, index) => {
      const group = inputs.map((input, subIndex) => {
        if (input.length > 1) {
          return (
            <FormGroup key={`form-group-${subIndex}`}>
              {input.map((inputItems) =>
                setInputComponent({
                  ...inputItems,
                  inputState,
                  setInputState,
                })
              )}
            </FormGroup>
          );
        }

        return setInputComponent({
          ...input[0],
          inputState,
          setInputState,
        });
      });

      return (
        <div key={`form-label-${index}`}>
          {label && <FormLabel first={index === 0}>{label}</FormLabel>}
          {group}
        </div>
      );
    })}
  </>
);

InputsContainer.propTypes = {
  inputState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
  setInputState: PropTypes.func.isRequired,
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
};

export default InputsContainer;
