import { gql } from 'apollo-boost';

const getLoansGQL = gql`
  query loan {
    getLoans {
      id
      name
      comments
      timestamp
      totalHardwareHeld
    }
  }
`;

export default getLoansGQL;
