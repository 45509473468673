import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import ReactTable from '../ReactTable';
import { getTotalHardwareHeldGQL } from '../../apollo';
import columns from './columns';
import { Title, TitleContainer } from '../TableStyles';

const TitleItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalHardwareHeldTable = () => {
  const [totalHardware, setTotalHardware] = useState([]);
  const totalHardwareHeldColumns = React.useMemo(() => columns, []);
  const [getTotalHardwareHeld] = useLazyQuery(getTotalHardwareHeldGQL, {
    onCompleted: ({ getTotalHardwareHeld: results }) => {
      setTotalHardware(results);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    getTotalHardwareHeld();
  }, []);

  return (
    <section>
      <div>
        <TitleContainer>
          <TitleItems>
            <Title>Total Hardware Held</Title>
          </TitleItems>
        </TitleContainer>
        <ReactTable data={totalHardware} columns={totalHardwareHeldColumns} />
      </div>
    </section>
  );
};

export default TotalHardwareHeldTable;
