import React from 'react';
import { matchSorter } from 'match-sorter';
import styled from 'styled-components';

import $ from '../../styles/global';

const FilterInput = styled.input`
  padding: 10px;
  border: 1px solid ${$.color.gray1};
  border-radius: 5px;
  min-width: 150px;
`;

const FilterDropdown = styled.select`
  border: 1px solid ${$.color.gray1};
  padding: 9px;
  color: ${$.color.blue4};
  border-radius: 5px;
  min-width: 80px;
`;

const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id]) {
        options.add(row.values[id]);
      }
    });

    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <FilterDropdown
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </FilterDropdown>
  );
};

const SpecialNeedsFilterColumn = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();

    options.add('Yes');
    options.add('No');

    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <FilterDropdown
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </FilterDropdown>
  );
};

const filterTypes = () => {
  const fuzzyTextFilter = (rows, id, filterValue) =>
    matchSorter(rows, filterValue, {
      keys: [
        (row) => {
          const value = row.values[id];

          if (typeof value === 'object' && value && value.props) {
            return value.props.children;
          }

          return value;
        },
      ],
    });

  return React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilter,
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .indexOf(String(filterValue).toLowerCase()) > -1
            : true;
        }),
    }),
    []
  );
};

const sortTypes = () => {
  const timestampSorter = (rowA, rowB, id) => {
    const rowATime = rowA.original[id];
    const rowBTime = rowB.original[id];

    if (rowATime > rowBTime) {
      return 1;
    }

    if (rowBTime > rowATime) {
      return -1;
    }

    return 0;
  };
  const strTimestampSorter = (rowA, rowB, id) => {
    const rowATime = new Date(rowA.original[id]).getTime();
    const rowBTime = new Date(rowB.original[id]).getTime();

    if (rowATime > rowBTime) {
      return 1;
    }

    if (rowBTime > rowATime) {
      return -1;
    }

    return 0;
  };

  return React.useMemo(() => ({
    timestampSort: timestampSorter,
    strTimestampSort: strTimestampSorter,
  }));
};

const defaultColumn = () => {
  const DefaultColumnFilter = ({
    column: { filterValue, setFilter, Header },
  }) => (
    <FilterInput
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${Header.toLowerCase()}...`}
    />
  );

  return React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
};

export {
  SelectColumnFilter,
  defaultColumn,
  filterTypes,
  sortTypes,
  SpecialNeedsFilterColumn,
};
