import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  padding: ${$.layout().padding1}px;
  width: calc(100% - ${$.layout().padding1 * 2}px);
  border-bottom: 1px solid ${$.color.whatsappBorder};
`;

const TextInput = styled.input.attrs({
  type: 'text',
  placeholder: 'Search phone number',
})`
  border: 1px solid ${$.color.whatsappBorder};
  border-radius: 10px;
  font-size: 15px;
  padding: ${$.layout().padding1}px;
  color: ${$.color.whatsappText};
  width: calc(100% - ${$.layout().padding1 * 2}px - 2px);
`;

const SearchBar = ({ setSearchTerm }) => (
  <Container>
    <TextInput onChange={(e) => {
      setSearchTerm(e.target.value);
    }}
    />
  </Container>
);

SearchBar.defaultProps = {
  setSearchTerm: () => {},
};

SearchBar.propTypes = {
  setSearchTerm: PropTypes.func,
};

export default SearchBar;
