import { gql } from 'apollo-boost';

const getCallsGQL = gql`
  query calls {
    getCalls {
      sid
      call
      campus
      from
      status
      handled
      handledBy
      handledTime
      staff
      time
      outbound
    }
  }
`;

export default getCallsGQL;
