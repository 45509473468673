import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';
import { clearModal } from '../../../actions';
import useActions from '../../../utils/useActions';
import CloseIcon from '../../../assets/icons/close.svg';
import Button from '../../Button';
import InputsContainer from './InputsContainer';
import ConfirmationContainer from './ConfirmationContainer';
import useOutsideClick from '../../../utils/useOutsideClick';

const Container = styled.div`
  min-width: calc((244px + 4px) * 2);
  height: 70vh;
  background-color: ${$.color.background};
  border-radius: 8px;
  box-shadow: ${$.dropShadow.normal};
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  padding: ${$.layout().padding2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${$.color.gray1};

  svg {
    width: 28px;
    height: 28px;
    fill: ${$.color.blue4};
    transition: all 0.3s ease;
    &:hover {
      fill: ${$.color.blue5};
    }
  }
`;

const Title = styled.div`
  color: ${$.color.blue4};
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  overflow-y: auto;
  padding: ${$.layout().padding3}px ${$.layout().padding2}px;
  height: 100%;
`;

const BottomButtonContainer = styled.div`
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  border-top: 1px solid ${$.color.gray1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > button {
    font-size: 17px;
    padding: 10px 12px;
  }
`;

const SubmitButton = styled(Button)`
  background-color: ${$.color.blue5};
  &:hover {
    background-color: ${$.color.blue4};
  }
`;

const ConfirmButton = styled(Button)`
  background-color: ${$.color.green1};
  margin-right: ${$.layout().margin2}px;
  &:hover {
    background-color: ${$.color.green2};
  }
`;

const CancelButton = styled(Button)`
  background-color: ${$.color.red1};
  &:hover {
    background-color: ${$.color.red2};
  }
`;

const StyledCloseIcon = styled(CloseIcon)``;

const Content = ({ components, title, state, onConfirm, onClose }) => {
  const containerRef = useRef(null);
  const [clearState] = useActions([clearModal]);
  const [confirm, setConfirm] = useState(false);
  const [inputState, setInputState] = useState(state);

  useOutsideClick(containerRef, () => {
    clearState();
  });

  return (
    <Container ref={containerRef}>
      <TitleContainer>
        <Title>{title}</Title>
        <StyledCloseIcon
          onClick={() => {
            clearState();
          }}
        />
      </TitleContainer>
      <ContentContainer>
        {confirm === false ? (
          <InputsContainer
            inputState={inputState}
            setInputState={setInputState}
            components={components}
          />
        ) : (
          <ConfirmationContainer
            inputState={inputState}
            components={components}
          />
        )}
      </ContentContainer>
      <BottomButtonContainer>
        {confirm === false ? (
          <SubmitButton
            onClick={() => {
              setConfirm(true);
            }}
          >
            Submit
          </SubmitButton>
        ) : (
          <>
            <ConfirmButton
              onClick={() => {
                onConfirm(inputState);
                setConfirm(false);
              }}
            >
              Confirm
            </ConfirmButton>
            <CancelButton
              onClick={() => {
                onClose(inputState);
                setConfirm(false);
              }}
            >
              Cancel
            </CancelButton>
          </>
        )}
      </BottomButtonContainer>
    </Container>
  );
};

Content.defaultProps = {
  state: {},
  onConfirm: () => {},
  onClose: () => {},
};

Content.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  state: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  title: PropTypes.string.isRequired,
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
};

export default Content;
