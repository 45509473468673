import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Amplify from 'aws-amplify';
import {
  withAuthenticator,
  SignIn,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
} from 'aws-amplify-react';

import DeleteConfirmationModal from './DeleteConfirmationModal';
import FormModal from './FormModal';
import $ from '../styles/global';
import useActions from '../utils/useActions';
import { setUser } from '../actions';
import awsconfig from '../../aws-config';
import Nav from './Nav';
import StyledToast from './StyledToast';

const Main = styled.main`
  margin-top: 90px;

  & > section {
    width: calc(100vw - 100px);
    padding: ${$.layout().padding2}px 50px;
  }
`;

Amplify.configure(awsconfig);

const Layout = ({ children }) => {
  const [setOneUser] = useActions([setUser]);

  useEffect(() => {
    setOneUser();
  }, []);

  return (
    <>
      <StyledToast />
      <Nav />
      <Main>
        <DeleteConfirmationModal />
        <FormModal />
        {children}
      </Main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withAuthenticator(
  Layout,
  false,
  [<SignIn />, <ConfirmSignIn />, <ForgotPassword />, <RequireNewPassword />],
  null,
  {
    formContainer: {
      margin: '5% auto 50px',
      textAlign: 'center',
    },
    formSection: {
      backgroundColor: '#ffffff',
      position: 'relative',
      borderRadius: '10px',
      fontFamily: 'Helvetica Neue',
      padding: '32px',
      marginBottom: '20px',
      minWidth: 'calc(460px - 64px)',
      display: 'inline-block',
      textAlign: 'left',
      boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 15%)',
    },
    formField: {
      marginBottom: '22px',
    },
    sectionHeader: {
      color: $.color.blue5,
      marginBottom: '24px',
      fontSize: '18px',
      fontWeight: '500',
    },
    inputLabel: {
      fontSize: '14px',
      marginBottom: '8px',
      color: $.color.blue4,
    },
    hint: {
      fontSize: '12px',
      color: $.color.gray2,
    },
    input: {
      color: $.color.blue4,
      borderRadius: '5px',
      padding: '16px',
      fontSize: '15px',
      outline: 'none',
      display: 'block',
      width: 'calc(100% - 32px - 2px)',
      lineHeight: '1.15',
      marginBottom: '10px',
      backgroundColor: '#fff',
      border: `1px solid ${$.color.gray1}`,
    },
    a: {
      color: $.color.orange4,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    button: {
      fontSize: '15px',
      fontFamily: 'Helvetica Neue',
      fontWeight: 'bold',
      borderRadius: '10px',
      outline: 'none',
      padding: '10px 12px',
      width: '100%',
      cursor: 'pointer',
      minWidth: '153px',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      display: 'inline-block',
      marginBottom: '0',
      textAlign: 'center',
      color: '#fff',
      backgroundColor: $.color.orange2,
      border: 'none',
      letterSpacing: '1.1px',
      textTransform: 'uppercase',
    },
    sectionFooter: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row-reverse',
    },
    sectionFooterSecondaryContent: {
      marginRight: 'auto',
      alignSelf: 'center',
      fontSize: '14px',
    },
    toast: {
      backgroundColor: $.color.blue4,
      color: $.color.blue1,
      fontFamily: 'Helvetica Neue',
      padding: '16px',
    },
  }
);
