/* eslint-disable react/jsx-filename-extension, react/prop-types */

import React from 'react';
import { Link } from 'gatsby';

export default [
  {
    Header: 'Loaner',
    id: 'loaner',
    accessor: (ele) => (
      <Link to={`/inventory/history?loan=${ele.id}`}>{ele.name}</Link>
    ),
    filter: 'fuzzyText',
  },
  {
    Header: 'Total Hardware Held',
    id: 'totalHardwareHeld',
    accessor: (ele) => ele.count,
    disableFilters: true,
  },
];
