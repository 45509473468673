const config = {
  Auth: {
    identityPoolId: 'ap-southeast-1:a692e6cf-8558-4c0b-9953-0a633c87087f',
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_it3GYlZrR',
    userPoolWebClientId: '475vtlheqnbbscpa15qaimn6nm',
  },
  API: {
    endpoints: [
      {
        name: 'APIGatewayGetPhoneList',
        endpoint:
          'https://ez88wpcy66.execute-api.ap-southeast-1.amazonaws.com/dev/getphonelist',
      },
      {
        name: 'APIGatewayGetAllMessages',
        endpoint:
          'https://ez88wpcy66.execute-api.ap-southeast-1.amazonaws.com/dev/getallmessages',
      },
      {
        name: 'APIGatewayGetPresignedURL',
        endpoint:
          'https://ez88wpcy66.execute-api.ap-southeast-1.amazonaws.com/dev/getpresignedurl',
      },
      {
        name: 'APIGatewaySetHandled',
        endpoint:
          'https://ez88wpcy66.execute-api.ap-southeast-1.amazonaws.com/dev/sethandled',
      },
    ],
  },
};

export default config;
