import { gql } from 'apollo-boost';

const getHardwareHistoriesGQL = gql`
  query history($id: String!) {
    getHardwareHistories(id: $id) {
      hardwareID
      loanID
      comments
      timestamp
      returned
      deleted
      name
    }
  }
`;

export default getHardwareHistoriesGQL;
