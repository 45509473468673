import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../styles/global';

const Container = styled.button`
  padding: 6px 10px;
  color: ${$.color.white};
  background-color: ${$.color.blue3};
  font-size: 14px;
  border-radius: 5px;
  border: none;
  box-shadow: ${$.dropShadow.normal};
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: ${$.color.blue4};
    box-shadow: ${$.dropShadow.repressed};
  }
`;

const Button = React.forwardRef(({ children, className, ...props }, ref) => (
  <Container className={className} {...props} ref={ref}>
    {children}
  </Container>
));

Button.defaultProps = {
  children: <></>,
  className: '',
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
};

export default Button;
