import { nanoid } from 'nanoid';

/**
 * Stores address for MP and BT. Prevents mistake if address changed.
 */
const ADDRESS = {
  MP: { LONG: 'Marine Parade (Parkway Centre #03-04)', SHORT: 'Marine Parade' },
  BT: { LONG: "Bukit Timah (King's Arcade #03-01B)", SHORT: 'Bukit Timah' },
  BS: { LONG: 'Bishan (508 Bishan St 11, #01-386)', SHORT: 'Bishan' },
  TP: { LONG: 'Tampines (Tampines Plaza 1, #06-03)', SHORT: 'Tampines' },
};

/**
 * Converts given date to string, GMT +8
 * @param {Date} timestamp - Unix time.
 */
const convertTimestampToStr = (timestamp) => {
  const d = new Date(timestamp)
    .toLocaleDateString('en-GB', {
      timeZone: 'Asia/Hong_Kong',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    .split(' ');
  const t = new Date(timestamp).toLocaleTimeString('en-GB', {
    timeZone: 'Asia/Hong_Kong',
  });
  const day = d[0] <= 9 ? `0${d[0]}` : d[0];

  return {
    long: `${day}-${d[1]}-${d[2]} ${t}`,
    time: t,
    date: `${day}-${d[1]}-${d[2]}`,
  };
};

/**
 * Retrieves 1 absolute path to the Webpack processed image from the assets/image folder.
 * @param {string} pathToImage
 * @return {string}
 */
const importOneImage = (pathToImage) =>
  require.context('../assets/images', true)(`./${pathToImage}`);

/**
 * Adds a key to the array of objects.
 * @param {Array<object>} arr - An array of objects
 */
const addKeys = (arr) =>
  arr.map((obj) => ({
    key: nanoid(),
    ...obj,
  }));

export { ADDRESS, convertTimestampToStr, addKeys, importOneImage };
