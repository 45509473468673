import React from 'react';
import styled from 'styled-components';

import DownChevronIcon from '../../assets/icons/down-chevron.svg';
import $ from '../../styles/global';

const Container = styled.span`
  ${({ expand }) => `
    & > svg {
      width: 20px;
      height: 20px;
      fill: ${$.color.black};
      transform: ${expand ? 'rotate(0deg)' : 'rotate(270deg)'};
    }
  `}
`;

const ExpandRow = ({ row }) => {
  return (
    <Container expand={row.isExpanded} {...row.getToggleRowExpandedProps()}>
      <DownChevronIcon />
    </Container>
  );
};

export default ExpandRow;
