const baseNum = 8;

export default {
  color: {
    white: '#FFFFFF',
    white2: '#fcfcfc',
    background: '#FAFAFA',
    gray1: '#C4C4C4',
    gray2: '#9E9E9E',
    black: '#000000',
    orange1: '#FFAF6E',
    orange2: '#F98D43',
    orange3: '#F37021',
    orange4: '#D85700',
    orange5: '#E85E00',
    blue1: '#EBFBFF',
    blue2: '#88FFFC',
    blue3: '#055399',
    blue4: '#114060',
    blue5: '#1B75BC',
    blue6: '#33B6FF',
    red1: '#FF0000',
    red2: '#cc0000',
    green1: '#3CB371',
    green2: '#2B8050',
    whatsappBg: '#ededed',
    whatsappText: '#4a4a4a',
    whatsappBorder: 'rgba(0, 0, 0, 0.15)',
    whatsappHover: '#f7f7f7',
  },
  easingFn: {
    standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
    decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  },
  layout() {
    const margin = {
      margin1: baseNum * 1,
      margin2: baseNum * 2,
      margin3: baseNum * 3,
      margin4: baseNum * 4,
      margin5: baseNum * 5,
    };

    const padding = {
      padding1: baseNum * 1,
      padding2: baseNum * 2,
      padding3: baseNum * 3,
      padding4: baseNum * 4,
      padding5: baseNum * 5,
    };

    return {
      ...margin,
      ...padding,
    };
  },
  ellipsis: `
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  `,
  dropShadow: {
    normal: '0px 3px 8px rgba(0, 0, 0, 0.15)',
    repressed: '0px 8px 12px rgba(0, 0, 0, 0.15)',
  },
  device: {
    mobile: 'only screen and (max-width: 767px)',
    tabletA: 'only screen and (min-width: 768px) and (max-width: 969px)',
    tabletB: 'only screen and (min-width: 970px) and (max-width: 1199px)',
    tablet: 'only screen and (min-width: 768px) and (max-width: 1199px)',
    desktop: 'only screen and (min-width: 1200px)',
  },
  br: `br {
    content: "";
    display: block;
    margin: 0.75em 0;
  }`,
};
