import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { deleteCustomerGQL, updateCustomerGQL } from '../../apollo';
import useActions from '../../utils/useActions';
import {
  setFormModal,
  updateCustomerState,
  deleteCustomerState,
  setDeleteModal,
} from '../../actions';
import $ from '../../styles/global';
import Button from '../Button';
import CloseIcon from '../../assets/icons/close.svg';
import EditIcon from '../../assets/icons/edit.svg';
import formStructure from './customerForm';

const UpdateButton = styled(Button)`
  background-color: ${$.color.orange3};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${$.color.orange4};
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${$.color.red1};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${$.color.red2};
  }
`;

const Container = styled.tr`
  & > td {
    border: none;

    & svg {
      width: 20px;
      height: 20px;
      fill: ${$.color.white};
      margin-right: ${$.layout().margin1}px;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: ${$.layout().margin1}px;
      }
    }
  }
`;

const SubRow = ({ row, columns }) => {
  const [updateLog, setFormModalState, deleteLog, setDeleteModalState] =
    useActions([
      updateCustomerState,
      setFormModal,
      deleteCustomerState,
      setDeleteModal,
    ]);
  const [deleteOneCustomer] = useMutation(deleteCustomerGQL, {
    onCompleted: ({ deleteStudent: result }) => {
      if (result) {
        deleteLog(result);
        toast.error('Student entry deleted', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to delete customer. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to delete customer. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
  const [updateOneCustomer] = useMutation(updateCustomerGQL, {
    onCompleted: ({ updateStudent: result }) => {
      if (result && Object.keys(result).length > 0) {
        updateLog(result);
        toast.success('Customer data successfully updated.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to update customer data. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to update customer data. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  return (
    <Container>
      <td colSpan={columns}>
        <div>
          <UpdateButton
            onClick={() => {
              setFormModalState({
                title: 'Update Customer Entry',
                state: row.original,
                components: formStructure,
                onConfirm: (formData) => {
                  updateOneCustomer({ variables: { ...formData } });
                },
              });
            }}
          >
            <EditIcon />
            Update Entry
          </UpdateButton>
          <DeleteButton
            onClick={() => {
              setDeleteModalState({
                title: 'Delete Customer',
                message: `Remove ${row.original.parent_first_name} ${row.original.parent_last_name}?`,
                onConfirm: () => {
                  deleteOneCustomer({ variables: { id: row.original.id } });
                },
              });
            }}
          >
            <CloseIcon />
            Delete
          </DeleteButton>
        </div>
      </td>
    </Container>
  );
};

SubRow.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object]).isRequired,
  columns: PropTypes.number.isRequired,
};

export default SubRow;
