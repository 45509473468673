import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { newIncomingMessage } from '../../../../actions';
import useActions from '../../../../utils/useActions';
import $ from '../../../../styles/global';
import Attachment from './Attachment';
import ChatArea from './ChatArea';
import { ourNumber, scrollToBottom } from '../../helper';
import { Provider } from './Context';
import Button from '../../../Button';

const Container = styled.div`
  width: calc(100% - ${$.layout().padding1 * 2}px);
  height: calc(100px - 1px - ${$.layout().padding1 * 2}px);
  border-top: 1px solid ${$.color.gray1};
  padding: ${$.layout().padding1}px;
  background-color: ${$.color.whatsappHover};
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  overflow: visible;

  & > * {
    margin-right: ${$.layout().margin1}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const UploadingBar = styled.div`
  opacity: 0;
  position: absolute;
  text-align: center;
  overflow: hidden;
  color: ${$.color.blue4};
  font-size: 14px;
  margin: 0;
  padding: ${$.layout().padding1}px 0;
  top: -${$.layout().padding1 * 2 + 16}px;
  left: 0;
  width: 100%;
  transform: translateY(0.5em);
  transition: all 0.35s ${$.easingFn.standard};
  ${$.ellipsis}

  @keyframes stripes {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(46px);
    }
  }

  & > div {
    z-index: -1;
    position: absolute;
    left: -46px;
    right: 0;
    top: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      -55deg,
      ${$.color.orange1} 1px,
      ${$.color.orange1} 2px,
      ${$.color.orange1} 11px,
      ${$.color.orange2} 12px,
      ${$.color.orange2} 20px
    );

    animation-name: stripes;
    animation-duration: 0.9s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &.uploading {
    opacity: 1;
    transform: translateY(0);
  }

  &.error {
    color: ${$.color.white};
    & > div {
      background: repeating-linear-gradient(
        -55deg,
        ${$.color.red1} 1px,
        ${$.color.red1} 2px,
        ${$.color.red1} 11px,
        ${$.color.red2} 12px,
        ${$.color.red2} 20px
      );

      animation-play-state: paused;
    }
  }
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    margin-top: ${$.layout().margin2}px;
    color: ${$.color.red1};
  }
`;

const check24HourWindow = (state, setTemplate) => {
  const { currentPhone, messages } = state.whatsapp;
  let currMessage = '';
  let lastMessage = '';
  let moreThan24Hours = '';

  if (currentPhone && messages[currentPhone] && messages[currentPhone].Items) {
    currMessage = messages[currentPhone].Items;
    lastMessage = currMessage[messages[currentPhone].Items.length - 1];
    moreThan24Hours =
      Date.now() - Number(lastMessage.timestamp) >= 1000 * 60 * 60 * 24;

    setTemplate(!!moreThan24Hours);
  }
};

const TextBox = () => {
  const textInputRef = useRef(null);
  const [txt, setTxt] = useState('');
  const [uploading, setUploading] = useState({
    show: false,
    name: '',
    error: false,
  });
  const [template, setTemplate] = useState(false);
  const [updateReduxWithMessage] = useActions([newIncomingMessage]);
  const state = useSelector(({ whatsapp, user }) => ({ whatsapp, user }));
  const phoneRef = useRef(state.whatsapp.currentPhone || '');

  // Use useRef instead.
  const sendReply = ({ str = '', attachment }) => {
    const param = {
      messageSid: Date.now().toString(),
      message: str,
      from: ourNumber,
      handledBy: state.user.name,
      phone: phoneRef.current,
      status: 'sent',
      timestamp: Date.now().toString(),
    };
    const messagesArea = document.getElementsByClassName('messages-area');

    if (attachment) {
      param.attachment = attachment;
    }

    // sends to all about the new message, and update dynamodb
    state.user.ws.sendToAll(param);
    updateReduxWithMessage(param);

    setTxt('');
    if (textInputRef && textInputRef.current) {
      // eslint-disable-next-line
      textInputRef.current.value = '';
      textInputRef.current.focus();
    }

    if (messagesArea && messagesArea[0]) {
      messagesArea[0].scrollTop = messagesArea[0].scrollHeight;
    }
  };

  useEffect(() => {
    /**
     * Scroll the message box to the bottom whenever user swap chat view.
     */
    if (phoneRef.current !== state.whatsapp.currentPhone) {
      phoneRef.current = state.whatsapp.currentPhone;
      scrollToBottom();
    }

    /**
     * Check if it has been more than 24 hours since last message.
     * Pop up a warning message if true.
     * Whatsapp will automatically fail all of our replies if
     * we are out of the time window.
     */
    check24HourWindow(state, setTemplate);
  }, [state.whatsapp]);

  return (
    <Provider
      value={{
        setTxt,
        txt,
        setUploading,
        textInputRef,
        sendReply,
      }}
    >
      <Container>
        {template ? (
          <TemplateContainer>
            <Button
              onClick={() => {
                sendReply({
                  str: "Hi! We're sorry we weren't able to respond to your queries previously but can assist you now. To continue the discussion, please reply with YES.",
                });
              }}
            >
              Send template
            </Button>
            <div>
              More than 24 hours has passed since the last message
              exchange.Please use template and wait for the customer&apos;s
              reply to restart 24 hour window.
            </div>
          </TemplateContainer>
        ) : (
          <>
            <UploadingBar
              className={`${uploading.error ? 'error' : ''} ${
                uploading.show ? 'uploading' : ''
              }`}
            >
              {uploading.error
                ? uploading.errorMessage ||
                  'Upload failed. Failed to send message.'
                : `Uploading ${uploading.name}...`}
              <div />
            </UploadingBar>
            <Attachment />
            <ChatArea />
          </>
        )}
      </Container>
    </Provider>
  );
};

export default TextBox;
