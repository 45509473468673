import React from 'react';
import ExpandRow from '../ReactTable/ExpandRow';

export default [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => <ExpandRow row={row} />,
    SubCell: () => null,
  },
  {
    Header: 'Timestamp',
    columns: [
      {
        Header: 'Timestamp',
        id: 'timestamp',
        accessor: (ele) => ele.timestamp,
        filter: 'fuzzyText',
        sortType: 'strTimestampSort',
      },
    ],
  },
  {
    Header: 'Name',
    columns: [
      {
        Header: 'First Name',
        id: 'first_name',
        accessor: (ele) => ele.first_name,
        filter: 'fuzzyText',
      },
      {
        Header: 'Last Name',
        id: 'last_name',
        accessor: (ele) => ele.last_name,
        filter: 'fuzzyText',
      },
    ],
  },
  {
    Header: 'Info',
    columns: [
      {
        Header: 'Course',
        id: 'course',
        accessor: (ele) => ele.course,
        filter: 'fuzzyText',
      },
      {
        Header: 'Location',
        id: 'location',
        accessor: (ele) => ele.location,
        filter: 'fuzzyText',
      },
      {
        Header: 'Phone',
        id: 'phone',
        accessor: (ele) => ele.phone,
        filter: 'fuzzyText',
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: (ele) => ele.email,
        filter: 'fuzzyText',
      },
      {
        Header: 'Comments',
        id: 'comments',
        accessor: (ele) => ele.comments,
        filter: 'fuzzyText',
      },
      {
        Header: 'ID',
        id: 'id',
        accessor: (ele) => ele.id,
        filter: 'fuzzyText',
      },
    ],
  },
];
