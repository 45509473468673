import getCallsGQL from './phone-dashboard/calls/getCallsGQL';
import updateCallGQL from './phone-dashboard/calls/updateCallGQL';

import getLeadsGQL from './phone-dashboard/leads/getLeadsGQL';
import updateLeadGQL from './phone-dashboard/leads/updateLeadGQL';
import deleteLeadGQL from './phone-dashboard/leads/deleteLeadGQL';
import createLeadGQL from './phone-dashboard/leads/createLeadGQL';

import getCustomersGQL from './phone-dashboard/customers/getCustomersGQL';
import createCustomerGQL from './phone-dashboard/customers/createCustomerGQL';
import deleteCustomerGQL from './phone-dashboard/customers/deleteCustomerGQL';
import updateCustomerGQL from './phone-dashboard/customers/updateCustomerGQL';

import getHardwaresGQL from './inventory-dashboard/hardware/getHardwaresGQL';
import getHardwareGQL from './inventory-dashboard/hardware/getHardwareGQL';
import createHardwareGQL from './inventory-dashboard/hardware/createHardwareGQL';
import updateHardwareGQL from './inventory-dashboard/hardware/updateHardwareGQL';
import deleteHardwareGQL from './inventory-dashboard/hardware/deleteHardwareGQL';

import getLoansGQL from './inventory-dashboard/loan/getLoansGQL';
import getLoanGQL from './inventory-dashboard/loan/getLoanGQL';
import createLoanGQL from './inventory-dashboard/loan/createLoanGQL';
import updateLoanGQL from './inventory-dashboard/loan/updateLoanGQL';
import deleteLoanGQL from './inventory-dashboard/loan/deleteLoanGQL';
import getTotalHardwareHeldGQL from './inventory-dashboard/loan/getTotalHardwareHeld';

import createLoanedGQL from './inventory-dashboard/history/createLoanedGQL';
import createReturnedGQL from './inventory-dashboard/history/createReturnedGQL';
import deleteHistoryGQL from './inventory-dashboard/history/deleteHistoryGQL';
import getHardwareHistoriesGQL from './inventory-dashboard/history/getHardwareHistoriesGQL';
import getLoanHistoriesGQL from './inventory-dashboard/history/getLoanHistoriesGQL';

import getTwilioTokenGQL from './getTwilioTokenGQL';

export {
  getCallsGQL,
  updateCallGQL,
  getLeadsGQL,
  updateLeadGQL,
  deleteLeadGQL,
  createLeadGQL,
  getCustomersGQL,
  createCustomerGQL,
  deleteCustomerGQL,
  updateCustomerGQL,
  getHardwaresGQL,
  getHardwareGQL,
  createHardwareGQL,
  updateHardwareGQL,
  deleteHardwareGQL,
  getTotalHardwareHeldGQL,
  getLoansGQL,
  getLoanGQL,
  createLoanGQL,
  updateLoanGQL,
  deleteLoanGQL,
  createLoanedGQL,
  createReturnedGQL,
  deleteHistoryGQL,
  getHardwareHistoriesGQL,
  getLoanHistoriesGQL,
  getTwilioTokenGQL,
};
