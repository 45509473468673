import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../styles/global';
import { ourNumber } from '../../../helper';
import EyeIcon from '../../../../../assets/icons/eye.svg';
import Message from './Message';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Row = styled.div`
  ${({ position }) => `
  text-align: ${position};
  align-self: ${position === 'right' ? 'end' : 'start'};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${position === 'right' ? 'flex-end' : 'flex-start'};
`}
`;

const TimestampRow = styled.div`
  font-size: 13px;
  color: ${$.color.black};
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }

  & > div {
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: ${$.color.green2};
  }
`;

const StatusDisplay = styled.div`
  ${({ status }) => {
    switch (status) {
      case 'read':
        return `color: ${$.color.blue4};`;
      case 'sent':
        return `color: ${$.color.green2};`;
      case 'delivered':
        return `color: ${$.color.green2};`;
      case 'failed':
        return `color: ${$.color.red1}`;
      default:
        return '';
    }
  }}
`;

const MessageRow = ({
  message,
  timestamp,
  from,
  handledBy,
  name,
  attachment,
  status,
}) => {
  const positioning = (() => {
    const position = from === ourNumber ? 'right' : 'left';
    const isYou = handledBy === '' || handledBy === name;
    const ts = new Date(Number(timestamp));
    const time = ts.toLocaleTimeString('en-US', {
      timeStyle: 'short',
      timeZone: 'Asia/Hong_Kong',
    });
    const dateString = ts.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Hong_Kong',
    });

    return {
      position,
      date: dateString,
      timestamp:
        position === 'right' ? `${isYou ? name : handledBy}, ${time}` : time,
      title:
        position === 'left'
          ? `First seen by ${isYou ? name : handledBy}`
          : `Replied by ${isYou ? name : handledBy}`,
    };
  })();

  return (
    <Container>
      <Row position={positioning.position}>
        <Message message={message} attachment={attachment} />
        <TimestampRow title={positioning.title}>
          {positioning.position === 'left' ? <EyeIcon /> : ''}
          <span>{`${positioning.timestamp}, ${positioning.date}`}</span>
          {positioning.position === 'right' && (
            <StatusDisplay status={status}>{status}</StatusDisplay>
          )}
        </TimestampRow>
      </Row>
    </Container>
  );
};

MessageRow.defaultProps = {
  message: '',
  handledBy: '',
  name: '',
  timestamp: Date.now().toString(),
  attachment: { type: '', value: '' },
  from: '',
  status: 'sent',
};

MessageRow.propTypes = {
  name: PropTypes.string,
  attachment: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  status: PropTypes.string,
  message: PropTypes.string,
  timestamp: PropTypes.string,
  handledBy: PropTypes.string,
  from: PropTypes.string,
};

export default MessageRow;
