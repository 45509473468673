import { gql } from 'apollo-boost';

const getLoanHistoriesGQL = gql`
  query history($id: String!) {
    getLoanHistories(id: $id) {
      hardwareID
      loanID
      comments
      timestamp
      returned
      name
    }
  }
`;

export default getLoanHistoriesGQL;
