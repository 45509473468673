const initialState = {
  name: '',
  email: '',
  ringTone: 'default',
  groups: [],
  ws: '',
};

export default (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case 'SET_USER':
      Object.keys(newState).forEach((key) => {
        if (typeof action.payload[key] !== 'undefined') {
          newState[key] = action.payload[key];
        }
      });

      return newState;
    default:
      return state;
  }
};
