export default [
  {
    value: 'Bukit Timah',
  },
  {
    value: 'Bishan',
  },
  {
    value: 'Marine Parade',
  },
  {
    value: 'Tampines',
  },
];
