import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../styles/global';
import { clearModal } from '../../actions';
import useActions from '../../utils/useActions';
import CloseIcon from '../../assets/icons/close.svg';
import Button from '../Button';
import useOutsideClick from '../../utils/useOutsideClick';

const Container = styled.div`
  width: 400px;
  height: 25vh;
  background-color: ${$.color.background};
  border-radius: 8px;
  box-shadow: ${$.dropShadow.normal};
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  padding: ${$.layout().padding2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${$.color.gray1};

  & > svg {
    width: 28px;
    height: 28px;
    fill: ${$.color.blue4};
    transition: all 0.3s ease;
    &:hover {
      fill: ${$.color.blue5};
    }
  }
`;

const Title = styled.div`
  color: ${$.color.blue4};
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
`;

const Message = styled.div`
  overflow-y: auto;
  padding: ${$.layout().padding3}px ${$.layout().padding2}px;
  height: 100%;
  color: ${$.color.blue4};
`;

const ButtonsContainer = styled.div`
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > button {
    font-size: 17px;
    padding: 10px 12px;
  }
`;

const ConfirmButton = styled(Button)`
  background-color: ${$.color.green1};
  margin-right: ${$.layout().margin2}px;
  &:hover {
    background-color: ${$.color.green2};
  }
`;

const CancelButton = styled(Button)`
  background-color: ${$.color.red1};
  &:hover {
    background-color: ${$.color.red2};
  }
`;

const StyledCloseIcon = styled(CloseIcon)``;

const Content = ({ message, title, onConfirm, onClose }) => {
  const containerRef = useRef(null);
  const [clearState] = useActions([clearModal]);

  useOutsideClick(containerRef, () => {
    clearState();
  });

  return (
    <Container ref={containerRef}>
      <TitleContainer>
        <Title>{title}</Title>
        <StyledCloseIcon
          onClick={() => {
            clearState();
          }}
        />
      </TitleContainer>
      <Message>{message}</Message>
      <ButtonsContainer>
        <ConfirmButton
          onClick={() => {
            onConfirm();
            clearState();
          }}
        >
          Confirm
        </ConfirmButton>
        <CancelButton
          onClick={() => {
            onClose();
            clearState();
          }}
        >
          Cancel
        </CancelButton>
      </ButtonsContainer>
    </Container>
  );
};

Content.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
};

Content.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Content;
