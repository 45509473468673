import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import useActions from '../../utils/useActions';
import { newHardwareState } from '../../actions';
import { createHardwareGQL } from '../../apollo';
import AddIcon from '../../assets/icons/add.svg';
import {
  FormTitle,
  Label,
  FormInput,
  TextAreaInput,
  TextInput,
  Form,
  Dropdown,
  SubmitButton,
  DoubleFormInput,
} from '../FormModal/Content/formStyles';
import typeOptions from '../HardwaresTable/typeOptions';
import locationOptions from '../HardwaresTable/locationOptions';

const NewHardwareForm = () => {
  const [formData, setFormData] = useState({
    type: 'Drawing tablet',
    location: 'Bukit Timah',
  });
  const [newLog] = useActions([newHardwareState]);
  const [createOneHardware] = useMutation(createHardwareGQL, {
    onCompleted: ({ createHardware: result }) => {
      if (result && Object.keys(result).length > 0) {
        newLog(result);
        toast.success('Hardware successfully added to database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to add hardware into database.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to add hardware into database.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Form>
      <FormTitle>New Hardware</FormTitle>
      <FormInput>
        <Label>Name</Label>
        <TextInput
          name="name"
          value={formData.name || ''}
          onChange={handleInputChange}
          placeholder="Name"
        />
      </FormInput>
      <DoubleFormInput>
        <FormInput>
          <Label>Serial Number</Label>
          <TextInput
            name="serialNumber"
            value={formData.serialNumber || ''}
            onChange={handleInputChange}
            placeholder="Serial number"
          />
        </FormInput>
        <FormInput>
          <Label>Operating System</Label>
          <TextInput
            name="os"
            value={formData.os || ''}
            onChange={handleInputChange}
            placeholder="Operating system"
          />
        </FormInput>
      </DoubleFormInput>
      <DoubleFormInput>
        <FormInput>
          <Label>Apple ID</Label>
          <TextInput
            name="appleID"
            value={formData.appleID || ''}
            onChange={handleInputChange}
            placeholder="Apple ID"
          />
        </FormInput>
        <FormInput>
          <Label>Type</Label>
          <Dropdown
            name="type"
            value={formData.type || ''}
            onChange={handleInputChange}
          >
            {typeOptions.map(({ value }, index) => (
              <option key={`hardwareForm_${value}_${index}`} value={value}>
                {value}
              </option>
            ))}
          </Dropdown>
        </FormInput>
      </DoubleFormInput>
      <FormInput>
        <Label>Location</Label>
        <Dropdown
          name="location"
          value={formData.location || ''}
          onChange={handleInputChange}
        >
          {locationOptions.map(({ value }, index) => (
            <option key={`hardwareForm_${value}_${index}`} value={value}>
              {value}
            </option>
          ))}
        </Dropdown>
      </FormInput>
      <FormInput>
        <Label>Comments</Label>
        <TextAreaInput
          name="comments"
          value={formData.comments || ''}
          onChange={handleInputChange}
          placeholder="Comments"
        />
      </FormInput>
      <SubmitButton
        onClick={() => {
          const currData = { ...formData };

          if (currData.name && currData.type) {
            createOneHardware({ variables: { ...currData } });
            setFormData({});
          }
        }}
      >
        <AddIcon />
        Create
      </SubmitButton>
    </Form>
  );
};

export default NewHardwareForm;
