import React from 'react';
import { Link } from 'gatsby';

export default [
  {
    Header: 'Hardware Name',
    id: 'hardwareName',
    accessor: (ele) => (
      <Link to={`/inventory/history?hardware=${ele.id}`}>{ele.name}</Link>
    ),
    filter: 'fuzzyText',
  },
  {
    Header: 'Serial Number',
    id: 'serialNumber',
    accessor: (ele) => ele.serialNumber,
    filter: 'fuzzyText',
  },
  {
    Header: 'Comments',
    id: 'comments',
    accessor: (ele) => ele.comments,
  },
];
