import { gql } from 'apollo-boost';

const updateLoanGQL = gql`
  mutation loan(
    $id: String
    $name: String
    $comments: String
    $totalHardwareHeld: Boolean
  ) {
    updateLoan(
      loan: {
        id: $id
        name: $name
        comments: $comments
        totalHardwareHeld: $totalHardwareHeld
      }
    ) {
      id
      name
      comments
      timestamp
      totalHardwareHeld
    }
  }
`;

export default updateLoanGQL;
