import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import Content from './Content';

const Background = styled.div`
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  background-color: ${({ show }) => (show ? 'rgba(0, 0, 0, 0.6)' : 'transparent')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 99999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DeleteConfirmationModal = () => {
  const state = useSelector(({ modalState }) => ({ modalState }));
  const showModal = state.modalState.show === true && state.modalState.deleteModal === true;

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: state.modalState.show === true ? 'modal-open' : '',
        }}
      />
      <Background show={showModal}>
        {showModal && (
          <Content
            message={state.modalState.message}
            title={state.modalState.title}
            onConfirm={state.modalState.onConfirm}
            onClose={state.modalState.onClose}
          />
        )}
      </Background>
    </>
  );
};

export default DeleteConfirmationModal;
