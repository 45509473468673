const initialState = {
  show: false,
  title: 'There is nothing here',
  state: {},
  message: '',
  components: [],
  onClose: () => {},
  onConfirm: () => {},
  deleteModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DELETE_MODAL':
      return {
        ...state,
        deleteModal: true,
        show: true,
        ...action.payload,
      };
    case 'SET_FORM_MODAL':
      return { ...state, show: true, ...action.payload };
    case 'CLEAR_MODAL':
      return initialState;
    default:
      return state;
  }
};
