const initialState = [];

const type = () => {
  const params = new URLSearchParams(window.location.search);
  const hardwareParam = params.get('hardware');

  if (hardwareParam) {
    return 'hardware';
  }

  return 'loan';
};

const highlightHardwareRows = (arr) =>
  arr.map((log, index) =>
    index === 0 ? { rowHighlight: '#c9c3af', ...log } : log
  );

const highlightLoanRows = (arr) => {
  const storage = [];

  return arr.map(({ hardwareID, ...rest }) => {
    if (storage.indexOf(hardwareID) === -1) {
      storage.push(hardwareID);
      return {
        hardwareID,
        rowHighlight: '#c9c3af',
        ...rest,
      };
    }

    return { hardwareID, ...rest };
  });
};

export default (state = initialState, action) => {
  let array = [];

  switch (action.type) {
    case 'CLEAR_HISTORIES_STATE':
      return [];
    case 'UPDATE_HISTORIES_STATE':
      array =
        type() === 'hardware'
          ? highlightHardwareRows(action.payload)
          : highlightLoanRows(action.payload);

      return array.length >= 1 ? array : state;

    case 'DELETE_HISTORY_STATE':
      if (action.payload.hardwareID && action.payload.timestamp) {
        array = state.filter((ele) => {
          if (
            ele.hardwareID === action.payload.hardwareID &&
            ele.timestamp === action.payload.timestamp
          ) {
            return false;
          }

          return true;
        });

        return type() === 'hardware'
          ? highlightHardwareRows(array)
          : highlightLoanRows(array);
      }

      return state;
    default:
      return state;
  }
};
