import React, { useState } from 'react';
import styled from 'styled-components';

import StartTwilio from './StartTwilio';
import Twilio from './Twilio';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TwilioDialInterface = () => {
  const [clicked, setClicked] = useState(false);

  return (
    <Container>
      {clicked === false ? <StartTwilio setClicked={setClicked} /> : <Twilio />}
    </Container>
  );
};

export default TwilioDialInterface;
