export default [
  {
    inputs: [
      [
        {
          label: 'First name',
          name: 'first_name',
        },
        {
          name: 'last_name',
          label: 'Last name',
        },
      ],
      [
        {
          name: 'phone',
          label: 'Phone',
        },
        {
          name: 'email',
          label: 'Email',
        },
      ],
      [
        {
          name: 'course',
          label: 'Course',
        },
        {
          name: 'location',
          label: 'Location',
        },
      ],
      [
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments',
        },
      ],
    ],
  },
];
