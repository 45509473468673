import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { googleMaps } from '../../../../helper';

const Container = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MapPreview = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const GMap = ({
  lat, lng,
}) => {
  useEffect(() => {
  }, []);

  /**
   * This is the free version of Google Map embed API.
   */
  return (
    <Container>
      <MapPreview
        frameborder="0"
        src={`https://www.google.com/maps/embed/v1/place?q=${`${lat} ${lng}`}&key=${googleMaps}`}
        allowfullscreen
      />
    </Container>
  );
};

GMap.defaultProps = {
  lat: 0,
  lng: 0,
};

GMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default GMap;
