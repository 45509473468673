import { Auth, API } from 'aws-amplify';

export default () => {
  const payload = Auth.currentSession()
    .then((session) => ({
      headers: { Authorization: session.idToken.jwtToken },
      response: true,
    }))
    .then((myInit) => API.get('APIGatewayGetPhoneList', '/', myInit))
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);
      return [];
    });

  return {
    type: 'GET_PHONE_LIST',
    payload,
  };
};
