import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../../../../styles/global';
import Pic from './Pic';
import GMap from './GMap';
import Video from './Video';
import Doc from './Doc';
import Audio from './Audio';
import { parseSlackMessage } from '../../../../helper';

const Container = styled.div`
  border-radius: 10px;
  background-color: ${$.color.white};
  margin-bottom: 5px;
  max-width: 320px;
  overflow: hidden;
`;

const TextMessage = styled.div`
  color: ${$.color.black};
  white-space: pre-wrap;
  text-align: left;
  width: calc(100% - ${$.layout().padding2 * 2}px);
  padding: ${$.layout().padding1}px ${$.layout().padding2}px;
  word-break: break-word;
  line-height: 1.3em;
  font-size: 15px;

  .empty {
    color: ${$.color.gray2};
    font-size: 15px;
  }

  pre {
    margin: 0;
    display: inline-block;
    white-space: pre-wrap;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: none;
    color: ${$.color.blue5};
    font-weight: bold;
  }
`;

/**
 * Determines what React component to show based on obj.type
 * @param {string} obj.type
 * @param {string | object} obj.value
 * @param {string} obj.value.latitude
 * @param {string} obj.value.longitude
 */
const showAttachment = (obj) => {
  const isImage = obj.type.indexOf('image') > -1;
  const isVideo = obj.type.indexOf('video') > -1;
  const isAudio = obj.type.indexOf('audio') > -1;
  const isLocation = obj.type === 'location';
  const isDocument = !isImage && !isVideo && !isAudio && obj.type;
  const lng = obj.value?.longitude;
  const lat = obj.value?.latitude;
  const replaceWithCloudFront = obj.value && typeof obj.value === 'string' ? obj.value.replace('sgcc-phone-dashboard-whatsapp-files.s3.ap-southeast-1.amazonaws.com', 'd2za9yb6rs14ah.cloudfront.net') : '';

  if (isImage) {
    return <Pic url={replaceWithCloudFront} type={obj.type} />;
  }

  if (isLocation && lat && lng) {
    return <GMap lat={Number(lat)} lng={Number(lng)} />;
  }

  if (isVideo) {
    return <Video url={obj.value} type={obj.type} />;
  }

  if (isAudio) {
    return <Audio url={obj.value} type={obj.type} />;
  }

  if (isDocument) {
    return <Doc url={replaceWithCloudFront} type={obj.type} />;
  }

  return '';
};

const Message = ({
  message, attachment,
}) => (
  <Container>
    {attachment && showAttachment(attachment)}
    {message && (
      <TextMessage
        dangerouslySetInnerHTML={{
          __html: parseSlackMessage(message),
        }}
      />
    )}
    {!message && attachment.type === '' && (
      <TextMessage
        dangerouslySetInnerHTML={{
          __html: '<i class="empty">Empty message</i>',
        }}
      />
    )}
  </Container>
);

Message.defaultProps = {
  message: '',
  attachment: { type: '', value: '' },
};

Message.propTypes = {
  message: PropTypes.string,
  attachment: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default Message;
