const initialState = [
  {
    comments: 'qweqwe',
    course: 'qweqwe',
    first_name: 'TF',
    id: 'b5ad61f2-1272-4980-a8fa-9b59f84813f8',
    last_name: 'Toh',
    phone: 'qweqwe',
    email: 'dasdasd',
    location: 'dasdsad',
  },
];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_LEAD_STATE':
      if (action.payload.id) {
        return state.map((ele) =>
          ele.id === action.payload.id ? action.payload : ele
        );
      }

      return state;
    case 'UPDATE_LEADS_STATE':
      return action.payload;
    case 'DELETE_LEAD_STATE':
      if (action.payload) {
        return state.filter((ele) => ele.id !== action.payload);
      }

      return state;
    case 'NEW_LEAD_STATE':
      if (action.payload.id) {
        return [action.payload, ...state];
      }

      return state;
    default:
      return state;
  }
};
