import React from 'react';
import styled from 'styled-components';

import $ from '../../../../styles/global';
import CallIcon from '../../../../assets/icons/call.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import ThumbsUpIcon from '../../../../assets/icons/thumbs-up.svg';
import PersonIcon from '../../../../assets/icons/person.svg';
import BellIcon from '../../../../assets/icons/bell.svg';
import LoaderIcon from '../../../../assets/icons/loader.svg';
import EndCallIcon from '../../../../assets/icons/end-call.svg';
import PersonTickIcon from '../../../../assets/icons/person-with-tick.svg';
import InfoIcon from '../../../../assets/icons/info.svg';
import { useMyContext } from './Context';
import RingTone from './RingTone';
import { FormTitle, Form } from '../../../FormModal/Content/formStyles';

const StyledForm = styled(Form)`
  max-height: 630px;
  height: 100%;
`;

const LogHistoryContainer = styled.div`
  border: 1px solid ${$.color.gray1};
  background-color: ${$.color.white2};
  border-radius: 10px;
  overflow: auto;
  flex-grow: 2;
  margin-bottom: ${$.layout().margin1}px;
  & > *:nth-child(odd) {
    background-color: #e7dede;
  }
`;

const Feed = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${$.layout().padding2}px;
  & > svg {
    align-self: flex-start;
    width: 24px;
    height: 24px;
    fill: ${$.color.blue4};
    margin-right: ${$.layout().margin2}px;
    &.red {
      fill: ${$.color.red1};
    }
    &.green {
      fill: ${$.color.green1};
    }
    &.orange {
      fill: ${$.color.orange3};
    }
  }
`;

const FeedContent = styled.div`
  ${({ type }) => `
  display: flex;
  width: calc(100% - 24px - ${$.layout().margin2}px);
  flex-direction: column;
  & > span {
    font-size: 16px;
    ${type === 'action' ? 'font-style: italic;' : ''}
    color: ${type === 'action' ? $.color.red2 : $.color.blue4};
    line-height: 17px;
    margin-bottom: 5px;
  }
`}
`;

const FeedTimestamp = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${$.color.orange4};
`;

const CallLogHistory = () => {
  const { logs } = useMyContext();

  const setIcon = (name) => {
    switch (name) {
      case 'end call':
        return <EndCallIcon className="red" />;
      case 'call':
        return <CallIcon />;
      case 'warning':
        return <CloseIcon className="red" />;
      case 'thumbs up':
        return <ThumbsUpIcon className="green" />;
      case 'contact found':
        return <PersonTickIcon className="green" />;
      case 'contact not found':
        return <PersonIcon className="red" />;
      case 'bell':
        return <BellIcon className="orange" />;
      case 'info':
        return <InfoIcon className="red" />;
      default:
        return <CallIcon />;
    }
  };

  return (
    <StyledForm>
      <FormTitle>Logs</FormTitle>
      <LogHistoryContainer>
        {logs.length > 0 ? (
          logs.map(({ icon, entry, time, type }, index) => (
            <Feed key={`feed_container_${time}_${index}`}>
              {setIcon(icon)}
              <FeedContent type={type}>
                <span>{entry}</span>
                <FeedTimestamp>{time}</FeedTimestamp>
              </FeedContent>
            </Feed>
          ))
        ) : (
          <Feed>
            <LoaderIcon />
            <FeedContent>Loading</FeedContent>
          </Feed>
        )}
      </LogHistoryContainer>
      <RingTone />
    </StyledForm>
  );
};

export default CallLogHistory;
