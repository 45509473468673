import { gql } from 'apollo-boost';

const createReturnedGQL = gql`
  mutation history(
    $hardwareID: String
    $loanID: String
    $timestamp: Date
    $comments: String
  ) {
    createReturned(
      history: {
        hardwareID: $hardwareID
        loanID: $loanID
        timestamp: $timestamp
        comments: $comments
      }
    ) {
      hardwareID
      loanID
      timestamp
      comments
      returned
    }
  }
`;

export default createReturnedGQL;
