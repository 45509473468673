/* eslint-disable no-param-reassign */
import { ourNumber } from '../../components/WhatsappTable/helper';

/**
 * In dev mode, this shows console log.
 * In prod mode, this prevents the dashboard users from seeing our test number.
 */
const mode = {
  test: process && process.env && process.env.NODE_ENV === 'development',
  testPhones: ['+60165016038'],
};

const initialState = {
  // list of chats to build left bar along with their solo message
  phoneList: { phone: [], message: {} },
  noti: {},
  // list of messages for each number
  messages: {},
  statuses: {},
  ws: '',
  currentPhone: '',
};

/**
 * Chrome won't play audio unless the tab is active. This could be related
 * to how Google Chrome loads resources. Tab inactive -> Chrome restricts
 * resources from loading -> Unable to play audio since mp3 file not loaded.
 *
 * Making the audio resource load when page is loaded should solve this issue.
 */
const audio =
  typeof window !== 'undefined'
    ? new Audio('/ringtone/whatsapp_tone1.mp3')
    : {};

/**
 * We may receive messages from websocket and from DB. We don't know if we have
 * already took the message, so it's best to filter here rather than do a double
 * ping at the DB to exclude the messages we already have.
 *
 * Also sorts in ascending order based on timestamp
 */
const filterOutRepeatedMessages = (list) => {
  const repeatedMessages = [];

  return list
    .filter(({ messageSid, timestamp }) => {
      if (
        repeatedMessages.indexOf(messageSid) === -1 &&
        repeatedMessages.indexOf(timestamp) === -1
      ) {
        repeatedMessages.push(messageSid);
        repeatedMessages.push(timestamp);
        return true;
      }

      return false;
    })
    .sort((a, b) => {
      const aTimestamp = Number(a.timestamp);
      const bTimestamp = Number(b.timestamp);

      return aTimestamp - bTimestamp;
    });
};

/**
 * A function to standardize adding new messages to the list. Ways for new
 * messages to enter list:
 * 1. First time page load, 1 message is pulled from db to show in phone number list.
 * 2. New messages coming in through websocket.
 * 3. Sending message to the client.
 */
const updateMessageItems = (state, phone, newData) => {
  const items =
    state.messages[phone] && state.messages[phone].Items
      ? state.messages[phone].Items
      : [];

  const obj = {
    Items: filterOutRepeatedMessages([...newData.Items, ...items]),
  };

  if (newData.LastEvaluatedKey) {
    obj.LastEvaluatedKey = newData.LastEvaluatedKey;
  }

  return obj;
};

/**
 * Sets the document title to reflect the unseen notis.
 *
 * Important Note: We could have done this part on the React Component pages
 * but for some reason, React Component pages isn't UPDATED when the websocket
 * informs of a change IF the BROWSER TAB is INACTIVE/HIDDEN/MINIMIZED. Thus,
 * everything that needs to happen after the websocket informs of a
 * change won't happen.
 *
 * This happens only in Chrome. I tried fiddling around and noticed
 * that it works as expected only if I added it in a .JS file.
 * So I'm putting the code here so it works properly even if
 * the browser tab is inactive. Once the user clicks on the browser tab
 * websocket should continue working properly with no hiccup.
 */
const setDocumentTitle = (state) => {
  let notiTotal = 0;

  Object.keys(state.noti).forEach((phone) => {
    if (mode.test) {
      if (mode.testPhones.indexOf(phone) > -1) {
        notiTotal += state.noti[phone];
      }
    } else if (mode.testPhones.indexOf(phone) === -1) {
      notiTotal += state.noti[phone];
    }
  });

  document.title =
    notiTotal === 0
      ? 'SGCC Dashboard'
      : `(${notiTotal}) ${notiTotal === 1 ? 'chat' : 'chats'} | SGCC Dashboard`;
};

/**
 * Decides when to play the noti sound. We try not to blast often.
 *
 * Important Note: We could have done this part on the React Component pages
 * but for some reason, React Component pages isn't UPDATED when the websocket
 * informs of a change IF the BROWSER TAB is INACTIVE/HIDDEN/MINIMIZED. Thus,
 * everything that needs to happen after the websocket informs of a
 * change won't happen.
 *
 * This happens only in Chrome. I tried fiddling around and noticed
 * that it works as expected only if I added it in a .JS file.
 * So I'm putting the code here so it works properly even if
 * the browser tab is inactive. Once the user clicks on the browser tab,
 * websocket should continue working properly with no hiccup.
 */
const playNotiSound = (phone) => {
  if (document.hidden) {
    audio.play();
  }

  if (!document.hidden && phone !== ourNumber) {
    audio.play();
  }
};

/**
 * Standardize the latest message shown on the phone list.
 */
const setPhoneRowMessage = (payload) => {
  if (payload.message) {
    return payload.message;
  }

  if (payload.attachment) {
    return `1 ${payload.attachment.type} received.`;
  }

  return '_Empty message_';
};

export default (state = initialState, action) => {
  const newState = { ...state };
  let phone = '';
  let found = true;

  switch (action.type) {
    case 'CURRENT_PHONE':
      newState.currentPhone = action.payload;
      return newState;
    case 'STATUS_UPDATE':
      /**
       * If we are in dev mode, show console log.
       */
      if (mode.test) {
        console.log('STATUS RECEIVED: ', action.payload);
      } else if (mode.testPhones.indexOf(action.payload.phone) > -1) {
        return newState;
      }

      /**
       * Updates the message status immediately or store it.
       * We will update the message when the browser receives it.
       */
      if (typeof action.payload.timestamp !== 'undefined') {
        newState.statuses[action.payload.timestamp] = action.payload;
        newState.statuses[action.payload.messageSid] = action.payload;
      }

      return newState;
    case 'NEW_INCOMING_MESSAGE':
      /**
       * If we are in dev mode, show console log.
       */
      if (mode.test) {
        console.log('INCOMING MESSAGE: ', action.payload);
      } else if (mode.testPhones.indexOf(action.payload.phone) > -1) {
        return newState;
      }

      if (
        typeof action.payload.message !== 'undefined' &&
        typeof action.payload.phone !== 'undefined'
      ) {
        phone = action.payload.phone;

        if (ourNumber !== action.payload.from) {
          newState.noti[phone] = 1;
        }

        setDocumentTitle(newState);
        playNotiSound(action.payload.from);

        /**
         * Creates a new entry if we have a new phone messaging us.
         */
        found =
          newState.phoneList.phone
            .map(({ phone: phoneNumber }) => phoneNumber)
            .filter((phoneNumber) => phoneNumber === phone).length > 0;

        if (found === false) {
          newState.phoneList.phone.unshift({
            phone,
            timestamp: Date.now(),
            key: Date.now(),
          });
        }

        newState.phoneList.phone = newState.phoneList.phone.map(
          ({ timestamp, phone: currPhone, ...rest }) => {
            const ts = Number(timestamp);
            const time = new Date(ts).toLocaleTimeString('en-US', {
              timeStyle: 'short',
            });

            return {
              ...rest,
              timestamp:
                phone === currPhone
                  ? Number(action.payload.timestamp)
                  : timestamp,
              parsedTimestamp:
                phone === currPhone ? time : rest.parsedTimestamp,
              phone: currPhone,
            };
          }
        );
        newState.phoneList.message[phone] = setPhoneRowMessage(action.payload);

        newState.messages[phone] = updateMessageItems(newState, phone, {
          Items: [action.payload],
          LastEvaluatedKey:
            newState.messages[phone] &&
            newState.messages[phone].LastEvaluatedKey
              ? newState.messages[phone].LastEvaluatedKey
              : undefined,
        });
      }

      return newState;
    case 'CLEAR_NOTI':
      newState.noti[action.payload] = 0;
      setDocumentTitle(newState);

      return newState;
    case 'SET_HANDLED':
      newState.phoneList.phone = newState.phoneList.phone.map(
        ({ phone: phoneNumber, timestamp, handledBy, handledAt, ...rest }) => {
          if (action.payload.phone === phoneNumber) {
            return {
              ...rest,
              phone: phoneNumber,
              timestamp,
              handledBy: action.payload.handledBy,
              handledAt: action.payload.handledAt
                ? `${new Date(
                    Number(action.payload.handledAt)
                  ).toLocaleTimeString('en-US', {
                    timeStyle: 'short',
                  })} @ ${new Date(
                    Number(action.payload.handledAt)
                  ).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: '2-digit',
                    timeZone: 'Asia/Hong_Kong',
                  })}`
                : '',
            };
          }

          return {
            ...rest,
            phone: phoneNumber,
            timestamp,
            handledBy,
            handledAt,
          };
        }
      );

      return newState;
    case 'GET_PHONE_LIST':
      newState.phoneList.phone = action.payload.phone
        .map(({ phone: phoneNumber, timestamp, handledBy, handledAt }) => {
          const ts = Number(timestamp);
          const time = new Date(ts).toLocaleTimeString('en-US', {
            timeStyle: 'short',
          });
          const handledAtTimestamp = handledAt
            ? `${new Date(Number(handledAt)).toLocaleTimeString('en-US', {
                timeStyle: 'short',
              })} @ ${new Date(Number(handledAt)).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: '2-digit',
                timeZone: 'Asia/Hong_Kong',
              })}`
            : '';

          return {
            phone: phoneNumber,
            timestamp: ts,
            parsedTimestamp: time,
            key: timestamp,
            handledBy: handledBy || '',
            handledAt: handledAtTimestamp,
          };
        })
        .sort((curr, prev) => prev.timestamp - curr.timestamp)
        .filter(({ phone: phoneNumber }) => {
          if (!mode.test && mode.testPhones.indexOf(phoneNumber) > -1) {
            return false;
          }

          return phoneNumber !== ourNumber;
        });

      Object.keys(action.payload.message).forEach((phoneNumber) => {
        const message = action.payload.message[phoneNumber];

        if (message.handledBy === '') {
          newState.noti[phoneNumber] = 1;
          audio.play();
        }

        newState.phoneList.message[phoneNumber] = setPhoneRowMessage(message);
      });

      setDocumentTitle(newState);

      return newState;
    case 'UPDATE_PHONE_LIST':
      newState.phoneList.names = action.payload;
      return newState;
    case 'GET_ALL_MESSAGES':
      phone = action.payload.phone;

      newState.messages[phone] = updateMessageItems(
        newState,
        phone,
        action.payload.data
      );

      return newState;
    default:
      return newState;
  }
};
