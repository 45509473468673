import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../Button';

import { useMyContext } from './Context';
import CallIcon from '../../../../assets/icons/call.svg';
import EndCallIcon from '../../../../assets/icons/end-call.svg';
import $ from '../../../../styles/global';

const Container = styled.div`
  & > * {
    margin-bottom: ${$.layout().margin1}px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    &:first-child {
      margin-right: 4%;
    }
    &:nth-child(2) {
      background-color: ${$.color.red1};
      &:hover {
        background-color: ${$.color.red2};
      }
    }
  }

  button {
    width: 48%;
    font-size: 17px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      padding: 8px;
      text-align: center;
      flex-grow: 2;
    }
    svg {
      padding: 10px 8px;
      fill: ${$.color.white};
      width: 24px;
      height: 24px;
      border-right: 1px solid ${$.color.white};
    }
  }
`;

const Buttons = () => {
  const { makeCall, setButtons } = useMyContext();
  const acceptIncomingRef = useRef(null);
  const ignoreIncomingRef = useRef(null);
  const hangUpRef = useRef(null);

  useEffect(() => {
    setButtons((prev) => {
      const newPrev = { ...prev };

      newPrev.accept = newPrev.accept || {};
      newPrev.accept.ref = acceptIncomingRef;

      newPrev.ignore = newPrev.ignore || {};
      newPrev.ignore.ref = ignoreIncomingRef;

      newPrev.hangup = newPrev.hangup || {};
      newPrev.hangup.ref = hangUpRef;

      return newPrev;
    });
  }, []);

  return (
    <Container>
      <ButtonContainer>
        <Button ref={acceptIncomingRef}>
          <CallIcon />
          <span>Accept</span>
        </Button>
        <Button ref={ignoreIncomingRef}>
          <EndCallIcon />
          <span>Ignore</span>
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button onClick={makeCall}>
          <CallIcon />
          <span>Make Call</span>
        </Button>
        <Button ref={hangUpRef}>
          <EndCallIcon />
          <span>Hang Up</span>
        </Button>
      </ButtonContainer>
      {/* Not used at the moment. Both buttons do nothing. */}
      {/* <ButtonContainer>
        <Button>
          <Icon name="call" />
          Transfer Call to MP
        </Button>
        <Button>
          <Icon name="call" flipped="vertically" />
          Transfer Call to BT
        </Button>
      </ButtonContainer> */}
    </Container>
  );
};

export default Buttons;
