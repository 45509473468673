import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';

const Container = styled.div`
  height: calc(100% - 30px);
  overflow: scroll;
  padding: 15px;
`;

const Row = styled.div`
  display: flex;
  padding-bottom: 8px;
  flex-direction: row;
  align-items: center;

  & > div {
    border-radius: 999px;
    width: 8px;
    height: 8px;
    background-color: ${$.color.green1};
    margin-right: ${$.layout().margin1}px;
  }

  & > span {
    font-size: 16px;
    color: ${$.color.blue5};
  }
`;

const Members = () => {
  const state = useSelector(({ user }) => ({ user }));
  const [members, setMembers] = useState({});

  useEffect(() => {
    const memberCB = (e) => {
      setMembers((prev) => {
        const newPrev = { ...prev };

        newPrev[e.detail.name] = e.detail.timestamp;

        return newPrev;
      });
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('isOnline', memberCB);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('isOnline', memberCB);
      }
    };
  }, []);

  useEffect(() => {
    let interval;

    if (state.user.ws) {
      interval = setInterval(() => {
        state.user.ws.isOnline();

        setMembers((prev) => {
          const newPrev = { ...prev };

          Object.keys(newPrev).forEach((name) => {
            if (
              Date.now() - newPrev[name] >= 1000 * 30 &&
              name !== state.user.name
            ) {
              delete newPrev[name];
            }
          });

          return newPrev;
        });
      }, 1000 * 10);

      state.user.ws.isOnline();
      setMembers({ [state.user.name]: Date.now() });
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [state.user.ws]);

  return (
    <Container>
      {Object.keys(members).length > 0 ? (
        Object.keys(members).map((member) => (
          <Row key={member}>
            <div />
            <span>{member}</span>
          </Row>
        ))
      ) : (
        <Row>
          <span>No one online</span>
        </Row>
      )}
    </Container>
  );
};

export default Members;
