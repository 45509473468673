import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import { parseSlackMessage } from '../helper';
import DownChevronIcon from '../../../assets/icons/down-chevron.svg';
import EyeIcon from '../../../assets/icons/eye.svg';
import CaseHandled from '../../../assets/icons/double-check.svg';

const MessageContainer = styled.div`
  ${({ bolden }) => `
  margin-top: ${$.layout().margin1}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${bolden ? 'font-weight: bold;' : ''}

  & > svg {
    width: 26px;
    height: 26px;
    fill: ${$.color.whatsappText};
    opacity: 0;
    transform: translateX(0.7em);
    transition: all 0.3s ${$.easingFn.standard};
  }
`}
`;

const Container = styled.div`
  ${({ clicked, noti }) => {
    if (noti) {
      return 'background-color: #fcfce8;';
    }

    if (clicked) {
      return `background-color: ${$.color.whatsappHover};`;
    }

    return `background-color: ${$.color.white};`;
  }}

  width: calc(100% - ${$.layout().padding2 * 2}px);
  padding: ${$.layout().padding2}px;
  border-bottom: 1px solid ${$.color.whatsappBorder};
  transition: background-color 0.35s ease;

  &:hover {
    background-color: ${$.color.whatsappHover};
    cursor: pointer;
    ${MessageContainer} > svg {
      opacity: 1;
      transform: translateX(0.3em);
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PhoneNumberContainer = styled.div`
  ${({ noti, caseHandled }) => `
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :nth-child(1) {
    font-size: 16px;
    color: ${$.color.black};
    margin-right: ${$.layout().margin1}px;
    ${noti ? 'font-weight: bold;' : ''}
  }

  & > svg {
    width: 16px;
    height: 16px;
    fill: ${caseHandled ? $.color.green1 : $.color.gray2};
  }
`}
`;

const NotiIndicator = styled.div`
  border-radius: 999px;
  background-color: ${$.color.red1};
  width: 12px;
  height: 12px;
  margin-right: ${$.layout().margin1}px;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    text-align: right;
    color: ${$.color.gray2};
    font-size: 12px;
  }
`;

const ViewCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 3px;
  border-radius: 5px;
  background-color: ${$.color.green1};
  margin-right: ${$.layout().margin1}px;

  & > svg {
    fill: ${$.color.white};
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  span {
    font-size: 10px;
    color: ${$.color.white};
  }
`;

const Message = styled.div`
  font-size: 14px;
  color: ${$.color.gray2};
  width: 100%;
  pointer-events: none;
  ${$.ellipsis}

  a {
    color: ${$.color.gray2};
    &:visited {
      color: ${$.color.gray2};
    }
  }

  pre {
    margin: 0;
    display: inline-block;
    white-space: pre-wrap;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
`;

const PhoneRow = ({
  phone,
  timestamp,
  onClick,
  message,
  names,
  viewers,
  parsedTimestamp,
}) => {
  const { noti, currentPhone, phoneList } = useSelector(
    ({ whatsapp }) => whatsapp
  );
  const messageDate = new Date(timestamp).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
    timeZone: 'Asia/Hong_Kong',
  });
  const [{ handledAt = '' }] = phoneList.phone.filter(
    ({ phone: mappedPhone }) => mappedPhone === phone
  );

  return (
    <Container
      clicked={phone === currentPhone}
      title={`${phone} | Latest message: ${messageDate}${
        handledAt ? ' | CASE CLOSED' : ' | CASE ONGOING'
      }`}
      onClick={onClick}
      noti={!!noti[phone]}
    >
      <Title>
        <PhoneNumberContainer noti={!!noti[phone]} caseHandled={!!handledAt}>
          <div>{names[phone]?.name || phone}</div>
          {noti[phone] ? <NotiIndicator /> : ''}
          <CaseHandled />
        </PhoneNumberContainer>
        <TimestampContainer>
          {viewers && viewers.length > 0 ? (
            <ViewCount title={`Users viewing this chat: ${viewers.join(', ')}`}>
              <EyeIcon />
              <span>{viewers.length}</span>
            </ViewCount>
          ) : (
            ''
          )}
          <span>
            {parsedTimestamp}
            <br />
            {messageDate}
          </span>
        </TimestampContainer>
      </Title>
      <MessageContainer bolden={!!noti[phone]}>
        <Message
          dangerouslySetInnerHTML={{
            __html: parseSlackMessage(message),
          }}
        />
        <DownChevronIcon />
      </MessageContainer>
    </Container>
  );
};

PhoneRow.defaultProps = {
  phone: '',
  names: {},
  timestamp: 0,
  parsedTimestamp: '',
  onClick: () => {},
  message: '',
  viewers: [],
};

PhoneRow.propTypes = {
  viewers: PropTypes.arrayOf(PropTypes.string),
  phone: PropTypes.string,
  names: PropTypes.objectOf(PropTypes.object),
  timestamp: PropTypes.number,
  parsedTimestamp: PropTypes.string,
  onClick: PropTypes.func,
  message: PropTypes.string,
};

export default PhoneRow;
