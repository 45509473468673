import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';

import LoaderIcon from '../assets/icons/loader.svg';

const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${$.color.blue4};

  svg {
    width: 20px;
    height: 20px;
    fill: ${$.color.blue4};
    margin-right: ${$.layout().margin1}px;
  }
`;

const LoadingMessage = ({ itemName }) => (
  <Message>
    <LoaderIcon />
    {`Loading ${itemName}`}
  </Message>
);

LoadingMessage.propTypes = {
  itemName: PropTypes.string,
};

LoadingMessage.defaultProps = {
  itemName: '',
};

export default LoadingMessage;
