import React from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';

import $ from '../../styles/global';
import Button from '../Button';
import LogoutIcon from '../../assets/icons/logout.svg';
import CallIcon from '../../assets/icons/call.svg';
import InventoryIcon from '../../assets/icons/inventory.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: ${$.layout().margin2}px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: ${$.color.blue5};
  color: ${$.color.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;

  &:hover {
    background-color: ${$.color.blue4};
  }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  width: 20px;
  height: 20px;
  fill: ${$.color.white};
  margin-right: ${$.layout().margin1}px;
`;

const LinkToDashboard = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  color: ${$.color.blue4};
  padding-bottom: 4px;
  text-decoration: none;

  & svg {
    fill: ${$.color.blue4};
    width: 20px;
    height: 20px;
    position: relative;
    top: 1px;
    margin-right: ${$.layout().margin1}px;
    margin-bottom: 2px;
  }

  &:hover {
    color: ${$.color.blue5};
    & svg {
      fill: ${$.color.blue5};
    }
  }
`;

const Menu = () => {
  const state = useSelector(({ user }) => {
    return { user };
  });

  return (
    <Container>
      {state.user.groups.indexOf('inventory') >= -1 && (
        <>
          <LinkToDashboard to="/" title="Go to Phone Dashboard">
            <CallIcon />
            Phone
          </LinkToDashboard>
          <LinkToDashboard to="/inventory/" title="Go to Inventory Dashboard">
            <InventoryIcon />
            Inventory
          </LinkToDashboard>
        </>
      )}
      <StyledButton
        onClick={() => {
          Auth.signOut().catch((e) => {
            /* eslint-disable-next-line */
            console.log('Logout error: ', e);
          });
        }}
      >
        <StyledLogoutIcon />
        Log out
      </StyledButton>
    </Container>
  );
};

export default Menu;
