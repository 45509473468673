import React from 'react';

import { useMyContext } from './Context';
import {
  Label,
  TextInput,
  FormInput,
  FormTitle,
  Form,
} from '../../../FormModal/Content/formStyles';
import Buttons from './Buttons';

const ControlPanel = () => {
  const { phoneNumber, setPhoneNumber } = useMyContext();

  return (
    <Form>
      <FormTitle>Control Panel</FormTitle>
      <FormInput>
        <Label>Phone Number</Label>
        <TextInput
          placeholder="Enter phone number here"
          onChange={(evt) => {
            setPhoneNumber(evt.target.value.trim());
          }}
          value={phoneNumber || ''}
        />
      </FormInput>
      <Buttons />
    </Form>
  );
};

export default ControlPanel;
