import { gql } from 'apollo-boost';

const updateLeadGQL = gql`
  mutation lead(
    $id: String!
    $comments: String
    $first_name: String
    $last_name: String
    $course: String
    $email: String
    $phone: String
    $location: String
    $timestamp: String
  ) {
    updateLead(lead: { 
      id: $id,
      comments: $comments,
      first_name: $first_name,
      last_name: $last_name,
      course: $course,
      email: $email,
      phone: $phone,
      location: $location,
      timestamp: $timestamp,
    }) {
      id
      comments
      first_name
      last_name
      course
      email
      phone
      location
      timestamp
    }
  }
`;

export default updateLeadGQL;
