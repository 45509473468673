import { gql } from 'apollo-boost';

const deleteHistoryGQL = gql`
  mutation history($hardwareID: String!, $timestamp: Date!) {
    deleteHistory(hardwareID: $hardwareID, timestamp: $timestamp) {
      hardwareID
      timestamp
    }
  }
`;

export default deleteHistoryGQL;
