export default [
  {
    inputs: [
      [
        {
          label: 'Name',
          name: 'name',
        },
      ],
      [
        {
          type: 'checkbox',
          name: 'totalHardwareHeld',
          label: 'Show total hardware held?',
        },
      ],
      [
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments',
        },
      ],
    ],
  },
];
