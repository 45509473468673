import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import useActions from '../../../../utils/useActions';
import { setUser } from '../../../../actions';
import $ from '../../../../styles/global';
import PlayIcon from '../../../../assets/icons/play.svg';
import StopIcon from '../../../../assets/icons/stop.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${$.layout().padding2}px;
  border: 1px solid ${$.color.gray1};
  border-radius: 10px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${$.color.blue4};
  width: 30%;
  margin-right: ${$.layout().margin1}px;
`;

const Dropdown = styled.select`
  border-radius: 10px;
  border: 1px solid ${$.color.gray1};
  padding: ${$.layout().padding1}px;
  align-self: stretch;
  width: 70%;
  color: ${$.color.blue4};
  font-size: 16px;
`;

const PlayButton = styled(PlayIcon)`
  fill: ${$.color.green1};
  width: 30px;
  height: 30px;
`;

const StopButton = styled(StopIcon)`
  fill: ${$.color.red1};
  width: 30px;
  height: 30px;
`;

const TestPlayAudio = styled.div`
  margin-left: ${$.layout().margin1}px;
  ${({ disable }) => {
    if (disable === true) {
      return `pointer-events: none; > svg { fill: ${$.color.gray1} !important; }`;
    }

    return '';
  }};
`;

const RingTone = () => {
  const state = useSelector(({ user }) => ({ user }));
  const [music, setMusic] = useState({ play: false, audio: '' });
  const [setOneUser] = useActions([setUser]);

  return (
    <Container>
      <Label>Choose ringtone:</Label>
      <Dropdown
        value={state.user.ringTone}
        onChange={(e) => {
          const { localStorage } = typeof window === 'undefined' ? {} : window;
          const rt = JSON.parse(localStorage.getItem('ringTone') || '{}');

          rt[state.user.email] = e.target.value;

          localStorage.setItem('ringTone', JSON.stringify(rt));
          setOneUser({ ringTone: e.target.value });
        }}
      >
        <option value="default">Twilio default</option>
        <option value="/ringtone/ring_1.mp3">Ring 1</option>
        <option value="/ringtone/ring_2.mp3">Ring 2</option>
        <option value="/ringtone/ring_3.mp3">Ring 3</option>
      </Dropdown>
      <TestPlayAudio
        disable={state.user.ringTone === 'default'}
        title={music.play === true ? 'Stop ringtone' : 'Play ringtone'}
        onClick={() => {
          setMusic((prev) => {
            const newPrev = { ...prev };

            newPrev.play = !newPrev.play;

            if (newPrev.play === true) {
              newPrev.audio = new Audio(state.user.ringTone);
              newPrev.audio.loop = true;
              newPrev.audio.play();
            } else {
              newPrev.audio.pause();
              newPrev.audio = '';
            }

            return newPrev;
          });
        }}
      >
        {music.play === true ? <StopButton /> : <PlayButton />}
      </TestPlayAudio>
    </Container>
  );
};

export default RingTone;
