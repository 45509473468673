import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getPhoneList,
  getAllMessages,
  clearNoti,
  setCurrentPhone,
} from '../../../actions';
import useActions from '../../../utils/useActions';
import $ from '../../../styles/global';
import PhoneRow from './PhoneRow';
import SearchBar from './SearchBar';

const Container = styled.div`
  width: 30%;
  border-right: 1px solid ${$.color.whatsappBorder};
  background-color: ${$.color.white};
`;

const SubContainer = styled.div`
  overflow: auto;
  /* Minus the height of the "Chats" title and Searchbar */
  height: calc(100% - 62px - 52px);

  ::-webkit-scrollbar-track {
    margin-right: ${$.layout().margin5}px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${$.color.whatsappBg};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${$.color.blue4};
  }
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: ${$.color.black};
  border-bottom: 1px solid ${$.color.whatsappBorder};
  padding: ${$.layout().padding2}px 0;
  background-color: ${$.color.whatsappBg};
  height: calc(60px - ${$.layout().padding2 * 2}px + 1px);
`;

const LoadingRow = styled.div`
  width: calc(100% - ${$.layout().padding2 * 2}px);
  padding: ${$.layout().padding2}px;
  border-bottom: 1px solid ${$.color.whatsappBorder};
  transition: background-color 0.35s ease;
  color: ${$.color.black};
`;

const PhoneList = ({ names }) => {
  const [getList, getChat, clearTotalNoti, currentPhone] = useActions([
    getPhoneList,
    getAllMessages,
    clearNoti,
    setCurrentPhone,
  ]);
  const {
    whatsapp: { phoneList },
    user: { name, ws },
  } = useSelector(({ whatsapp, user }) => ({
    whatsapp,
    user,
  }));
  const [searchTerm, setSearchTerm] = useState('');
  const [totalViewing, setTotalViewing] = useState({});

  /**
   * Request for a list of phone numbers to display.
   */
  useEffect(() => {
    const cb = (e) => {
      setTotalViewing((prev) => {
        const newPrev = { ...prev };

        newPrev[e.detail.name] = e.detail.phone;

        return newPrev;
      });
    };

    getList();

    if (typeof window !== 'undefined') {
      window.addEventListener('isViewing', cb);
    }

    return () => {
      window.removeEventListener('isViewing', cb);
    };
  }, []);

  return (
    <Container>
      <Title>Chats</Title>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <SubContainer>
        {phoneList.phone.length > 0 ? (
          phoneList.phone
            .sort((curr, next) => {
              /**
               * Sort phone numbers according to the latest message received.
               */
              const currTimestamp = curr.timestamp;
              const nextTimestamp = next.timestamp;

              if (currTimestamp < nextTimestamp) {
                return 1;
              }

              if (currTimestamp > nextTimestamp) {
                return -1;
              }

              return 0;
            })
            .filter(({ phone }) => {
              /**
               * If we received a search term, filter the phone numbers.
               * We look through the list by checking the number and name.
               */
              if (searchTerm) {
                return !!(
                  phone.indexOf(searchTerm) > -1 ||
                  (names &&
                    names[phone].name &&
                    names[phone].name
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase()) > -1)
                );
              }

              return true;
            })
            .map(({ phone, parsedTimestamp, timestamp, key }) => {
              const { message } = phoneList;
              const phoneTotalViewers = [];

              /**
               * Creates a "Total users viewing this chat" feature.
               */
              Object.keys(totalViewing).forEach((viewerName) => {
                const viewingPhone = totalViewing[viewerName];

                if (viewingPhone === phone && viewerName !== name) {
                  phoneTotalViewers.push(viewerName);
                }
              });

              return (
                <PhoneRow
                  key={key}
                  names={names}
                  onClick={() => {
                    /**
                     * 1. On click, add the current number to redux.
                     * 2. Pings everyone that you are viewing this chat.
                     * 3. Clears notification for this number.
                     * 4. If need to, pull more messages from the database.
                     */
                    currentPhone(phone);
                    ws.isViewing(phone);
                    clearTotalNoti(phone);
                    getChat({ phone, name });
                  }}
                  viewers={phoneTotalViewers}
                  phone={phone}
                  timestamp={timestamp}
                  parsedTimestamp={parsedTimestamp}
                  message={message[phone]}
                />
              );
            })
        ) : (
          <LoadingRow>
            <div title="Loading...">Loading...</div>
          </LoadingRow>
        )}
      </SubContainer>
    </Container>
  );
};

PhoneList.defaultProps = {
  names: {},
};

PhoneList.propTypes = {
  names: PropTypes.objectOf(PropTypes.object),
};

export default PhoneList;
