/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { Link } from 'gatsby';

export default [
  {
    Header: 'Loan Name',
    id: 'loanName',
    accessor: (ele) => (
      <Link to={`/inventory/history?loan=${ele.id}`}>{ele.name}</Link>
    ),
    filter: 'fuzzyText',
  },
  {
    Header: 'Comments',
    id: 'comments',
    accessor: (ele) => ele.comments,
  },
];
