import React from 'react';
import {
  useTable,
  usePagination,
  useFilters,
  useExpanded,
  useSortBy,
} from 'react-table';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { filterTypes, defaultColumn, sortTypes } from './Filter';

import Pagination from './Pagination';
import Table from './Table';

const Container = styled.div``;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const ReactTable = ({ data, columns, SubRow, defaultPageSize }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      filterTypes: filterTypes(),
      sortTypes: sortTypes(),
      defaultColumn: defaultColumn(),
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <Container>
      <TableContainer>
        <Table
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          visibleColumns={visibleColumns}
          SubRow={SubRow}
        />
      </TableContainer>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </Container>
  );
};

ReactTable.defaultProps = {
  defaultPageSize: 10,
  SubRow: () => {},
};

ReactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSize: PropTypes.number,
  SubRow: PropTypes.func,
};

export default ReactTable;
