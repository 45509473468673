import React from 'react';
import styled from 'styled-components';

import NewLeadForm from './NewLeadForm';
import MissedCallForm from './MissedCallForm';
import OnlineMembers from '../OnlineMembers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TwilioFormsContainer = () => (
  <Container>
    <OnlineMembers />
    <NewLeadForm />
    <MissedCallForm />
  </Container>
);

export default TwilioFormsContainer;
