import React from 'react';
import styled from 'styled-components';

import $ from '../styles/global';

const Section = styled.section`
  width: 100vw;
  height: 100vh;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 40px;
  margin-bottom: ${$.layout().margin3}px;
  color: ${$.color.red1};
`;

const Message = styled.div`
  font-size: 17px;
  margin-bottom: ${$.layout().margin3}px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
`;

const Error404 = () => (
  <Section>
    <Container>
      <Title>404</Title>
      <Message>
        Oops! There are no other page save for the index page. What did you click on?
      </Message>
      <StyledLink href="/">Go back to index page</StyledLink>
    </Container>
  </Section>
);

export default Error404;
