import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { updateLoanGQL, deleteLoanGQL } from '../../apollo';
import useActions from '../../utils/useActions';
import {
  deleteLoanState,
  setFormModal,
  updateLoanState,
  setDeleteModal,
} from '../../actions';
import $ from '../../styles/global';
import Button from '../Button';
import loanForm from './loanForm';
import CloseIcon from '../../assets/icons/close.svg';
import EditIcon from '../../assets/icons/edit.svg';

const DeleteButton = styled(Button)`
  background-color: ${$.color.red1};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: ${$.color.red2};
  }
`;

const UpdateEntryButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${$.color.orange3};
  margin-right: ${$.layout().margin1}px;

  &:hover {
    background-color: ${$.color.orange4};
  }
`;

const Container = styled.tr`
  & > td {
    border: none;

    & svg {
      width: 20px;
      height: 20px;
      fill: ${$.color.white};
      margin-right: ${$.layout().margin1}px;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: ${$.layout().margin1}px;
      }
    }
  }
`;

const CommentTitle = styled.span`
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
`;

const CommentContent = styled.span`
  font-size: 16px;
`;

const SubRow = ({ row, columns }) => {
  const [updateLog, deleteLog, setFormModalState, setDeleteModalState] =
    useActions([
      updateLoanState,
      deleteLoanState,
      setFormModal,
      setDeleteModal,
    ]);
  const [updateOneLoan] = useMutation(updateLoanGQL, {
    onCompleted: ({ updateLoan: result }) => {
      if (result && Object.keys(result).length > 0) {
        updateLog(result);
        toast.success('Loan data successfully updated.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to update loan data. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to update loan data. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });
  const [deleteOneLoan] = useMutation(deleteLoanGQL, {
    onCompleted: ({ deleteLoan: result }) => {
      if (result) {
        deleteLog(result);
        toast.error('Loan entry deleted', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.warn('Unable to delete loan. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError: (e) => {
      console.log(e);
      toast.warn('Unable to delete loan. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  return (
    <Container>
      <td colSpan={columns}>
        <div>
          <UpdateEntryButton
            onClick={() => {
              setFormModalState({
                title: 'Update Loan Entry',
                state: row.original,
                onConfirm: (state) => {
                  updateOneLoan({ variables: { ...state } });
                },
                components: loanForm,
              });
            }}
          >
            <EditIcon />
            Update Entry
          </UpdateEntryButton>
          <DeleteButton
            onClick={() => {
              const name = row.original.name || '';

              setDeleteModalState({
                title: 'Delete Loan',
                message: `Remove ${name}?`,
                onConfirm: () => {
                  deleteOneLoan({
                    variables: { ...row.original },
                  });
                },
              });
            }}
          >
            <CloseIcon />
            Delete
          </DeleteButton>
          <CommentTitle>Comment: </CommentTitle>
          <CommentContent>{row.original.comments}</CommentContent>
        </div>
      </td>
    </Container>
  );
};

SubRow.defaultProps = {
  row: {},
  columns: 0,
};

SubRow.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.number,
};

export default SubRow;
