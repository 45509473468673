const initialState = [
  {
    campus: 'Loading',
    staff: 'Loading',
    call: 'Loading',
    from: 'Loading',
    status: 'Loading',
    time: 0,
    sid: 'Loading',
    name: 'Loading',
    outbound: true,
    handledBy: 'Loading',
    handled: false,
    handledTime: 0,
  },
];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CALL_STATE':
      if (action.payload.sid) {
        return state.map((ele) =>
          ele.sid === action.payload.sid ? action.payload : ele
        );
      }

      return state;
    case 'UPDATE_CALLS_STATE':
      return action.payload && action.payload.length >= 1
        ? action.payload
        : state;
    default:
      return state;
  }
};
