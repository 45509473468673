import { gql } from 'apollo-boost';

const updateCallGQL = gql`
  mutation call(
    $sid: String
    $campus: String
    $from: String
    $status: String
    $handled: Boolean
    $handledBy: String
    $handledTime: Date
    $staff: String
    $time: Date
    $outbound: Boolean
  ) {
    updateCall(call: { 
      sid: $sid,
      campus: $campus,
      from: $from,
      status: $status,
      handled: $handled,
      handledBy: $handledBy,
      handledTime: $handledTime,
      staff: $staff,
      time: $time,
      outbound: $outbound
    }) {
      sid
      call
      campus
      from
      status
      handled
      handledBy
      handledTime
      staff
      time
      outbound
    }
  }
`;

export default updateCallGQL;
