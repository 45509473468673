const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_HARDWARES_STATE':
      return action.payload && action.payload.length >= 1
        ? action.payload
        : state;

    case 'UPDATE_HARDWARE_STATE':
      if (action.payload.id) {
        return state.map((ele) =>
          ele.id === action.payload.id ? action.payload : ele
        );
      }

      return state;
    case 'NEW_HARDWARE_STATE':
      if (action.payload.id) {
        return [action.payload, ...state];
      }

      return state;
    case 'DELETE_HARDWARE_STATE':
      if (action.payload) {
        return state.filter((ele) => ele.id !== action.payload);
      }

      return state;
    default:
      return state;
  }
};
