import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import $ from '../../styles/global';
import SGCCLogo from '../../assets/images/sgcc-logo.png';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Home = styled(Link)`
  display: block;
  width: 50px;
  text-decoration: none;
  margin-right: ${$.layout().margin1}px;

  & > img {
    width: 100%;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Name = styled.div`
  font-size: 20px;
  color: ${$.color.blue4};
`;

const VersionNumber = styled.div`
  background-color: ${$.color.orange2};
  margin-right: ${$.layout().margin1}px;
  padding: 3px 7px;
  color: ${$.color.white};
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  align-self: flex-start;
`;

const Logo = () => {
  const {
    site: {
      siteMetadata: { version },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          version
        }
      }
    }
  `);
  const state = useSelector(({ user }) => ({ user }));

  return (
    <Container>
      <Home to="/">
        <img src={SGCCLogo} />
      </Home>
      <NameContainer>
        <Name>{`Hello, ${state.user.name}`}</Name>
        <VersionNumber>{`v${version}`}</VersionNumber>
      </NameContainer>
    </Container>
  );
};

export default Logo;
