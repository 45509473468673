/* mousetrap v1.6.5 craig.is/killing/mice */
(function (q, u, c) {
  function v(a, b, g) { a.addEventListener ? a.addEventListener(b, g, !1) : a.attachEvent(`on${b}`, g); } function z(a) { if (a.type == 'keypress') { let b = String.fromCharCode(a.which); a.shiftKey || (b = b.toLowerCase()); return b; } return n[a.which] ? n[a.which] : r[a.which] ? r[a.which] : String.fromCharCode(a.which).toLowerCase(); } function F(a) { const b = []; a.shiftKey && b.push('shift'); a.altKey && b.push('alt'); a.ctrlKey && b.push('ctrl'); a.metaKey && b.push('meta'); return b; } function w(a) {
    return a == 'shift' || a == 'ctrl' || a == 'alt'
|| a == 'meta';
  } function A(a, b) {
    let g;
    const d = []; let e = a; e === '+' ? e = ['+'] : (e = e.replace(/\+{2}/g, '+plus'), e = e.split('+')); for (g = 0; g < e.length; ++g) { var m = e[g]; B[m] && (m = B[m]); b && b != 'keypress' && C[m] && (m = C[m], d.push('shift')); w(m) && d.push(m); }e = m; g = b; if (!g) { if (!p) { p = {}; for (const c in n)c > 95 && c < 112 || n.hasOwnProperty(c) && (p[n[c]] = c); }g = p[e] ? 'keydown' : 'keypress'; }g == 'keypress' && d.length && (g = 'keydown'); return { key: m, modifiers: d, action: g };
  } function D(a, b) { return a === null || a === u ? !1 : a === b ? !0 : D(a.parentNode, b); } function d(a) {
    function b(a) {
      a = a || {}; let b = !1;
      let l; for (l in p)a[l] ? b = !0 : p[l] = 0; b || (x = !1);
    } function g(a, b, t, f, g, d) {
      let l;
      const E = [];
      const h = t.type; if (!k._callbacks[a]) return []; h == 'keyup' && w(a) && (b = [a]); for (l = 0; l < k._callbacks[a].length; ++l) { const c = k._callbacks[a][l]; if ((f || !c.seq || p[c.seq] == c.level) && h == c.action) { var e; (e = h == 'keypress' && !t.metaKey && !t.ctrlKey) || (e = c.modifiers, e = b.sort().join(',') === e.sort().join(',')); e && (e = f && c.seq == f && c.level == d, (!f && c.combo == g || e) && k._callbacks[a].splice(l, 1), E.push(c)); } } return E;
    } function c(a, b, c, f) {
      k.stopCallback(b,
        b.target || b.srcElement, c, f) || !1 !== a(b, c) || (b.preventDefault ? b.preventDefault() : b.returnValue = !1, b.stopPropagation ? b.stopPropagation() : b.cancelBubble = !0);
    } function e(a) { typeof a.which !== 'number' && (a.which = a.keyCode); const b = z(a); b && (a.type == 'keyup' && y === b ? y = !1 : k.handleKey(b, F(a), a)); } function m(a, g, t, f) {
      function h(c) { return function () { x = c; ++p[a]; clearTimeout(q); q = setTimeout(b, 1E3); }; } function l(g) { c(t, g, a); f !== 'keyup' && (y = z(g)); setTimeout(b, 10); } for (let d = p[a] = 0; d < g.length; ++d) {
        const e = d + 1 === g.length ? l : h(f
|| A(g[d + 1]).action); n(g[d], e, f, a, d);
      }
    } function n(a, b, c, f, d) {
      k._directMap[`${a}:${c}`] = b; a = a.replace(/\s+/g, ' '); const e = a.split(' '); e.length > 1 ? m(a, e, b, c) : (c = A(a, c), k._callbacks[c.key] = k._callbacks[c.key] || [], g(c.key, c.modifiers, { type: c.action }, f, a, d), k._callbacks[c.key][f ? 'unshift' : 'push']({
        callback: b, modifiers: c.modifiers, action: c.action, seq: f, level: d, combo: a,
      }));
    } var k = this; a = a || u; if (!(k instanceof d)) return new d(a); k.target = a; k._callbacks = {}; k._directMap = {}; var p = {};
    let q;
    var y = !1;
    let r = !1;
    var x = !1; k._handleKey = function (a,
      d, e) {
      let f = g(a, d, e);
      let h; d = {}; let k = 0;
      let l = !1; for (h = 0; h < f.length; ++h)f[h].seq && (k = Math.max(k, f[h].level)); for (h = 0; h < f.length; ++h)f[h].seq ? f[h].level == k && (l = !0, d[f[h].seq] = 1, c(f[h].callback, e, f[h].combo, f[h].seq)) : l || c(f[h].callback, e, f[h].combo); f = e.type == 'keypress' && r; e.type != x || w(a) || f || b(d); r = l && e.type == 'keydown';
    }; k._bindMultiple = function (a, b, c) { for (let d = 0; d < a.length; ++d)n(a[d], b, c); }; v(a, 'keypress', e); v(a, 'keydown', e); v(a, 'keyup', e);
  } if (q) {
    var n = {
      8: 'backspace',
      9: 'tab',
      13: 'enter',
      16: 'shift',
      17: 'ctrl',
      18: 'alt',
      20: 'capslock',
      27: 'esc',
      32: 'space',
      33: 'pageup',
      34: 'pagedown',
      35: 'end',
      36: 'home',
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      45: 'ins',
      46: 'del',
      91: 'meta',
      93: 'meta',
      224: 'meta',
    };
    var r = {
      106: '*', 107: '+', 109: '-', 110: '.', 111: '/', 186: ';', 187: '=', 188: ',', 189: '-', 190: '.', 191: '/', 192: '`', 219: '[', 220: '\\', 221: ']', 222: "'",
    };
    var C = {
      '~': '`', '!': '1', '@': '2', '#': '3', $: '4', '%': '5', '^': '6', '&': '7', '*': '8', '(': '9', ')': '0', _: '-', '+': '=', ':': ';', '"': "'", '<': ',', '>': '.', '?': '/', '|': '\\',
    };
    var B = {
      option: 'alt',
      command: 'meta',
      return: 'enter',
      escape: 'esc',
      plus: '+',
      mod: /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'meta' : 'ctrl',
    };
    var p; for (c = 1; c < 20; ++c)n[111 + c] = `f${c}`; for (c = 0; c <= 9; ++c)n[c + 96] = c.toString(); d.prototype.bind = function (a, b, c) { a = a instanceof Array ? a : [a]; this._bindMultiple.call(this, a, b, c); return this; }; d.prototype.unbind = function (a, b) { return this.bind.call(this, a, () => {}, b); }; d.prototype.trigger = function (a, b) { if (this._directMap[`${a}:${b}`]) this._directMap[`${a}:${b}`]({}, a); return this; }; d.prototype.reset = function () {
      this._callbacks = {};
      this._directMap = {}; return this;
    }; d.prototype.stopCallback = function (a, b) { if ((` ${b.className} `).indexOf(' mousetrap ') > -1 || D(b, this.target)) return !1; if ('composedPath' in a && typeof a.composedPath === 'function') { const c = a.composedPath()[0]; c !== a.target && (b = c); } return b.tagName == 'INPUT' || b.tagName == 'SELECT' || b.tagName == 'TEXTAREA' || b.isContentEditable; }; d.prototype.handleKey = function () { return this._handleKey.apply(this, arguments); }; d.addKeycodes = function (a) { for (const b in a)a.hasOwnProperty(b) && (n[b] = a[b]); p = null; };
    d.init = function () {
      const a = d(u);
      let b; for (b in a)b.charAt(0) !== '_' && (d[b] = (function (b) { return function () { return a[b].apply(a, arguments); }; }(b)));
    }; d.init(); q.Mousetrap = d; typeof module !== 'undefined' && module.exports && (module.exports = d); typeof define === 'function' && define.amd && define(() => d);
  }
}(typeof window !== 'undefined' ? window : null, typeof window !== 'undefined' ? document : null));
