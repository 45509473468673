import React, { useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Auth, API } from 'aws-amplify';
import { useSelector } from 'react-redux';

import { useMyContext } from './Context';
import $ from '../../../../styles/global';
import AttachmentIcon from '../../../../assets/icons/attachment.svg';

const Container = styled.div`
  align-self: flex-start;
  svg {
    position: relative;
    top: 3px;
    fill: ${$.color.black};
    width: 28px;
    height: 28px;
    transform: rotate(135deg);
    transition: all 0.3s ease;
  }

  input {
    display: none;
  }

  &:hover {
    svg {
      fill: ${$.color.blue5};
      cursor: pointer;
    }
  }
`;

/**
 * Uses timestamp as the name.
 */
const getFileName = (type) => {
  const fileType = type.split('/')[1].toLowerCase();

  return `${Date.now()}.${fileType}`;
};

const Attachment = () => {
  const inputRef = useRef(null);
  const state = useSelector(({ whatsapp, user }) => ({ whatsapp, user }));
  const { setUploading, sendReply } = useMyContext();

  const upload = async (file) => {
    const formData = new FormData();
    const sessionData = await Auth.currentSession();
    const fileName = getFileName(file.type);

    const presignedURLQuery = API.post('APIGatewayGetPresignedURL', '/', {
      headers: {
        Authorization: sessionData.idToken.jwtToken,
      },
      response: true,
      body: {
        name: `${state.whatsapp.currentPhone}/${fileName}`,
        type: file.type,
      },
    });

    formData.append('file', file);

    return presignedURLQuery
      .then(({ data: { url } }) => {
        if (url === '') {
          return { error: true };
        }

        return axios
          .put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
          })
          .then(() => ({
            data: `https://sgcc-phone-dashboard-whatsapp-files.s3.ap-southeast-1.amazonaws.com/${state.whatsapp.currentPhone.replace(
              '+',
              '%2B'
            )}/${fileName}`,
          }))
          .catch((err) => {
            console.log(err);
            return { error: true };
          });
      })
      .catch((err) => {
        console.log(err);
        return { error: true };
      });
  };

  return (
    <Container title="Add images, or PDF.">
      <AttachmentIcon
        onClick={(e) => {
          if (['path', 'svg'].indexOf(e.target.tagName) > -1) {
            inputRef.current.click();
          }
        }}
      />
      <input
        type="file"
        name="upload"
        ref={inputRef}
        accept="image/png, image/jpeg, image/jpg, application/pdf"
        onChange={(e) => {
          const file = Array.from(e.target.files)[0];
          const fileSize = (file.size / (1024 * 1024)).toFixed(3);

          /**
           * Do not upload files more than 15MB
           */
          if (fileSize >= 16) {
            setUploading({
              show: true,
              name: file.name,
              error: true,
              errorMessage: 'File size is more than 16MB.',
            });

            setTimeout(() => {
              setUploading((prev) => {
                const newPrev = { ...prev };

                newPrev.show = false;

                return newPrev;
              });
            }, 3500);
            return;
          }

          /**
           * Show the upload banner, then hide it later once we have confirmed
           * that the file has been logged.
           */
          setUploading({
            show: true,
            name: file.name,
            error: false,
          });

          upload(file).then(({ data, error }) => {
            if (error) {
              setUploading((prev) => {
                const newPrev = { ...prev };

                newPrev.error = true;

                return newPrev;
              });

              setTimeout(() => {
                setUploading((prev) => {
                  const newPrev = { ...prev };

                  newPrev.show = false;

                  return newPrev;
                });
              }, 5000);
            } else {
              sendReply({
                attachment: {
                  type: file.type,
                  value: data,
                },
              });

              setUploading((prev) => {
                const newPrev = { ...prev };

                newPrev.show = false;

                return newPrev;
              });
            }
          });

          e.target.value = '';
        }}
      />
    </Container>
  );
};

export default Attachment;
